import { useCallback } from 'react'
import { isEmpty } from 'lodash'

import { CustomField, FieldOption, FieldValue } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'

export const buildDefaultFieldValues = ({
  customFields,
  groupedCustomFields,
  fieldValues = {},
  integrationGroupedCustomFields
}: {
  customFields: CustomField[]
  groupedCustomFields: Record<number, CustomField[]>
  fieldValues: Record<CustomField['id'], any>
  integrationGroupedCustomFields?: Record<number, CustomField[]>
}) => {
  const defaultFieldValues: { [key: number]: Partial<FieldValue> } = {}
  customFields.forEach(cf => {
    const value = cf.field_type.slug === 'textarea' ? '' : null
    defaultFieldValues[cf.id] = {
      value: cf.default_value || value,
      field_option_id: null
    }
  })

  Object.values(groupedCustomFields).forEach(group => {
    group.forEach(cf => {
      const value = cf.field_type.slug === 'textarea' ? '' : null
      defaultFieldValues[cf.id] = {
        value: cf.default_value || value,
        field_option_id: null
      }
    })
  })

  if (integrationGroupedCustomFields) {
    Object.values(integrationGroupedCustomFields).forEach(group => {
      group.forEach(cf => {
        const value = cf.field_type.slug === 'textarea' ? '' : null
        defaultFieldValues[cf.id] = {
          value: cf.default_value || value,
          field_option_id: null
        }
      })
    })
  }

  // If we have provided fieldValues, add these into defaultFieldValues
  // If a field value was provided but no default field value was found, we will
  // not add this to the defaultFieldValues. This ensures that we don't add new
  // keys to our defaultFieldValues.
  for (const [key, value] of Object.entries(fieldValues)) {
    if (defaultFieldValues.hasOwnProperty(key)) {
      defaultFieldValues[key as unknown as number] = value
    }
  }

  return defaultFieldValues
}

export const useCustomFieldFormHelpers = () => {
  const { t } = useLanguage('customFields', { keyPrefix: 'form' })
  const getArchivedFieldValues = useCallback(
    ({
      items = [],
      keyBy = 'id',
      fieldOptionsLookup = {}
    }: {
      items?: { [key: string]: any; field_values: FieldValue[] }[]
      keyBy?: string
      fieldOptionsLookup?: Record<number, FieldOption>
    }) => {
      return items.reduce((acc, item) => {
        item.field_values?.forEach(({ field_option_id, value }) => {
          const addArchivedField = (id: number) => {
            if (fieldOptionsLookup[id]?.archived) {
              acc[item[keyBy]] = [...(acc[item[keyBy]] || []), id]
            }
          }

          if (field_option_id) addArchivedField(field_option_id)
          if (typeof value === 'string' && value.startsWith('[')) {
            try {
              JSON.parse(value).forEach(addArchivedField)
            } catch {}
          }
        })
        return acc
      }, {} as Record<number, number[]>)
    },
    []
  )

  const getArchivedFieldValueErrors = useCallback(
    ({
      archivedFieldValues,
      fieldOptionsLookup,
      getItemName
    }: {
      archivedFieldValues: Record<number, number[]>
      fieldOptionsLookup: Record<number, FieldOption>
      getItemName: (id: number) => string
    }) =>
      isEmpty(archivedFieldValues)
        ? []
        : Object.keys(archivedFieldValues).map(id =>
            t('archivedFieldValues', {
              name: getItemName(parseInt(id)),
              fieldValueNames: archivedFieldValues[parseInt(id)]
                .map(fvId => `${fieldOptionsLookup[fvId].name}`)
                .join(', ')
            })
          ),
    []
  )

  return { getArchivedFieldValues, getArchivedFieldValueErrors }
}
