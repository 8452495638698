import { ReactElement, useEffect } from 'react'

import {
  CsvImportResponse,
  MarkedIncidentSensitiveResponse
} from 'main/services/api/data-providers/user/user-channel-response-types'
import { useUserWebsocket } from 'main/services/hooks'
import { ActiveRunbookModel, CurrentUserModel, RunbookViewModel } from 'main/data-access'
/*
  While Workspace is still using context provider for websockets,
  just listen to the existing channel in this subscriber on the runbook level
  Eventually we will use this to subscribe in the entire app
*/
export const UserChannelSubscriber = ({ children }: { children: ReactElement }) => {
  const { listen } = useUserWebsocket()
  const currentUserId = CurrentUserModel.useId()
  const updateModalData = RunbookViewModel.useAction('modal:update')
  const processMarkedRestricted = ActiveRunbookModel.useOnAction('runbook_marked_restricted')

  const handleUserChannelResponse = (response: CsvImportResponse | MarkedIncidentSensitiveResponse) => {
    if (!response) return

    switch (response?.meta?.headers?.request_method) {
      case 'runbook_version_import_success':
        updateModalData({ status: 'success' })
        break
      case 'runbook_version_import_error':
        const csvResponse = response as CsvImportResponse
        updateModalData({ status: 'error', context: csvResponse.errors })
        break
      case 'runbook_marked_restricted':
        processMarkedRestricted(response as MarkedIncidentSensitiveResponse)
        break
      default:
    }
  }

  useEffect(() => {
    if (!currentUserId) return
    listen(data => handleUserChannelResponse(data as CsvImportResponse))
  }, [currentUserId])

  return children
}
