export type UserStatus = 'archived' | 'external' | 'not_allowed' | 'invited' | 'active' | 'external' | 'not_in_account'

export type User = {
  id: number
  archived: boolean
  avatar: string | null
  color: string
  created_at: number
  current_sign_in_at: string
  description: string | null
  email: string
  expired_at: number | null
  expired: boolean | null
  expires_at: number | null
  first_name: string
  firstName?: string
  handle: string
  has_role_on_runbook: boolean
  invitation_accepted_at: number
  invitation_sent_at: number | null
  is_admin: boolean
  is_member_of_linked_team: boolean | null
  job_title: string | null
  last_activity_at: number
  last_name: string
  lastName?: string
  last_sign_in_at: string
  locked_at: string | null
  mobile_number_normalized: string | null
  mobile_number: string | null
  name: string
  not_in_account: boolean
  online: boolean
  pref_email: boolean
  pref_sms: boolean
  role_id: number
  sign_in_count: number
  status: UserStatus
  subject_type: string
  unique_id: string
  updated_at: number
}

export type Permissions = {
  update: number[]
  archive: number[]
  destroy: number[]
  addEvent: number[]
  leaveEvent: number[]
  duplicate: number[]
}

export const MY_CUTOVER_PERMISSIONS = [
  'access',
  'accounts',
  'connect_settings',
  'custom_fields',
  'cutover_root_user',
  'event_webhooks',
  'dashboards',
  'data_sources',
  'field_groups',
  'global_settings',
  'incident_severities',
  'instance_settings',
  'integrations',
  'roles',
  'role_types',
  'runbook_types',
  'saml_configurations',
  'self_serve_sso',
  'task_types',
  'users',
  'user_app_tokens'
] as const

export type MyCutoverPermissionsType = (typeof MY_CUTOVER_PERMISSIONS)[number]
export type PermissionsRecordType = Record<MyCutoverPermissionsType, boolean>

export type FrontEndUserSettingIntegrationActionItemsType = {
  id: number
  order: number
  visible: boolean
}

export type FrontendUserSettingType = {
  id?: number
  type: 'FrontendUserSetting' | 'RunbookUserSetting'
  resource_id?: number
  resource_type?: 'Runbook'
  data: {
    custom_integration_info_hidden?: boolean
    linked_template_marketing_modal_hidden?: boolean
    task_finish_confirm_hidden?: number[]
    integration_action_items?: FrontEndUserSettingIntegrationActionItemsType[]
  }
  user_id?: number
}

export type SamlConfiguration = {
  custom_acs_url: string
  allowed_clock_drift: number
  attribute_mappings: object
  default: boolean
  id?: number
  idp_cert_multi: {
    signing: string[]
  }
  idp_sso_service_url: string
  hidden: boolean
  options: {
    active_roles: boolean
    purge_roles: boolean
  }
  private_key: string
  sp_entity_id: string
  updated_at: string
  name: string
  certificates_with_metadata: {
    signing: string[]
    meta_data: {
      expiry: string
      cn: string
    }[]
  }
}
