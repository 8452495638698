import { useMemo } from 'react'
import * as yup from 'yup'

import { Box } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  FieldOptionModel,
  RunbookViewModel,
  TaskModel
} from 'main/data-access'
import { duplicateTask, TaskDuplicatePayload } from 'main/services/queries/use-task'
import { FormModal, RadioboxGroupField, TextInputField } from 'main/components/shared/form'
import { RunbookTaskMultiplyResponse } from 'main/services/api/data-providers/runbook-types'
import { useCustomFieldFormHelpers } from 'main/components/shared/custom-field-form/custom-field-form-helpers'

type TasksDuplicateModalProps = {
  taskId: number
  taskName: string
  onClose: () => void
}

const validationSchema = yup.object({
  copy_type: yup.string().oneOf(['sequence', 'parallel']).required(),
  copies: yup.string().required(),
  suffix: yup.string()
})

type TaskDuplicateForm = yup.InferType<typeof validationSchema>

export const TaskDuplicateModal = ({ taskId, taskName, onClose }: TasksDuplicateModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskDuplicateModal' })
  const notify = RunbookViewModel.useAction('notify')
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const processTaskMultiplyResponse = TaskModel.useOnAction('multiply')
  const task = TaskModel.useGet(taskId)
  const fieldOptionsLookup = FieldOptionModel.useGetLookup()
  const { getArchivedFieldValueErrors, getArchivedFieldValues } = useCustomFieldFormHelpers()

  const archivedErrors = useMemo(
    () =>
      getArchivedFieldValueErrors({
        archivedFieldValues: getArchivedFieldValues({ items: [task], fieldOptionsLookup }),
        fieldOptionsLookup,
        getItemName: () => taskName
      }),
    [fieldOptionsLookup, task]
  )

  const handleSubmit = async (payload: TaskDuplicatePayload) => {
    return await duplicateTask({ runbookId, runbookVersionId, taskId, payload })
  }

  const handleSuccess = (response: RunbookTaskMultiplyResponse) => {
    processTaskMultiplyResponse(response)
    notify.success(t('successText'))
    onClose?.()
  }

  return (
    <FormModal<TaskDuplicateForm>
      preventAutoClose // The usual Hack to fix formmodal bug where it unexpectedly closes after api error
      title={t('title')}
      description={t('description', { taskName: taskName })}
      open
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      confirmText={t('confirmText')}
      confirmDisabled={!!archivedErrors.length}
      confirmIcon="duplicate"
      schema={validationSchema}
      customErrors={archivedErrors}
      defaultValues={{
        copies: '10',
        suffix: 'copy',
        copy_type: 'sequence'
      }}
    >
      <TaskDuplicateModalInner />
    </FormModal>
  )
}

const TaskDuplicateModalInner = () => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskDuplicateModal' })

  const positionOptions: { label: string; value: 'sequence' | 'parallel' }[] = [
    { label: t('copyTypeSequence'), value: 'sequence' },
    { label: t('copyTypeParallel'), value: 'parallel' }
  ]

  return (
    <Box direction="column">
      <RadioboxGroupField<TaskDuplicateForm>
        name="copy_type"
        label={t('copyType')}
        direction="row"
        options={positionOptions}
      />
      <TextInputField name="copies" label={t('copies')} />
      <TextInputField name="suffix" label={t('suffix')} />
    </Box>
  )
}
