import { useRecoilValue } from 'recoil'

import { DataSourceValueModelType } from 'main/data-access/models/data-source-value-model'
import {
  dataSourceValuesCount,
  dataSourceValuesFilter,
  dataSourceValuesOptions
} from 'main/recoil/shared/models/data-source-values/data-source-values'

export const useGetFilteredValues: DataSourceValueModelType['useGetFilteredValues'] = ({
  customFieldId,
  accountId
}) => {
  return useRecoilValue(dataSourceValuesFilter({ customFieldId, accountId }))
}

export const useGetCount: DataSourceValueModelType['useGetCount'] = ({ customFieldId, accountId }) => {
  return useRecoilValue(dataSourceValuesCount({ customFieldId, accountId }))
}

export const useGetOptions: DataSourceValueModelType['useGetOptions'] = ({ customFieldId, accountId }) => {
  return useRecoilValue(dataSourceValuesOptions({ customFieldId, accountId }))
}
