import { FilterAccordionPanel, MultiSelectControl } from '@cutover/react-ui'
import { ToggleControl } from 'main/components/runbook/runbook-filter/shared/toggle-control'
import { useRunbookTypesAccountFilter, useRunbookTypesGlobalFilter } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useAccountsQuery } from 'main/services/queries/use-accounts'

export const RunbookTypeVisibilityGroup = () => {
  const { t } = useLanguage('runbookTypes')
  const [accountFilterIds, setAccountFilterIds] = useRunbookTypesAccountFilter()
  const [globalFilterState] = useRunbookTypesGlobalFilter()

  const isApplied = [globalFilterState, accountFilterIds].some(filter => filter !== undefined)

  // TODO: get accounts data from global state instead?
  const { data: accountsData } = useAccountsQuery()
  const accounts = accountsData?.accounts

  const accountOptions = (accounts || [])?.map(account => ({
    label: account.title,
    value: account.id
  }))

  return (
    <FilterAccordionPanel label={t('filter.filterGroupVisibility.title')} applied={isApplied} initialActive>
      <GlobalFilterToggle />
      <MultiSelectControl
        value={(accountFilterIds as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          setAccountFilterIds(val?.map(v => Number(v.value)) ?? [])
        }}
        options={accountOptions}
        plain
      />
    </FilterAccordionPanel>
  )
}

const GlobalFilterToggle = () => {
  const [value, setValue] = useRunbookTypesGlobalFilter()

  return <ToggleControl filterKey="global" value={value} setValue={setValue} />
}
