import { useCallback } from 'react'
import { format } from 'date-fns'

import { ChangeRequest, ChangeTaskWithMetadata, StandaloneChangeTask } from 'main/services/queries/types'

const CHANGE_REQUESTS_KEYS = [
  'title',
  'crq_number',
  'request_type',
  'project_classification',
  'small_enhancement',
  'backout_ability',
  'description',
  'status',
  'status_reason',
  'scheduled_start_date',
  'scheduled_end_date',
  'actual_start_date',
  'actual_end_date'
]

const CHANGE_REQUESTS_TASKS_KEYS = [
  'title',
  'activity_type',
  'order',
  'assigned_group',
  'assigneeId',
  'status',
  'status_reason',
  'scheduled_start_date',
  'scheduled_end_date',
  'actual_start_date',
  'actual_end_date',
  'assignment_group'
]

export const useChangeRequestMetadata = (changeRequest: ChangeRequest) => {
  const getMetadataByKey = (key: string) => {
    if (!changeRequest.metadata?.fields) return

    const fields = changeRequest.metadata.fields
    return key in fields ? fields[key].value : null
  }

  const formatMetadataValue = (key: string) => {
    var value = getMetadataByKey(key)
    if (!value) return

    if (changeRequest.metadata?.fields[key]?.type === 'datetime') {
      return format(new Date(value), 'do MMM yyyy, HH:mm')
    }
    return value
  }

  const relevantChangeRequestMetadata = () => {
    if (!changeRequest.metadata?.fields) return []

    return CHANGE_REQUESTS_KEYS.filter(key => {
      const field = changeRequest.metadata?.fields[key]?.value
      if (field && field !== null && field !== '') return key
    })
  }

  return {
    formatMetadataValue,
    relevantChangeRequestMetadata
  }
}

export const useChangeTaskMetadata = () => {
  const getMetadataByKey = (changeTask: StandaloneChangeTask, key: string) => {
    if (!changeTask.metadata?.fields) return

    const fields = changeTask.metadata.fields
    return key in fields ? fields[key].value : null
  }

  const formatStandaloneMetadataValue = (changeTask: StandaloneChangeTask, key: string) => {
    var value = getMetadataByKey(changeTask, key)
    if (!value) return

    if (changeTask.metadata?.fields[key]?.type === 'datetime') {
      return format(new Date(value), 'do MMM yyyy, HH:mm')
    }
    return value
  }

  const relevantChangeTaskMetadataCallback = useCallback((changeTask: ChangeTaskWithMetadata) => {
    return CHANGE_REQUESTS_TASKS_KEYS.filter(key => {
      const field = changeTask[key]
      if (field && field !== null && field !== '') return key
    })
  }, [])

  const relevantStandaloneChangeTaskMetadataCallback = useCallback((changeTask: StandaloneChangeTask) => {
    if (!changeTask.metadata?.fields) return []

    return CHANGE_REQUESTS_TASKS_KEYS.filter(key => {
      const field = changeTask.metadata?.fields[key]?.value
      if (field && field !== null && field !== '') return key
    })
  }, [])

  return {
    formatStandaloneMetadataValue,
    relevantStandaloneChangeTaskMetadataCallback,
    relevantChangeTaskMetadataCallback
  }
}
