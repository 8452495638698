import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export const EventWebhooksHeader = () => {
  const { t } = useLanguage('eventWebhooks')

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <EventWebhooksTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('eventWebhooks.header.title.label')}
        </EventWebhooksTitle>
      </Box>
    </Box>
  )
}

const EventWebhooksTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
