import { useState } from 'react'

import { Modal } from '@cutover/react-ui'
import { CreateChangeRequestFormWithRunbookWebsocket } from './create-change-request-form'
import { CreateOrLinkChangeRequestMenu } from './create-or-link-change-request-menu'
import { LinkChangeRequestForm } from './link-change-request-form'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

type ModalContent = 'menu' | 'link' | 'create'

type CreateOrLinkChangeRequestModalProps = {
  open: boolean
  runbookId: string | undefined
  changeRequestType: string | null
  handleCloseModal: () => void
}

export const CreateOrLinkChangeRequestModal = ({
  open,
  runbookId,
  changeRequestType,
  handleCloseModal
}: CreateOrLinkChangeRequestModalProps) => {
  const { t } = useLanguage('changeRequests', { keyPrefix: 'modal' })
  const isChangeGovernanceIntegrationEnabled = ConfigModel.useIsFeatureEnabled('change_governance_integration')
  const initialModalContentType = isChangeGovernanceIntegrationEnabled ? 'menu' : 'link'

  const [modalContentType, setModalContentType] = useState<ModalContent>(initialModalContentType)
  const [onClickConfirm, setOnClickConfirm] = useState<(() => void) | undefined>(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [changeRequestId, setChangeRequestId] = useState<number | undefined>(undefined)

  const handleChangeRequestCreate = (id: number) => {
    setChangeRequestId(id)
  }

  const handleClose = () => {
    handleCloseModal()
    setModalContentType(initialModalContentType)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      loading={isSubmitting}
      loadingText={modalContentType === 'menu' ? undefined : t('submit', { context: modalContentType })}
      hideFooter={modalContentType == 'menu'}
      title={t('title', { context: modalContentType })}
      onClickConfirm={onClickConfirm}
      onClickBack={
        isChangeGovernanceIntegrationEnabled && modalContentType !== 'menu'
          ? () => setModalContentType('menu')
          : undefined
      }
      confirmText={modalContentType === 'menu' ? undefined : t('submit', { context: modalContentType })}
      focusConfirmButton
    >
      {modalContentType === 'menu' ? (
        <CreateOrLinkChangeRequestMenu
          onClickCreate={() => setModalContentType('create')}
          onClickLink={() => setModalContentType('link')}
        />
      ) : (
        runbookId &&
        changeRequestType && (
          <>
            {modalContentType === 'link' ? (
              <LinkChangeRequestForm
                setOnClickConfirm={setOnClickConfirm}
                setSubmitting={setIsSubmitting}
                runbookId={runbookId}
                onSuccess={handleClose}
              />
            ) : modalContentType === 'create' ? (
              <CreateChangeRequestFormWithRunbookWebsocket
                setOnClickConfirm={setOnClickConfirm}
                setSubmitting={setIsSubmitting}
                runbookId={runbookId}
                changeRequestType={changeRequestType}
                onSuccess={handleClose}
                onChangeRequestCreate={handleChangeRequestCreate}
                changeRequestId={changeRequestId}
              />
            ) : null}
          </>
        )
      )}
    </Modal>
  )
}
