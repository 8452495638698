import { memo, Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { FilterAccordion, FilterAccordionPanel, MultiSelectControl } from '@cutover/react-ui'
import { FilterPanel } from 'main/components/layout/filter-panel'
import { IDS as LAYOUT_IDS } from 'main/components/layout/layout-constants'
import { GroupsHeader } from 'main/components/shared/filter/filter-groups/groups-header'
import { CustomField } from 'main/services/queries/types'
import { useDataSourceValuesAppliedFilterState, useDataSourceValuesFilter } from 'main/recoil/data-access'
import { DataSourceValueModel } from 'main/data-access/models/data-source-value-model'
import { AnyOrNoneControls } from 'main/recoil/shared/filters/controls'
import { ActiveAccountModel, CustomFieldModel } from 'main/data-access'

export const DataSourceFilters = () => {
  const { customFieldId } = useParams<{ customFieldId?: string }>()

  return (
    <>
      {customFieldId && (
        <Suspense fallback={null}>
          <DataSourceFiltersInner customFieldId={parseInt(customFieldId)} />
        </Suspense>
      )}
    </>
  )
}

export const DataSourceFiltersInner = ({ customFieldId }: { customFieldId: number }) => {
  const account = ActiveAccountModel.useGet()
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const options = DataSourceValueModel.useGetOptions({ customFieldId, accountId: account.id })
  const dataSourceFilters = useDataSourceValuesAppliedFilterState()

  const filterCustomFields = () => {
    const customField = customFieldsLookup[customFieldId]
    return [
      customField,
      ...Object.values(customFieldsLookup).filter(cf => (cf as CustomField).source_custom_field_id === customField.id)
    ]
  }

  return (
    <FilterPanel>
      <FilterAccordion
        a11yTitle="Filter panel"
        renderMode="active"
        isLayoutFilter
        scrollContainer={LAYOUT_IDS.FILTER_PANEL}
      >
        <GroupsHeader key="header" />
        {filterCustomFields().map(customField => {
          const valueKey = customField.value_key
          return (
            <DataSourceValueGroup
              customField={customField}
              applied={dataSourceFilters && (customField.value_key ?? '') in dataSourceFilters}
              key={customField.id}
              options={valueKey ? options[valueKey] : []}
            />
          )
        })}
      </FilterAccordion>
    </FilterPanel>
  )
}

const DataSourceValueGroup = memo(
  ({ customField, applied, options }: { customField: CustomField; applied?: boolean; options: any }) => {
    const [value, setValue] = useDataSourceValuesFilter()
    const valueKey = customField.value_key

    return (
      <FilterAccordionPanel applied={applied} label={customField.display_name || customField.name} key={customField.id}>
        <MultiSelectControl
          options={options}
          value={valueKey ? value?.[valueKey] : []}
          onChange={val => {
            if (!valueKey) return

            const newValue =
              val?.map(v => v.value).filter((v: string | number) => v !== undefined && v !== null && v !== '') ?? []

            if (value?.[valueKey]?.includes(0)) {
              newValue.push(0)
            }

            setValue((prevValue: Record<string, any> = {}) => {
              const { [valueKey]: _, ...rest } = prevValue
              return newValue.length ? { ...prevValue, [valueKey]: newValue } : rest
            })
          }}
          plain
          additionalControls={
            <AnyOrNoneControls
              value={valueKey ? value?.[valueKey] : []}
              setValue={value => {
                if (!valueKey) return

                const newValue = !value ? [] : value[0] === '*' ? ['*'] : value

                setValue((prevValue: Record<string, any> = {}) => {
                  const { [valueKey]: _, ...rest } = prevValue
                  return newValue.length ? { ...prevValue, [valueKey]: newValue } : rest
                })
              }}
            />
          }
        />
      </FilterAccordionPanel>
    )
  }
)
