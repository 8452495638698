import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, ColorProp, FavoriteStar, IconButton, Pill, useNotify } from '@cutover/react-ui'
import { RunbookSettingsMenu } from './runbook-settings-menu'
import { ScheduledStartRunbookModal } from '../modals/scheduled-start-runbook/scheduled-start-runbook-modal'
import { useToggleRecoilFavorites } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { CsvImportModal } from '../modals/csv-import/csv-import-modal'
import { MakeTemplateModal } from '../modals/make-template/make-template-modal'
import { ViewHistoryModal } from '../modals/view-history/view-history-modal'
import { DeleteTemplateDraftVersionModal } from '../modals/delete-template-draft-version/delete-template-draft-version-modal'
import { RunbookDuplicateModalWrapper } from '../modals/duplicate/runbook-duplicate-modal-wrapper'
import { MergeRunbookModal } from '../modals/merge-runbook/merge-runbook-modal'
import { ModalActiveType } from 'main/recoil/runbook'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel,
  RunbookViewModel
} from 'main/data-access'
import { useCreateNewRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { ArchiveRunbooksModal } from 'main/components/workspace/modals/archive-runbooks-modal'
import { useRouting } from 'main/services/routing'
import { CreateSavedViewModal } from 'main/components/workspace/modals/create-saved-view-modal'
import { SelectedTimezoneModal } from '../modals/selected-timezone/selected-timezone-modal'
import { AdHocCommsModal } from '../modals/ad-hoc-comms/ad-hoc-comms-modal'
import { useRunbookRestore } from 'main/services/queries/use-runbook-restore'

const STATUS_COLOR_MAP: Record<string, ColorProp> = {
  pending: 'text-light',
  open: 'warning',
  approved: 'success',
  expired: 'error',
  expiring: 'warning',
  rejected: 'error'
}

const SNIPPET_STATUS_COLOR_MAP: Record<string, ColorProp> = {
  template_approved: 'success',
  template_rejected: 'error',
  template_draft: 'text-light'
}

export const RunbookToolbar = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const notify = useNotify()
  const navigate = useNavigate()
  const { toWorkspace } = useRouting()

  const { slug, id: accountId } = ActiveAccountModel.useGet()

  const { active: activeModal } = RunbookViewModel.useGet('modal')
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')

  const closeModal = RunbookViewModel.useAction('modal:close')
  const openModal = RunbookViewModel.useAction('modal:open')

  const { start_scheduled: startScheduled } = CurrentRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const hasScheduledStart = !!startScheduled

  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const handleCreateVersionResponse = ActiveRunbookVersionModel.useOnAction('create')
  const { id: runbookId, archived, is_template, name, template_type: templateType } = ActiveRunbookModel.useGet()

  const [favorite, setFavorite] = useToggleRecoilFavorites({ id: runbookId })
  const isFavorite = !!favorite

  const { mutate: createVersion } = useCreateNewRunbookVersion(runbookId)

  const { mutate: restoreRunbook } = useRunbookRestore({
    options: {
      onSuccess: () => {
        navigate(0)
      }
    }
  })

  const toggleFavorite = () => {
    if (runbookId) {
      setFavorite(
        favorite || {
          account_slug: slug,
          archived,
          is_template,
          name,
          template_type: templateType,
          id: runbookId
        }
      )
    }
  }

  const handleCreateNewVersion = () => {
    createVersion(
      { base_version_id: runbookVersionId },
      { onSuccess: handleCreateVersionResponse, onError: () => notify.error(t('createVersionError')) }
    )
  }

  const handleArchiveSuccess = () => {
    navigate(toWorkspace({ accountSlug: slug as string, templateType }))
  }

  const handleRestoreRunbook = () => {
    restoreRunbook({ runbookId })
  }

  const handleClickOption = (type: ModalActiveType['type']) => {
    if (type === 'version-create') return handleCreateNewVersion()
    if (type === 'runbook-restore') return handleRestoreRunbook()

    openModal({ type } as ModalActiveType)
  }

  return (
    <>
      <RunbookTitleLabel
        hasScheduledStart={hasScheduledStart}
        onClickSchedule={() => handleClickOption('runbook-schedule')}
      />
      <Box direction="row" css="margin-top: 1px; flex-shrink: 0;">
        <RunbookSettingsMenu onClickOption={handleClickOption} />
        <FavoriteStar
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          tooltip={{
            addFavorite: t('addFavorite'),
            removeFavorite: t('removeFavorite'),
            placement: 'bottom'
          }}
        />
        {hasScheduledStart && (
          <IconButton
            icon="timezones"
            label={activeTimezone ? t('runbookTimezone', { timezone: activeTimezone }) : t('setTimezone')}
            tipPlacement="bottom"
            size="medium"
            onClick={e => {
              e.stopPropagation()
              openModal({ type: 'runbook-selected-timezone' } as ModalActiveType)
            }}
            data-testid="runbook-toolbar-timezone-menu"
            // Note: the below needs design thought. Nds clear/compact way to indicate TZ on/off
            css={`
              opacity: ${activeTimezone ? 1 : 0.5};
            `}
          />
        )}
      </Box>
      {activeModal?.type === 'runbook-schedule' && (
        <ScheduledStartRunbookModal startScheduled={startScheduled} onClose={closeModal} />
      )}
      {activeModal?.type === 'runbook-selected-timezone' && <SelectedTimezoneModal onClose={closeModal} />}
      {activeModal?.type === 'runbook-ad-hoc-comms' && <AdHocCommsModal onClose={closeModal} />}
      {activeModal?.type === 'runbook-archive' && (
        <ArchiveRunbooksModal
          onClose={closeModal}
          selectedRunbookIds={[runbookId]}
          templateType={templateType}
          onSuccess={handleArchiveSuccess}
        />
      )}
      {activeModal?.type === 'runbook-restore' && (
        <IconButton icon="unarchive" label={t('restore')} onClick={() => handleClickOption('runbook-restore')} />
      )}
      {activeModal?.type === 'tasks-csv-import' && (
        <CsvImportModal
          open
          closeModal={closeModal}
          planningMode={!['live', 'rehearsal'].includes(run?.run_type || '')}
          resourceType={templateType === 'snippet' ? 'Snippet' : 'Runbook'}
        />
      )}
      {activeModal?.type === 'runbook-save-as-saved-view' && (
        <CreateSavedViewModal
          accountId={accountId}
          accountSlug={slug}
          runbookName={name}
          runbookId={runbookId}
          onClose={closeModal}
        />
      )}
      {activeModal?.type === 'runbook-make-template' && (
        <MakeTemplateModal onClose={closeModal} runbookId={runbookId} runbookVersionId={runbookVersionId} />
      )}
      {activeModal?.type === 'runbook-merge' && <MergeRunbookModal onClose={closeModal} />}
      {activeModal?.type === 'runbook-duplicate' && <RunbookDuplicateModalWrapper closeModal={closeModal} />}
      {activeModal?.type === 'runbook-view-history' && <ViewHistoryModal onClose={closeModal} />}
      {activeModal?.type === 'template-draft-version-delete' && (
        <DeleteTemplateDraftVersionModal
          onClose={closeModal}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
        />
      )}
    </>
  )
}

const RunbookTitleLabel = ({
  onClickSchedule,
  hasScheduledStart
}: {
  onClickSchedule: () => void
  hasScheduledStart: boolean
}) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const {
    template_version: templateVersion,
    approval_status: approvalStatus,
    is_current: isCurrent
  } = ActiveRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const {
    linked_runbook_details: linkedRunbookDetails,
    restricted: sensitiveRunbook,
    template_status: snippetApprovalStatus,
    template_type: templateType
  } = ActiveRunbookModel.useGet()

  const runType = run?.run_type
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const runbookType = ActiveRunbookModel.useRunbookType()
  const isChildRunbook = !!linkedRunbookDetails?.id
  const showScheduleButton =
    !hasScheduledStart && canUpdateRunbook && isCurrent && templateType === 'off' && !isChildRunbook

  const pillProps = useMemo(() => {
    // incident runbook
    if (runbookType?.incident) {
      return sensitiveRunbook
        ? { label: t('sensitiveIncident'), color: 'primary' }
        : { label: t('incident'), color: 'primary' }
    }

    // has run type
    if (!!runType) {
      if (runType === 'live') {
        return runbookType.dynamic
          ? { label: t('liveRunDynamic'), color: 'primary' }
          : { label: t('liveRun'), color: 'primary' }
      } else if (runType === 'rehearsal') {
        return runbookType.dynamic
          ? { label: t('rehearsalDynamic'), color: 'secondary' }
          : { label: t('rehearsal'), color: 'secondary' }
      }
    }

    // template type
    if (templateType === 'snippet') {
      return {
        label: t(`snippetApprovalStatus.${snippetApprovalStatus}`),
        color: SNIPPET_STATUS_COLOR_MAP[snippetApprovalStatus ?? 'template_draft']
      }
    } else if (templateType === 'default' && approvalStatus) {
      return {
        label: t(`templateApprovalStatus.${approvalStatus}`, { version: templateVersion }),
        color: STATUS_COLOR_MAP[approvalStatus]
      }
    }

    return null
  }, [approvalStatus, snippetApprovalStatus, templateType, runbookType, runType, sensitiveRunbook, t])

  return pillProps ? (
    <Pill
      {...pillProps}
      data-testid={'runbook-toolbar-pill-' + pillProps.label}
      css={`
        position: relative;
        top: 6px;
        min-width: fit-content;
        cursor: default;
      `}
    />
  ) : showScheduleButton ? (
    <Button
      primary
      label={t('schedule')}
      onClick={onClickSchedule}
      size="small"
      css={`
        margin-left: 4px;
        top: 1px;
      `}
    />
  ) : null
}
