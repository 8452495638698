import styled from 'styled-components'

import { Box, Button, themeColor } from '@cutover/react-ui'
import { ChangeRequest, ChangeTask } from 'main/services/queries/types'

type ChangeStatusButtonProps = {
  label: string
  changeRequest: ChangeRequest
  changeTask?: ChangeTask
  onClick: (changeRequest: ChangeRequest, changeTask?: ChangeTask) => void
  canSelectChangeRequest?: boolean
  disabled?: boolean
}

export const ChangeStatusButton = ({
  label,
  changeRequest,
  changeTask,
  onClick,
  canSelectChangeRequest = false,
  disabled = false
}: ChangeStatusButtonProps) => {
  const changeStart = changeTask ? changeRequest.change_task_starts?.[changeTask.id] : changeRequest.change_start
  const changeFinish = changeTask ? changeRequest.change_task_finishes?.[changeTask.id] : changeRequest.change_finish

  return (
    <ChangeLinkContainer
      alignSelf="center"
      changeStart={changeStart}
      changeFinish={changeFinish}
      changeTask={!!changeTask}
      canSelectChangeRequest={canSelectChangeRequest}
    >
      <Button
        as="div"
        secondary
        label={label}
        size="small"
        disabled={disabled}
        onClick={e => {
          e.stopPropagation()
          onClick(changeRequest, changeTask)
        }}
      />
    </ChangeLinkContainer>
  )
}

const ChangeLinkContainer = styled(Box).attrs({})<{
  changeStart?: number
  changeFinish?: number
  changeTask?: boolean
  canSelectChangeRequest?: boolean
}>`
  position: relative;
  right: ${({ canSelectChangeRequest }) => (canSelectChangeRequest ? '14px' : '0')};
  margin-right: ${({ canSelectChangeRequest }) => (canSelectChangeRequest ? '0' : '8px')};

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: ${({ canSelectChangeRequest }) => (canSelectChangeRequest ? '-8px' : '0')};
    width: 8px;
    height: 8px;
    opacity: 0.5;
    background-color: ${themeColor('success')};
    border-radius: 50%;
    display: ${({ changeStart }) => (changeStart ? 'block' : 'none')};
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: ${({ canSelectChangeRequest }) => (canSelectChangeRequest ? '-8px' : '0')};
    width: 8px;
    height: 8px;
    opacity: 0.5;
    background-color: ${themeColor('success')};
    border-radius: 50%;
    display: ${({ changeFinish }) => (changeFinish ? 'block' : 'none')};
    z-index: 2;
  }
`
