import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, LinkButton, Message, Modal, useNotify } from '@cutover/react-ui'
import { EnterSnippetDetails } from './enter-snippet-details'
import { ValidateSnippet } from './validate-snippet'
import { useLanguage } from 'main/services/hooks'
import { DuplicateRunbookResponseType, useDuplicateRunbook } from 'main/services/queries/use-duplicate-runbooks'
import { ConfigModel, FieldOptionModel, TaskModel } from 'main/data-access'
import { useCustomFieldFormHelpers } from 'main/components/shared/custom-field-form/custom-field-form-helpers'

type CreateSnippetProps = {
  runbookId: number
  taskInternalIds: number[]
  triggeredFromAngular?: boolean
  onClose?: () => void
}

type FormValues = {
  name: string
}

export const CreateSnippetFromRunbookModal = ({
  runbookId,
  taskInternalIds,
  triggeredFromAngular,
  onClose
}: CreateSnippetProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [modalStep, setModalStep] = useState<'enterDetailsModal' | 'validateSnippet'>('enterDetailsModal')
  const [errors, setErrors] = useState<string[]>([])
  const { t } = useLanguage('runbooks')
  const mutation = useDuplicateRunbook(runbookId)
  const notify = useNotify()
  const getFieldOptionsLookup = FieldOptionModel.useGetLookupCallback()
  const getTasksLookup = TaskModel.useGetLookupCallback()
  const isReactRunbook = ConfigModel.useIsFeatureEnabled('react_runbook')
  const { getArchivedFieldValueErrors, getArchivedFieldValues } = useCustomFieldFormHelpers()

  useEffect(() => {
    if (!isReactRunbook) return

    const setArchivedFieldOptionErrors = async () => {
      const fieldOptionsLookup = await getFieldOptionsLookup()
      const taskLookup = await getTasksLookup({ keyBy: 'internal_id' })
      const tasks = taskInternalIds.map(id => taskLookup[id])
      const errors = getArchivedFieldValueErrors({
        archivedFieldValues: getArchivedFieldValues({ items: tasks, fieldOptionsLookup, keyBy: 'internal_id' }),
        fieldOptionsLookup,
        getItemName: internalId => taskLookup[internalId].name
      })
      if (errors.length) setErrors(errors)
    }

    setArchivedFieldOptionErrors()
  }, [])

  const unmountConnector = () => {
    eventManager.emit('hide-create-snippet-from-runbook-modals')
  }

  const closeModalWithUnmount = () => {
    setIsModalOpen(false)
    if (triggeredFromAngular) {
      unmountConnector()
    } else {
      onClose?.()
    }
  }

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(t('enterSnippetDetails.fields.name.errors.required'))
      })
    ),
    defaultValues: {
      name: ''
    }
  })

  const onFormSubmit = async ({ name }: FormValues) => {
    const payload = {
      name,
      template_type: 'snippet',
      template_status: 'template_draft',
      task_internal_ids: taskInternalIds,
      copy_tasks: true,
      copy_teams: false,
      copy_users: false
    }
    await mutation.mutateAsync(payload, {
      onSuccess: data => {
        const runbook: DuplicateRunbookResponseType = data

        if (runbook) {
          closeModalWithUnmount()
          notify.success(t('duplicateRunbookModal.notification.success.messageCreateSnippet', { name: name }), {
            title: t('duplicateRunbookModal.notification.success.title')
          })
        }
      }
    })
  }

  useEffect(() => {
    if (methods.formState.isValid) {
      methods.clearErrors()
    }
  }, [methods.formState.isValid])

  const isEnterDetailsModal = modalStep === 'enterDetailsModal'

  const modalContent = isEnterDetailsModal ? (
    <EnterSnippetDetails
      methods={methods}
      onClickContinue={() => {
        methods.trigger()

        return methods.formState.isValid ? setModalStep('validateSnippet') : undefined
      }}
    />
  ) : (
    <ValidateSnippet taskCount={taskInternalIds.length} />
  )

  const title = isEnterDetailsModal ? t('enterSnippetDetails.heading') : t('enterSnippetDetails.createSnippet')
  const confirmIcon = isEnterDetailsModal ? ('chevron-right' as const) : ('add' as const)
  const confirmText = isEnterDetailsModal
    ? t('common:nextButton')
    : mutation?.isLoading
    ? t('common:addingText')
    : t('common:addButton')
  const handleClickConfirm = isEnterDetailsModal
    ? methods.formState.isDirty
      ? () => setModalStep('validateSnippet')
      : undefined
    : methods.handleSubmit(onFormSubmit)

  return (
    <Box pad="large">
      <Modal
        title={title}
        open={isModalOpen}
        onClose={closeModalWithUnmount}
        hideFooter={false}
        onClickBack={isEnterDetailsModal ? undefined : () => setModalStep('enterDetailsModal')}
        confirmIcon={confirmIcon}
        confirmText={confirmText}
        confirmDisabled={errors.length > 0}
        onClickConfirm={handleClickConfirm}
        customButton={
          isEnterDetailsModal ? (
            <LinkButton href={t('enterSnippetDetails.learnMoreLink')} target="_blank" label={t('common:learnMore')} />
          ) : null
        }
      >
        {errors.length > 0 && (
          <Box margin={{ bottom: 'small' }}>
            <Message type="error" message={errors} />
          </Box>
        )}
        {modalContent}
      </Modal>
    </Box>
  )
}
