import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Message } from '@cutover/react-ui'
import { DuplicateRunbookForm } from './duplicate-runbook-form'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import {
  buildDefaultFieldValues,
  useCustomFieldFormHelpers
} from 'main/components/shared/custom-field-form/custom-field-form-helpers'
import { FormModal } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import {
  DuplicateRunbookResponseType,
  useBulkDuplicateRunbooks,
  useDuplicateRunbook
} from 'main/services/queries/use-duplicate-runbooks'
import { useRouting } from 'main/services/routing'
import {
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  RunbookListRunbook,
  RunbookShowRunbook,
  RunbookTypeType
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { useGetFieldOptionLookup } from 'main/data-access/hooks'

const formType = createValidationSchema(yup.object())
export type DuplicateRunbookFormSchema = yup.InferType<typeof formType>

type DuplicateRunbooksProps = {
  runbook?: RunbookListRunbook | RunbookShowRunbook
  runbookIds?: number[] | null
  runbookType?: RunbookTypeType
  reloadRunbookList?: () => void
  customFieldProps?: {
    customFieldsLookup?: Record<number, CustomField>
    customFieldUsers?: CustomFieldUser[]
    customFieldGroupsLookup?: Record<number, CustomFieldGroup>
    customFieldGroups?: CustomFieldGroup[]
  }
  hasPermissionOnExistingProject?: boolean
  projects?: PermittedProject[]
  open?: boolean
  loading?: boolean
  closeModal?: () => void
  errorMessage?: string[]
  warningMessage?: string[]
  context: 'single' | 'bulk-single' | 'bulk'
  templateType?: 'default' | 'snippet'
}

export const DuplicateRunbooksModal = ({
  closeModal,
  context,
  customFieldProps,
  errorMessage,
  hasPermissionOnExistingProject,
  open,
  projects,
  loading: loadingProp,
  reloadRunbookList,
  runbook,
  runbookIds,
  runbookType,
  templateType,
  warningMessage
}: DuplicateRunbooksProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const fieldOptionsLookup = useGetFieldOptionLookup()
  const navigate = useNavigate()
  const { toRunbook } = useRouting()
  const { runbookLookup, runbooks } = useWorkspaceData()
  const runbookTypeContext = !templateType ? 'runbook' : templateType === 'default' ? 'template' : 'snippet'
  const isSnippet = runbookTypeContext === 'snippet'
  const selectedRunbooks = runbooks?.filter(runbook => runbookIds?.includes(runbook.id))
  const showTemplateFolderLockNote = selectedRunbooks?.some(
    runbook => runbook.settings_lock_template_copies_to_folder && runbook.is_template
  )
  const [loading, setLoading] = useState(!isSnippet)

  const { getArchivedFieldValueErrors, getArchivedFieldValues } = useCustomFieldFormHelpers()

  const archivedFieldValues = useMemo(
    () => getArchivedFieldValues({ items: selectedRunbooks, fieldOptionsLookup }),
    [selectedRunbooks, fieldOptionsLookup]
  )

  const archivedErrors = useMemo(
    () =>
      context === 'bulk'
        ? getArchivedFieldValueErrors({
            archivedFieldValues,
            fieldOptionsLookup,
            getItemName: id => runbookLookup?.[id].name || ''
          })
        : [],
    [archivedFieldValues, context]
  )

  const { mutateAsync: bulkMutation } = useBulkDuplicateRunbooks(runbookIds || [])
  const { mutateAsync: singleMutation } = useDuplicateRunbook(runbook?.id)

  const {
    initialFieldValues,
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_add_edit'],
    validateRequired: context === 'bulk' || isSnippet,
    customFieldsLookup: customFieldProps?.customFieldsLookup,
    fieldValues: runbook?.field_values,
    customFieldGroups: customFieldProps?.customFieldGroups,
    constraintContext: { runbook_type_id: runbook?.runbook_type_id },
    excludeTransientFieldValues: true
  })

  const defaultValues: Partial<DuplicateRunbookFormSchema> = {
    context: runbookTypeContext,
    bulk: context !== 'single',
    single: context !== 'bulk',
    project_id: runbook?.project_id,
    name: context === 'single' ? `${runbook?.name} copy` : undefined,
    suffix: context !== 'single' ? 'copy' : undefined,
    copy_tasks: true,
    copy_teams: true,
    copy_users: true,
    project_modify_type: !isSnippet ? (hasPermissionOnExistingProject ? 'existing' : 'choose') : undefined,
    shift_time: !isSnippet ? true : undefined,
    field_values: !isSnippet
      ? buildDefaultFieldValues({ customFields, groupedCustomFields, fieldValues: initialFieldValues })
      : {}
  }

  const handleSuccess = (data?: DuplicateRunbookResponseType) => {
    if (context !== 'single') {
      reloadRunbookList?.()
      closeModal?.()
    } else {
      if (data) {
        const runbook: DuplicateRunbookResponseType = data
        if (runbook) {
          // Redirect to task list
          navigate(toRunbook({ accountSlug: runbook.account_slug, runbookId: runbook.id }))
        }
      }
    }
  }

  const transformer = (data: DuplicateRunbookFormSchema) => {
    const isProjectChecked = data.project_modify_type === 'choose' || data.single

    if (context !== 'single') {
      return {
        options: {
          suffix: data.suffix,
          copy_tasks: data.copy_tasks,
          copy_teams: data.copy_teams,
          copy_users: data.copy_users,
          is_template: !isSnippet ? false : undefined,
          project_id: isProjectChecked ? data.project_id : undefined,
          shift_time: !isSnippet ? data.shift_time : undefined,
          start_scheduled: data.start_scheduled,
          end_scheduled: data.end_scheduled,
          template_type: isSnippet ? 'snippet' : undefined,
          template_status: isSnippet ? 'template_draft' : undefined
        },
        runbook: {
          field_values_attributes:
            context === 'bulk-single'
              ? data.field_values && buildFieldValuesAttributesRequestData(data.field_values, true)
              : []
        },
        runbook_ids: runbookIds
      }
    } else {
      return {
        name: data.name,
        copy_tasks: data.copy_tasks,
        copy_teams: data.copy_teams,
        copy_users: data.copy_users,
        template_type: isSnippet ? 'snippet' : undefined,
        template_status: isSnippet ? 'template_draft' : undefined,
        shift_time: data.shift_time,
        project_id: isProjectChecked ? data.project_id : undefined,
        runbook_versions_attributes: [
          {
            start_scheduled: data.start_scheduled,
            end_scheduled: data.end_scheduled
          }
        ],
        field_values_attributes: data.field_values && buildFieldValuesAttributesRequestData(data.field_values, true)
      }
    }
  }

  // Transformer returns correct type for each endpoint
  const handleSubmit = (data: ReturnType<typeof transformer>) => {
    if (context !== 'single') {
      return bulkMutation(data as any)
    } else {
      return singleMutation(data as any)
    }
  }

  const title =
    context !== 'bulk' && runbookTypeContext === 'template'
      ? t('create', { count: 1, runbookTypeName: runbookType?.name })
      : context === 'bulk' && runbookTypeContext === 'template'
      ? t('create', { count: runbookIds?.length ?? 1, runbookTypeName: runbookType?.name })
      : t('duplicate', { count: runbookIds?.length ?? 1, context: runbookTypeContext })

  const successMessage =
    context !== 'single'
      ? t('notification.success.message', { count: runbookIds?.length ?? 1, context: runbookTypeContext })
      : undefined

  const customErrors = [...archivedErrors, ...(errorMessage || [])]

  return (
    <FormModal<DuplicateRunbookFormSchema, ReturnType<typeof transformer>>
      schema={createValidationSchema(fieldValueValidation)}
      title={title}
      confirmText={
        runbookTypeContext === 'template'
          ? t('createRunbookButton')
          : runbookTypeContext === 'runbook'
          ? t('duplicateRunbookButton')
          : t('duplicateSnippetButton')
      }
      confirmIcon={'add'}
      open={open}
      onClose={closeModal}
      onSuccess={handleSuccess}
      defaultValues={defaultValues}
      transformer={transformer}
      onSubmit={handleSubmit}
      successMessage={successMessage}
      loading={loading || loadingProp}
      confirmDisabled={!!customErrors?.length}
      customErrors={customErrors}
    >
      {warningMessage && (
        <Box margin={{ bottom: '16px' }}>
          <Message role="alert" type="warning" message={warningMessage} />
        </Box>
      )}
      {!loadingProp && (
        <DuplicateRunbookForm
          context={runbookTypeContext}
          isBulkDuplicate={context !== 'single'}
          isSnippet={isSnippet}
          isSingleRunbook={context !== 'bulk'}
          customFields={customFields}
          groupedCustomFields={groupedCustomFields}
          customFieldGroupsLookup={customFieldProps?.customFieldGroupsLookup}
          displayCustomFields={!isSnippet && !!runbook}
          customFieldUsers={customFieldProps?.customFieldUsers}
          hasPermissionOnExistingProject={hasPermissionOnExistingProject}
          projects={projects}
          runbook={runbook}
          setLoading={setLoading}
          showTemplateFolderLockNote={showTemplateFolderLockNote}
        />
      )}
    </FormModal>
  )
}

function createValidationSchema(fieldValuesValidation: any) {
  return yup.object({
    context: yup.string().oneOf(['runbook', 'template', 'snippet']),
    bulk: yup.boolean(),
    single: yup.boolean(),

    name: yup.string().when('bulk', {
      is: false,
      then: schema => schema.required()
    }),
    suffix: yup.string().nullable(),
    project_modify_type: yup
      .string()
      .oneOf(['choose', 'existing'])
      .when(['context', 'single'], {
        is: (context: 'runbook' | 'template' | 'snippet', single: boolean) => context !== 'snippet' && !single,
        then: schema => schema.required()
      }),
    project_id: yup
      .number()
      .nullable()
      .when(['project_modify_type', 'single', 'context'], {
        is: (
          project_modify_type: 'choose' | 'existing',
          single: boolean,
          context: 'runbook' | 'template' | 'snippet'
        ) => {
          return context !== 'snippet' && (single || project_modify_type === 'choose')
        },
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      }),
    copy_tasks: yup.boolean(),
    copy_teams: yup.boolean(),
    copy_users: yup.boolean(),
    field_values: fieldValuesValidation,
    start_scheduled: yup.date().notRequired(),
    end_scheduled: yup.date().notRequired(),
    shift_time: yup.boolean()
  })
}
