import { useMemo } from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'

import { Box, Button, Icon, IconName, Message, Text, themeColor } from '@cutover/react-ui'
import { ChangeRequest, ChangeTask, ChangeTaskWithMetadata, StandaloneChangeTask } from 'main/services/queries/types'
import { ActiveRunbookModel, ConfigModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { ChangeStatusButton } from './change-status-button'
import { useChangeRequestPermissions } from './use-change-request-permissions'
import { useChangeRequestMetadata, useChangeTaskMetadata } from './use-change-request-metadata'
import { useChangeRequestChangeWindow } from './use-change-request-change-window'

type ChangeRequestItemContentProps = {
  changeRequest: ChangeRequest
  standaloneChangeTasks: Map<number, StandaloneChangeTask[]>
  linkChangeRequestCutoverTask: (changeRequest: ChangeRequest, changeTask?: ChangeTask) => void
  editChangeTask: (changeRequest: ChangeRequest, changeTask: ChangeTask) => void
  createChangeTask: (changeRequest: ChangeRequest) => void
}

const CONTENT_PADDING_LEFT = 25

export const ChangeRequestItemContent = ({
  changeRequest,
  standaloneChangeTasks,
  linkChangeRequestCutoverTask,
  editChangeTask,
  createChangeTask
}: ChangeRequestItemContentProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const changeGovernanceIntegrationEnabled = ConfigModel.useIsFeatureEnabled('change_governance_integration')
  const { changeRequestInteractive } = ConfigModel.useGet()
  const { canShowNewTaskButton } = useChangeRequestPermissions(changeRequest)

  const hasStandaloneChangeTasks = useMemo(
    () => (standaloneChangeTasks && standaloneChangeTasks.get(changeRequest.id)?.length) || false,
    [standaloneChangeTasks, changeRequest]
  )

  return (
    <>
      {changeRequest.error && (
        <Box css="margin-bottom: 8px; word-wrap: break-word;">
          <Message type="error" message={changeRequest.error} />
        </Box>
      )}
      <Box pad="xsmall" gap="xsmall">
        <ChangeRequestContent changeRequest={changeRequest} />
        {changeGovernanceIntegrationEnabled && !changeRequestInteractive && hasStandaloneChangeTasks && (
          <StandaloneChangeTaskContent changeTasks={standaloneChangeTasks.get(changeRequest.id) ?? []} />
        )}
        {!changeGovernanceIntegrationEnabled && changeRequestInteractive && changeRequest.change_tasks?.length > 0 && (
          <ChangeTaskContent
            changeRequest={changeRequest}
            linkChangeRequestCutoverTask={linkChangeRequestCutoverTask}
            editChangeTask={editChangeTask}
          />
        )}
        {canShowNewTaskButton && (
          <Button
            label={t('newTaskButtonLabel')}
            secondary
            size="small"
            onClick={() => createChangeTask(changeRequest)}
          />
        )}
      </Box>
    </>
  )
}

const ChangeRequestContent = ({ changeRequest }: { changeRequest: ChangeRequest }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const { changeRequestInteractive } = ConfigModel.useGet()
  const runbookType = ActiveRunbookModel.useRunbookType()
  const { states } = runbookType.change_request_types[0]
  const { formatMetadataValue, relevantChangeRequestMetadata } = useChangeRequestMetadata(changeRequest)
  const { withinChangeWindow, formatChangeWindow } = useChangeRequestChangeWindow(changeRequest)

  const stateColorMap = useMemo(() => {
    return new Map(states.map(s => [s.identifier, s.properties.color]))
  }, [states])

  return (
    <Box data-testid="change-request-item-content">
      <Box direction="row" gap="xxsmall" css="padding-bottom: 4px;">
        <Icon
          icon={
            changeRequestStatusIcon[
              stateColorMap.get(changeRequest.current_state) as keyof typeof changeRequestStatusIcon
            ].icon as IconName
          }
          color={
            changeRequestStatusIcon[
              stateColorMap.get(changeRequest.current_state) as keyof typeof changeRequestStatusIcon
            ].color
          }
          size="medium"
        />
        <Text weight="bold">{t('status')}</Text>
        <Text>{states.find(s => s.identifier === changeRequest.current_state)?.label}</Text>
      </Box>
      {changeRequest.interactivity_control_store?.ics_show_change_window && (
        <Box direction="row" gap="xxsmall">
          <Icon
            icon={withinChangeWindow() ? 'check-solid' : 'report-problem'}
            color={withinChangeWindow() ? 'success' : 'warning'}
            size="medium"
          />
          <Text weight="bold">{t('window')}</Text>
          <Text>{formatChangeWindow()}</Text>
        </Box>
      )}
      <Box
        css={`
          padding-left: ${CONTENT_PADDING_LEFT}px;
        `}
        gap="xxsmall"
      >
        <Box direction="row" gap="xxsmall">
          <Text weight="bold">{t('lastUpdated')}</Text>
          <Text>{format(new Date(changeRequest.last_checked_at), 'do MMM yyyy, HH:mm')}</Text>
        </Box>
        {changeRequestInteractive && (
          <>
            <InlineRow>
              <Text weight="bold">{t('cutoverStartTask')}</Text>
              {changeRequest.change_start && <Text>#{changeRequest.change_start}</Text>}
            </InlineRow>
            <InlineRow>
              <Text weight="bold">{t('cutoverFinishTask')}</Text>
              {changeRequest.change_finish && <Text>#{changeRequest.change_finish}</Text>}
            </InlineRow>
          </>
        )}
        {relevantChangeRequestMetadata().map(key => (
          <InlineRow key={key}>
            <Text weight="bold" css="text-transform: capitalize;">
              {key.replace(/_/g, ' ')}:
            </Text>
            <Text>{formatMetadataValue(key)}</Text>
          </InlineRow>
        ))}
      </Box>
    </Box>
  )
}

const StandaloneChangeTaskContent = ({ changeTasks }: { changeTasks: StandaloneChangeTask[] }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const { relevantStandaloneChangeTaskMetadataCallback, formatStandaloneMetadataValue } = useChangeTaskMetadata()

  return (
    <Box
      css={`
        padding-left: ${CONTENT_PADDING_LEFT}px;
      `}
      data-testid="change-task-content"
    >
      <Text weight="bold">{t('taskTitle')}</Text>
      {changeTasks.map(changeTask => (
        <Box
          key={changeTask.id}
          margin={{ vertical: 'xsmall' }}
          gap="xxsmall"
          css={`
            border-top: 1px solid ${themeColor('border')};
            padding-top: 8px;
          `}
        >
          <Text>{changeTask.external_id}</Text>
          {relevantStandaloneChangeTaskMetadataCallback(changeTask).map(key => (
            <InlineRow key={key}>
              <Text weight="bold" size="small" css="text-transform: capitalize;">
                {key.replace(/_/g, ' ')}:
              </Text>
              <Text size="small">{formatStandaloneMetadataValue(changeTask, key)}</Text>
            </InlineRow>
          ))}
        </Box>
      ))}
    </Box>
  )
}

type ChangeTaskContentProps = {
  changeRequest: ChangeRequest
  linkChangeRequestCutoverTask: (changeRequest: ChangeRequest, changeTask?: ChangeTask) => void
  editChangeTask: (changeRequest: ChangeRequest, changeTask: ChangeTask) => void
}

const ChangeTaskContent = ({ changeRequest, linkChangeRequestCutoverTask, editChangeTask }: ChangeTaskContentProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const { canLinkChangeTask, canFinishChangeTaskCallback: canFinishChangeTask } =
    useChangeRequestPermissions(changeRequest)
  const { relevantChangeTaskMetadataCallback: relevantChangeTasktMetadata } = useChangeTaskMetadata()

  return (
    <Box
      css={`
        padding-left: ${CONTENT_PADDING_LEFT}px;
      `}
      data-testid="change-task-content"
    >
      <Text weight="bold">{t('taskTitle')}</Text>
      {changeRequest.change_tasks.map(changeTask => (
        <Box
          key={changeTask.id}
          margin={{ vertical: 'xsmall' }}
          gap="xxsmall"
          css={`
            border-top: 1px solid ${themeColor('border')};
            padding-top: 8px;
          `}
        >
          <Box width="100%" direction="row">
            {canLinkChangeTask && (
              <Box direction="row" flex="shrink">
                <ChangeStatusButton
                  label={t('linkButtonLabel')}
                  changeRequest={changeRequest}
                  changeTask={changeTask}
                  onClick={linkChangeRequestCutoverTask}
                />
              </Box>
            )}
            {canFinishChangeTask(changeTask) && (
              <ChangeStatusButton
                label={t('finishButtonLabel')}
                changeRequest={changeRequest}
                changeTask={changeTask}
                onClick={() => editChangeTask(changeRequest, changeTask)}
              />
            )}
            <Box flex="grow">
              <Text>{changeTask.external_id}</Text>
            </Box>
          </Box>
          <InlineRow>
            <Text weight="bold" size="small">
              {t('cutoverStartTask')}
            </Text>
            {changeRequest.change_task_starts?.[changeTask.id] && (
              <Text size="small">#{changeRequest.change_task_starts?.[changeTask.id]}</Text>
            )}
          </InlineRow>
          <InlineRow>
            <Text weight="bold" size="small">
              {t('cutoverFinishTask')}
            </Text>
            {changeRequest.change_task_finishes?.[changeTask.id] && (
              <Text size="small">#{changeRequest.change_task_finishes?.[changeTask.id]}</Text>
            )}
          </InlineRow>
          {relevantChangeTasktMetadata(changeTask as ChangeTaskWithMetadata).map(key => (
            <InlineRow key={key}>
              <Text weight="bold" size="small" css="text-transform: capitalize;">
                {key.replace(/_/g, ' ')}:
              </Text>
              <Text size="small">{(changeTask as ChangeTaskWithMetadata)[key]}</Text>
            </InlineRow>
          ))}
        </Box>
      ))}
    </Box>
  )
}

const InlineRow = styled(Box)`
  display: block;
  white-space: normal;
  span:first-child {
    padding-right: 6px;
  }
`

const changeRequestStatusIcon = {
  amber: {
    icon: 'alert',
    color: 'orange'
  },
  red: {
    icon: 'close',
    color: 'error'
  },
  green: {
    icon: 'check-solid',
    color: 'success'
  },
  refreshing: {
    icon: 'refresh',
    color: 'blue'
  },
  completed: {
    icon: 'check-solid',
    color: 'success'
  }
}
