import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { Box, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  FieldOptionModel,
  RunbookViewModel,
  StreamModel,
  TaskModel
} from 'main/data-access'
import { TaskLineItemWrapper } from 'main/components/shared/task-line-item'
import { TaskListTask } from 'main/services/queries/types'
import { pasteTasks, TaskPastePayload } from 'main/services/queries/use-tasks'
import { FormModal, RadioboxGroupField, StreamSelectField, TextInputField } from 'main/components/shared/form'
import { RunbookTaskPasteResponse } from 'main/services/api/data-providers/runbook-types'
import { useCustomFieldFormHelpers } from 'main/components/shared/custom-field-form/custom-field-form-helpers'

type TasksPasteModalProps = {
  taskId?: number
  taskName?: string
  onClose: () => void
}

type PastePosition = 'after' | 'before' | 'start'
type DestinationStream = 'unchanged' | 'select' | 'sameAsTarget'

const validationSchema = yup.object({
  position: yup.string().oneOf(['after', 'before', 'start']),
  suffix: yup.string(),
  destinationStream: yup.string().oneOf(['unchanged', 'select', 'sameAsTarget']),
  streamId: yup
    .number()
    .nullable()
    .when(['destinationStream'], {
      is: (destinationStream: DestinationStream) => destinationStream === 'select',
      then: schema => schema.required(),
      otherwise: schema => schema.notRequired()
    })
})

type TaskPasteForm = yup.InferType<typeof validationSchema>

export const TasksPasteModal = ({ taskId, taskName, onClose }: TasksPasteModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskPasteModal' })
  const notify = RunbookViewModel.useAction('notify')
  const copyIds = RunbookViewModel.useGet('copyIds')
  const permittedStreams = StreamModel.useGetAll({ scope: 'permitted' })
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const taskLookup = TaskModel.useGetLookup()
  const processTaskPasteResponse = TaskModel.useOnAction('paste')
  const hasTargetTask = !!taskId
  const [customFormErrors, setCustomFormErrors] = useState<string[]>([])
  const fieldOptionsLookup = FieldOptionModel.useGetLookup()
  const { getArchivedFieldValueErrors, getArchivedFieldValues } = useCustomFieldFormHelpers()

  useEffect(() => {
    const tasks = copyIds.map(id => taskLookup[id])

    const errors = getArchivedFieldValueErrors({
      archivedFieldValues: getArchivedFieldValues({ items: tasks, fieldOptionsLookup }),
      fieldOptionsLookup,
      getItemName: id => taskLookup[id].name
    })
    if (errors.length) setCustomFormErrors(errors)
  }, [copyIds, taskLookup])

  const handleSubmit = async (payload: TaskPastePayload) => {
    if (copyIds.length === 0) {
      onClose()
      return
    }

    if (!canEditSelectedTasks()) {
      setCustomFormErrors([t('noPermission')])
      return
    }

    return await pasteTasks(runbookId, runbookVersionId, payload)
  }

  const canEditSelectedTasks = () => {
    for (const id of copyIds) {
      const task = taskLookup[id]
      if (!permittedStreams.find(stream => stream.id === task.stream_id)) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (copyIds.length === 0) {
      setCustomFormErrors([t('tasksDeleted')])
    }
  }, [copyIds])

  const transformer = (data: TaskPasteForm): TaskPastePayload => {
    const { position, suffix, destinationStream, streamId } = data
    let targetTask: TaskListTask | undefined = taskId ? taskLookup[taskId] : undefined

    const selectedStreamId =
      destinationStream === 'select'
        ? streamId
        : hasTargetTask && destinationStream === 'sameAsTarget' && targetTask
        ? targetTask.stream_id
        : undefined

    const payload: TaskPastePayload = {
      task_internal_ids: copyIds.map(id => taskLookup[id].internal_id),
      target_task_id: taskId ?? null,
      position: position as PastePosition,
      suffix: suffix ?? '',
      ...(selectedStreamId && { stream_id: selectedStreamId })
    }

    return payload
  }

  return (
    <FormModal<TaskPasteForm, TaskPastePayload>
      title={t('title', { count: copyIds.length })}
      open
      onSubmit={handleSubmit}
      transformer={transformer}
      customErrors={customFormErrors}
      onSuccess={(response: RunbookTaskPasteResponse) => {
        processTaskPasteResponse(response)
        notify.success(t('pasteSuccess', { count: copyIds.length }), {
          title: t('pasteSuccessTitle', { count: copyIds.length })
        })
      }}
      confirmDisabled={!!customFormErrors.length}
      onClose={onClose}
      confirmText={copyIds.length > 0 ? t('confirmButton', { count: copyIds.length }) : t('confirmButtonOk')}
      confirmIcon={copyIds.length > 0 ? 'paste' : null}
      schema={validationSchema}
      defaultValues={{
        position: hasTargetTask ? 'after' : 'start',
        suffix: 'copy',
        destinationStream: hasTargetTask ? 'sameAsTarget' : 'unchanged'
      }}
    >
      {copyIds.length > 0 && <TaskPasteModalInner taskId={taskId} taskName={taskName} />}
    </FormModal>
  )
}

const TaskPasteModalInner = ({ taskId, taskName }: Omit<TasksPasteModalProps, 'onClose'>) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskPasteModal' })
  const copyIds = RunbookViewModel.useGet('copyIds')
  const permittedStreams = StreamModel.useGetAll({ scope: 'permitted' })
  const { watch } = useFormContext<TaskPasteForm>()
  const suffix = watch('suffix')
  const destinationStream = watch('destinationStream')
  const streamId = watch('streamId')
  const position = watch('position')

  const hasTargetTask = !!taskId
  const noSuffix = suffix?.trim().length === 0

  const positionOptions: { label: string; value: PastePosition }[] = [
    { label: t('positionAfter'), value: 'after' },
    { label: t('positionBefore'), value: 'before' },
    { label: t('positionStart'), value: 'start' }
  ]

  const destinationStreamOptions: { label: string; value: DestinationStream }[] = [
    { label: t('streamOriginal'), value: 'unchanged' },
    { label: t('streamChoose'), value: 'select' }
  ]
  if (hasTargetTask) {
    destinationStreamOptions.splice(1, 0, { label: t('streamSameAsSelected'), value: 'sameAsTarget' })
  }

  return (
    <Box direction="column">
      {hasTargetTask && (
        <RadioboxGroupField<TaskPasteForm>
          name="position"
          label={t('pastePositionLabel')}
          direction="row"
          options={positionOptions}
        />
      )}
      <RadioboxGroupField<TaskPasteForm>
        name="destinationStream"
        label={t('destinationStreamLabel')}
        direction="row"
        options={destinationStreamOptions}
      />
      {destinationStream === 'select' && (
        <StreamSelectField<TaskPasteForm>
          name="streamId"
          label={t('streamLabel')}
          placeholder={t('streamPlaceholder')}
          required
          hasError={!streamId}
          streams={permittedStreams ?? []}
        />
      )}
      <TextInputField name="suffix" label={t('suffixLabel')} />
      <Text>{parse(t('clipboard', { count: copyIds.length }))}</Text>
      <Box
        css={`
          max-height: 196px;
          overflow: auto;
          margin: 8px 0;
        `}
      >
        {copyIds.map(id => (
          <TaskLineItemWrapper key={id} taskId={id} />
        ))}
      </Box>
      <Text>
        {position === 'start'
          ? parse(t(noSuffix ? 'pasteStart' : 'pasteStartSuffix', { count: copyIds.length, suffix }))
          : parse(
              t(noSuffix ? 'pastePosition' : 'pastePositionSuffix', {
                count: copyIds.length,
                position,
                taskName,
                suffix
              })
            )}
      </Text>
    </Box>
  )
}
