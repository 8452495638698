import { atom, selector, selectorFamily } from 'recoil'
import { keyBy } from 'lodash'

import { ChangeRequest } from 'main/services/queries/types'

export const changeRequestListResponseState_INTERNAL = atom<{ change_requests: ChangeRequest[] }>({
  key: 'change-requests:response',
  default: {
    change_requests: []
  }
})

export const changeRequestsState = selector({
  key: 'change-requests:list',
  get: ({ get }) => {
    const { change_requests } = get(changeRequestListResponseState_INTERNAL)
    return change_requests
  }
})

export const changeRequestsLookup = selector<Record<number, ChangeRequest>>({
  key: 'change-requests:lookup',
  get: ({ get }) => {
    const changeRequests = get(changeRequestsState)
    return keyBy(changeRequests, 'id')
  }
})

export const changeRequestState = selectorFamily({
  key: 'change-requests:id',
  get:
    (id: number) =>
    ({ get }) => {
      const lookup = get(changeRequestsLookup)
      return lookup[id]
    }
})
