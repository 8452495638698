import { memo, ReactNode, useMemo } from 'react'
import styled from 'styled-components/macro'

import { Box } from '../../layout'
import { CheckboxGroup } from '../checkbox-group/checkbox-group'
import { AnimatedFormField, FieldLabelTextWrap } from '../internal/form-field'
import { MultiSelect, RenderMultiSelectOptionProps } from '../select/select/multi-select'
import { SelectListItem } from '../select/select-list-item'

type Option = {
  label: string
  value: number | string
  suffix?: React.ReactNode
  hasError?: boolean
}

type MultiSelectControlProps = {
  options: Option[]
  value: (number | string)[] | undefined
  a11yTitle?: string
  filterKeys?: string[]
  label?: string
  additionalControls?: ReactNode
  plain?: boolean
  hasError?: boolean
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  onCreateOption?: (value: string) => void
  onChange?: (value?: Option[]) => void
}

export const MultiSelectControl = memo(
  ({
    options,
    value,
    a11yTitle,
    filterKeys,
    additionalControls,
    plain = false,
    label,
    hasError,
    disabled,
    readOnly,
    required,
    onCreateOption,
    onChange
  }: MultiSelectControlProps) => {
    const selectedOptions = useMemo(() => {
      return Array.isArray(value) ? options.filter(option => value.includes(option.value)) : []
    }, [value, options])

    const renderMultiSelectOption = (
      option: (typeof options)[number],
      renderProps: RenderMultiSelectOptionProps<(typeof options)[number]>
    ) => {
      return <SelectListItem {...renderProps} label={option.label} suffix={option.suffix} />
    }

    return options.length > 10 ? (
      <Wrapper showWrapper={plain} wrapper={children => <MultiSelectWrapper>{children}</MultiSelectWrapper>}>
        <MultiSelect
          plain={plain}
          icon="search"
          value={selectedOptions}
          options={options}
          renderOption={renderMultiSelectOption}
          additionalControls={additionalControls}
          a11yTitle={a11yTitle}
          filterKeys={filterKeys ?? ['label']}
          label={label}
          hasError={hasError}
          onChange={val => {
            onChange?.(val)
          }}
          onCreateOption={onCreateOption}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          selectedCheckboxGroup
        />
      </Wrapper>
    ) : (
      <>
        <CheckboxGroup
          hasError={hasError}
          plain={plain}
          options={options}
          a11yTitle={a11yTitle}
          label={label}
          value={value}
          direction={'column'}
          onCreateOption={onCreateOption}
          readOnly={readOnly}
          required={required}
          onChange={(event: { value: number[] } | any) => {
            const selectedOptions = options.filter(option => event.value.includes(option.value))
            onChange?.(selectedOptions)
          }}
        />
        {additionalControls}
      </>
    )
  }
)

const Wrapper = ({
  showWrapper,
  wrapper,
  children
}: {
  showWrapper: boolean
  wrapper: (children: ReactNode) => JSX.Element
  children: ReactNode
}) => (showWrapper ? wrapper(children) : <>{children}</>)

const MultiSelectWrapper = styled(Box)`
  ${FieldLabelTextWrap} {
    display: none;
  }
  ${AnimatedFormField} {
    min-height: auto;
  }
  label {
    padding: 0;
  }
`
