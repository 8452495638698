import { Simplify } from 'type-fest'

import { ChangeRequest } from 'main/services/queries/types'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  CollectionModelInterfaceWithActions as WithActions
} from './types'
import {
  ChangeRequestActionType,
  useChangeRequest,
  useChangeRequestAction,
  useChangeRequestCallback,
  useChangeRequests,
  useChangeRequestsCallback,
  useChangeRequestsLookup,
  useChangeRequestsLookupCallback,
  useOnActionChangeRequest
} from '../hooks/recoil/change-request'

type ChangeRequestCollectionAccessScope = ModelCollectionAccessScope<null>

export type ChangeRequestModelType = Simplify<
  CollectionModelInterfaceBase<ChangeRequest, ChangeRequestCollectionAccessScope> &
    Omit<WithActions<'ChangeRequest'>, 'usePermission' | 'usePermissionCallback'> & {
      useAction: <Taction extends keyof ChangeRequestActionType>(action: Taction) => ChangeRequestActionType[Taction]
    }
>

export const ChangeRequestModel: ChangeRequestModelType = {
  useGet: useChangeRequest,
  useGetCallback: useChangeRequestCallback,

  useGetAll: useChangeRequests,
  useGetAllCallback: useChangeRequestsCallback,

  useGetLookup: useChangeRequestsLookup,
  useGetLookupCallback: useChangeRequestsLookupCallback,

  useAction: useChangeRequestAction,
  useOnAction: useOnActionChangeRequest
}
