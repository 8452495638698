// @ts-nocheck
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 } from 'uuid'
import styled, { css } from 'styled-components'
import { colors, fontSizes, px2rem, transition } from 'app/Styles/Variables'
import { HelpText } from 'Components/Molecules'
import AsyncSelect from 'react-select/async'
import ReactSelect from 'react-select'
import { SelectPresenter } from './SelectPresenter'
import { TSelectOptionTypes } from './TSelectOptionTypes'
import { Icon } from 'Components/Atoms'
import { SelectStyle } from 'app/Styles/Common'
import { SELECT_MENU_PORTAL } from 'app/Shared/Helpers/DOM'

interface IWrapperProps {
  color: string
  borderColor: string
  inline: boolean
  customSize: string
}

const Wrapper = styled.div`
  position: relative;
  overflow: visible;
  ${(props: IWrapperProps) =>
    !props.inline &&
    css`
      width: 100%;
      border-bottom: 1px dashed ${props.borderColor};
      padding-top: ${px2rem(15)};
      margin-bottom: ${px2rem(16)};
    `}

  ${(props: IWrapperProps) => props.customSize && `width: ${props.customSize};`}
`

interface ILabelProps {
  color: string
  isShrunk: boolean
  hasPrefix: boolean
}

const Label = styled.label`
  ${transition('xfast')};
  white-space: nowrap;
  position: absolute;
  z-index: -2;

  color: ${(props: ILabelProps) => props.color};

  font-size: ${(props: ILabelProps) => (props.isShrunk ? fontSizes.sm : fontSizes.m)};
  font-weight: ${(props: ILabelProps) => (props.isShrunk ? '500' : '400')};

  top: ${(props: ILabelProps) => (props.isShrunk ? 0 : px2rem(18))};
  left: ${(props: ILabelProps) => (props.hasPrefix && !props.isShrunk ? px2rem(28) : '0')};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: ${px2rem(24)};
  min-height: ${px2rem(32)};
  max-height: ${px2rem(32)};
`

const IconWrapper = styled.div`
  margin-top: ${px2rem(4)};
`

const SelectIconExtraStyles = {
  cursor: 'pointer',
  width: px2rem(24)
}

interface IReactSelectProps {
  menuPortalTarget: HTMLElement
  components: { [key: string]: () => JSX.Element }
  options: TSelectOptionTypes[]
  isDisabled: boolean
  noOptionsMessage: () => string
  loadOptions: (inputText: string) => Promise<TSelectOptionTypes[]>
  placeholder: string
  onChange: (option: TSelectOptionTypes) => void
  isClearable: boolean
  onBlur: () => void
  onFocus: () => void
  value: TSelectOptionTypes
  styles: any
  autoFocus: boolean
  maxMenuHeight: number
}

interface ISelectProps {
  presenter: SelectPresenter
  customSize?: string
  getFocused?: (focused: boolean) => void
  customOnChange?: () => void
  autoFocusOnMount?: boolean
  notClearable?: boolean
  extraStyles?: CSSProperties
  maxMenuHeight?: number
}

export const Select = observer<ISelectProps>(props => {
  const {
    customSize,
    getFocused,
    presenter,
    customOnChange,
    autoFocusOnMount,
    notClearable,
    extraStyles,
    maxMenuHeight
  } = props

  const {
    value,
    onChange,
    label,
    disabled,
    isValid,
    isDirty,
    required,
    options,
    helpText,
    placeholder,
    iconPrefix,
    loading,
    markAsDirty,
    inlineComponent,
    asyncOptionsGetter,
    handleAsyncOptionsRequest,
    inModal,
    language
  } = presenter

  const [isFocused, setFocused] = useState(false)
  const [id] = useState(v4())

  const changeHandler = (option: TSelectOptionTypes) => {
    if (customOnChange) {
      customOnChange(option)
    }

    onChange(option ? option.value : undefined)
  }

  const focusHandler = () => {
    setFocused(!isFocused)
    getFocused && getFocused(!isFocused)
  }

  const blurHandler = () => {
    setFocused(!isFocused)
    getFocused && getFocused(!isFocused)
    markAsDirty()
  }

  let color = isFocused ? colors.primary : colors.textLighter
  let borderColor = isFocused ? colors.primary : colors.primaryGreyHoverBackgroundColor

  if (!isValid && isDirty) {
    color = colors.error
    borderColor = colors.error
  }

  const isShrunk = !!(isFocused || value !== undefined) || !!autoFocusOnMount
  const hasPrefix = !!iconPrefix
  const labelRender = required && label ? `${label} *` : label

  const SelectComponent = (props: IReactSelectProps) => {
    return asyncOptionsGetter ? <AsyncSelect {...props} /> : <ReactSelect {...props} />
  }

  const helpTextRender = helpText ? <HelpText text={helpText} /> : null

  const DropdownIndicator = () => (
    <IconWrapper>
      {loading ? (
        <Icon name="spinner" color={color} extraStyles={SelectIconExtraStyles} />
      ) : (
        <Icon name="chevron-right" rotation={90} color={color} extraStyles={SelectIconExtraStyles} />
      )}
    </IconWrapper>
  )

  let style = SelectStyle

  style['valueContainer'] = provided => ({
    ...provided,
    padding: inlineComponent && 0
  })

  style['singleValue'] = (provided, state) => ({
    ...provided,
    color: state.isDisabled ? colors.primaryGrey : colors.text
  })

  style['clearIndicator'] = provided => ({
    ...provided,
    padding: px2rem(4),
    svg: {
      stroke: 'white',
      strokeWidth: 1
    }
  })

  const menuContainer = inModal ? document.getElementById(SELECT_MENU_PORTAL) : null

  return (
    <Wrapper
      color={color}
      borderColor={borderColor}
      inline={inlineComponent}
      customSize={customSize}
      style={extraStyles}
    >
      <Label color={color} htmlFor={id} isShrunk={isShrunk} hasPrefix={hasPrefix}>
        {labelRender}
      </Label>
      <InputWrapper>
        {iconPrefix && (
          <IconWrapper>
            <Icon name={iconPrefix} color={color} />
          </IconWrapper>
        )}
        {SelectComponent({
          menuPortalTarget: menuContainer,
          components: { DropdownIndicator },
          options,
          isDisabled: disabled,
          noOptionsMessage: () =>
            loading
              ? language.get('components:molecules:select:loading')
              : language.get('components:molecules:select:noResults'),
          loadOptions: handleAsyncOptionsRequest,
          placeholder: isFocused && placeholder,
          onChange: changeHandler,
          isClearable: notClearable ? false : !required,
          onBlur: blurHandler,
          onFocus: focusHandler,
          value: options.find(option => option.value === value) || null,
          styles: style,
          autoFocus: autoFocusOnMount,
          maxMenuHeight: maxMenuHeight ? maxMenuHeight : null
        })}
        {helpTextRender}
      </InputWrapper>
    </Wrapper>
  )
})
