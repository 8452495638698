import { isPlainObject } from 'lodash'

import { DataSourceValue } from '../../queries/types'
import { DataSourceValueFilterContext } from './filter-context'
import { createDataSourceValueFilterFunction, DataSourceValueFilterType } from './filters'

export const custom = createDataSourceValueFilterFunction(
  'dsv',
  (
    dataSourceValue: DataSourceValue,
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext
  ): boolean => {
    if (filters.dsv === undefined) return false
    if (!context.dataSourceValues) return false

    const values = dataSourceValue.values

    const valueBlank = (value: string) => {
      return value === null || value === undefined || value === ''
    }

    for (const [key, filterValue] of Object.entries(filters.dsv)) {
      const currentValue = values[key]

      if (isPlainObject(currentValue) && filterValue[0] !== '*') return false

      const currentValueArray = Array.isArray(currentValue) ? currentValue : [currentValue]
      if ((currentValueArray.some(v => filterValue.includes(v)) || filterValue[0] === '*') && !valueBlank(currentValue))
        return true

      if (filterValue.includes(0) && valueBlank(currentValue)) return true
    }

    return false
  }
)
