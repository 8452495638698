import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { AiSuggestionsWebsocketResponseType } from 'main/services/queries/use-runbook-ai-suggestions'
import {
  aiSuggestions_INTERNAL,
  aiSuggestionsLoading_INTERNAL
} from 'main/recoil/runbook/models/ai-suggestions/ai-suggestions'

export const useOnActionAiSuggestions = () => {
  const processAiSuggestions = useProcessAiSuggestions()
  return useCallback(
    (response: AiSuggestionsWebsocketResponseType) => {
      processAiSuggestions(response)
    },
    [processAiSuggestions]
  )
}

export type UseGetAiSuggestionsType = {
  sessionId: string
  runbookId: number
  promptItemKey: string
}

export const useGetAiSuggestions = ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType) => {
  return useRecoilValue(aiSuggestions_INTERNAL({ sessionId, runbookId, promptItemKey }))
}

export const useProcessAiSuggestions = () => {
  return useRecoilCallback(({ set }) => async (response: AiSuggestionsWebsocketResponseType) => {
    set(
      aiSuggestions_INTERNAL({
        sessionId: response.session_id,
        runbookId: response?.runbook?.id ?? 0,
        promptItemKey: response.prompt_key
      }),
      response
    )
  })
}

export const useResetAiSuggestions = () => {
  return useRecoilCallback(({ reset }) => async ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType) => {
    reset(
      aiSuggestions_INTERNAL({
        sessionId,
        runbookId,
        promptItemKey
      })
    )
  })
}

export const useSetAiSuggestionsLoading = () => {
  return useRecoilCallback(
    ({ set }) =>
      async ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType, loading: boolean) => {
        set(aiSuggestionsLoading_INTERNAL({ sessionId, runbookId, promptItemKey }), loading)
      }
  )
}

export const useGetAiSuggestionsLoading = ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType) => {
  return useRecoilValue(aiSuggestionsLoading_INTERNAL({ sessionId, runbookId, promptItemKey }))
}
