import { useCallback, useMemo } from 'react'

import { ActiveRunbookModel, ConfigModel } from 'main/data-access'
import { ChangeRequest, ChangeRequestState, ChangeTask } from 'main/services/queries/types'

export const useChangeRequestPermissions = (changeRequest: ChangeRequest) => {
  const useCanSelectChangeRequest = useCanSelectChangeRequestCallback()
  const { changeRequestInteractive } = ConfigModel.useGet()
  const changeGovernanceIntegrationEnabled = ConfigModel.useIsFeatureEnabled('change_governance_integration')
  const runbook = ActiveRunbookModel.useGet()
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')

  const isLiveRun = runbook.current_version.run?.run_type === 'live'

  const isRunbookEditable =
    runbook.current_version.run === null ||
    (!!runbook.current_version.run && runbook.current_version.stage === 'paused')

  const canLinkChangeRequest =
    changeRequestInteractive &&
    canUpdateRunbook &&
    isRunbookEditable &&
    // This was ported from angular and is tied to client specific states when changeRequestInteractive is true
    ['scheduled', 'approval', 'planning', 'draft'].includes(changeRequest.current_state)

  const canStartChangeRequest =
    changeRequestInteractive && canUpdateRunbook && isLiveRun && changeRequest.current_state === 'scheduled'

  const canFinishChangeRequest =
    changeRequestInteractive && canUpdateRunbook && isLiveRun && changeRequest.current_state === 'implementing'

  const canLinkChangeTask =
    !['implementing', 'completed'].includes(changeRequest.current_state) && canUpdateRunbook && isRunbookEditable

  const canShowNewTaskButton =
    changeGovernanceIntegrationEnabled &&
    canUpdateRunbook &&
    isRunbookEditable &&
    changeRequest.interactivity_control_store?.ics_show_new_change_task_button

  const canFinishChangeTaskCallback = (changeTask: ChangeTask) =>
    changeRequest.current_state === 'implementing' &&
    changeTask.status_show_finish_button &&
    canUpdateRunbook &&
    isLiveRun

  return {
    canSelectChangeRequest: useCanSelectChangeRequest(changeRequest),
    canLinkChangeRequest,
    canStartChangeRequest,
    canFinishChangeRequest,
    canLinkChangeTask,
    canShowNewTaskButton,
    canFinishChangeTaskCallback
  }
}

export const useCanSelectAllChangeRequests = (changeRequests: ChangeRequest[] = []) => {
  const canSelectChangeRequest = useCanSelectChangeRequestCallback()

  return useMemo(() => changeRequests.some(cr => canSelectChangeRequest(cr)), [changeRequests, canSelectChangeRequest])
}

export const useCanCreateOrLinkChangeRequest = () => {
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const runbook = ActiveRunbookModel.useGet()

  const isLiveRun = runbook.current_version.run?.run_type === 'live'
  const runComplete = runbook.stage === 'complete' || (runbook.stage === 'cancelled' && isLiveRun)

  return canUpdateRunbook && !isLiveRun && !runComplete
}

const useCanSelectChangeRequestCallback = () => {
  const runbook = ActiveRunbookModel.useGet()
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const canDestroyRunbook = ActiveRunbookModel.useCan('destroy')
  const runbookType = ActiveRunbookModel.useRunbookType()
  const { states, transitions } = runbookType.change_request_types[0]

  const isLiveRun = runbook.current_version.run?.run_type === 'live'
  const runComplete = runbook.stage === 'complete' || (runbook.stage === 'cancelled' && isLiveRun)

  return useCallback(
    (changeRequest: ChangeRequest) => {
      const transitionsFromState = (state: ChangeRequestState) =>
        transitions.some(t => t.from_state === state.identifier && t.properties.interactive)

      const currentState = states.find(s => s.identifier === changeRequest.current_state)

      return (
        (canUpdateRunbook || canDestroyRunbook) &&
        !isLiveRun &&
        (!currentState
          ? true
          : !runComplete || (runComplete && !currentState.properties.final_state && transitionsFromState(currentState)))
      )
    },
    [canUpdateRunbook, canDestroyRunbook, isLiveRun, runComplete, states, transitions]
  )
}
