import { useCallback } from 'react'
import {
  TransactionInterface_UNSTABLE,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import {
  runbookIdState,
  runbookPermission,
  runbookResponseState_INTERNAL,
  runbookRunbookTypeState,
  runbookState,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { updateAllChangedTasks } from 'main/recoil/data-access'
import { RunbookShowRunbook } from 'main/services/queries/types'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { getRunbookVersion, GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import {
  RunbookMergeResponse,
  RunbookRefreshCustomFieldResponse,
  RunbookRestoreResponse,
  RunbookUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModelType } from 'main/data-access/models'
import { updateCurrentVersion, updateVersionData } from 'main/recoil/data-access/updaters__TEMPORARY/shared-updates'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { taskListResponseDataHelpers } from './task-list-response-data-helpers'
import { runbookEditUpdatedRunbook } from 'main/recoil/runbook/models/runbook/runbook-edit'
import { sharedViewState_INTERNAL } from 'main/recoil/shared/models/view/view'
import { addRunbookActionHandlerBreadcrumb } from 'main/components/support-and-analytics/sentry'
import { MarkedIncidentSensitiveResponse } from 'main/services/api/data-providers/user/user-channel-response-types'

/* ----------------------------------- Get ---------------------------------- */

export const useGetActiveRunbook: ActiveRunbookModelType['useGet'] = () => useRecoilValue(runbookState)

export const useGetActiveRunbookCallback: ActiveRunbookModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookState),
    []
  )

/* ------------------------------ Get Loadable ------------------------------ */

export const useActiveRunbookLoadableValue: ActiveRunbookModelType['useGetLoadable'] = () =>
  useRecoilValueLoadable(runbookState)

export const useActiveRunbookLoadableCallback: ActiveRunbookModelType['useGetLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookState),
    []
  )

/* ----------------------------------- Can ---------------------------------- */

export const useActiveRunbookCan: ActiveRunbookModelType['useCan'] = permission => {
  return useRecoilValue(runbookPermission({ attribute: permission }))
}

/* ----------------------------------- Id ----------------------------------- */

export const useActiveRunbookId: ActiveRunbookModelType['useId'] = () => {
  return useRecoilValue(runbookIdState)
}

export const useActiveRunbookIdCallback: ActiveRunbookModelType['useIdCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookIdState),
    []
  )

/* --------------------------------- Action --------------------------------- */

// @ts-ignore
export const useActiveRunbookActionHandler: ActiveRunbookModelType['useOnAction'] = action => {
  useEnsureStableArgs(action)

  const processMergeResponse = useProcessRunbookMergeResponse()
  const processUpdateResponse = useProcessRunbookUpdateResponse()
  const processRestoreResponse = useProcessRestoreResponse()
  const processRefreshCustomFieldResponse = useProcessRunbookRefreshCustomFieldResponse()
  const processSensitiveResponse = useProcessSensitiveResponse()

  switch (action) {
    case 'merge':
      return processMergeResponse
    case 'update':
      return processUpdateResponse
    case 'restore':
      return processRestoreResponse
    case 'refresh_custom_field':
      return processRefreshCustomFieldResponse
    case 'runbook_marked_restricted':
      return processSensitiveResponse
    default:
      throw new Error(`Unknown action: ${action}`)
  }
}

/* ---------------------------- Get Runbook Type ---------------------------- */

export const useActiveRunbookRunbookType: ActiveRunbookModelType['useRunbookType'] = () =>
  useRecoilValue(runbookRunbookTypeState)

export const useActiveRunbookRunbookTypeCallback: ActiveRunbookModelType['useRunbookTypeCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookRunbookTypeState),
    []
  )

/* -------------------------------- Internal -------------------------------- */

const useProcessRunbookUpdateResponse = () =>
  useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookUpdateResponse) => {
      addRunbookActionHandlerBreadcrumb('active-runbook', response)
      updateRunbookData(transactionInterface)(response.runbook)
      updateVersionData(transactionInterface)(response.runbook.current_version)
      updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)
    },
    []
  )

const useProcessRunbookRefreshCustomFieldResponse = () =>
  useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookRefreshCustomFieldResponse) => {
      addRunbookActionHandlerBreadcrumb('active-runbook', response)
      const { set } = transactionInterface

      updateRunbookData(transactionInterface)(response.runbook)
      updateVersionData(transactionInterface)(response.runbook.current_version)
      updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

      set(runbookEditUpdatedRunbook, prev => {
        return produce(prev, draft => {
          extend(draft, response.runbook)
        })
      })

      const fieldValue = response.runbook.field_values.find(fv => fv.id === response.meta.headers.searchable_field_id)
      const fieldValueId = fieldValue?.id
      const customFieldId = fieldValue?.custom_field_id
      set(sharedViewState_INTERNAL, prev => {
        return produce(prev, draft => {
          if (fieldValueId && customFieldId) {
            const refrehState = {
              refresh: false,
              errors: { ...prev.customFieldRefresh[customFieldId].errors, [fieldValueId]: response.meta.headers.errors }
            }
            draft.customFieldRefresh[customFieldId] = refrehState
          }
        })
      })
    },
    []
  )

const updateRunbookData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (responseRunbook: Partial<RunbookShowRunbook>) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, responseRunbook)
      })
    )
  }

const useProcessRestoreResponse = () => {
  const process = useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookRestoreResponse) => {
      updateRunbookData(transactionInterface)(response.runbook)
    },
    []
  )
  return useCallback(
    (response: RunbookRestoreResponse) => {
      process(response)
    },
    [process]
  )
}

const useProcessSensitiveResponse = () => {
  const process = useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: MarkedIncidentSensitiveResponse) => {
      const { set } = transactionInterface

      set(runbookResponseState_INTERNAL, prev =>
        produce(prev, draft => {
          if (draft.runbook.linked_runbook_details.linked_resource?.id === response.runbook_id) {
            extend(draft.runbook.linked_runbook_details, {
              ...draft.runbook.linked_runbook_details,
              linked_resource: {
                ...draft.runbook.linked_runbook_details.linked_resource,
                id: null,
                name: response.redacted_parent_name
              }
            })
          }
        })
      )
    },
    []
  )
  return useCallback(
    (response: MarkedIncidentSensitiveResponse) => {
      process(response)
    },
    [process]
  )
}

const useProcessRunbookMergeResponse = () => {
  const updatePageData = useRecoilTransaction_UNSTABLE(
    transactionInterface =>
      ({
        taskData,
        runbookVersionData,
        runbookData
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        runbookData: RunbookShowRunbook
      }) => {
        const { set } = transactionInterface

        set(
          taskListResponseState_INTERNAL,
          taskListResponseDataHelpers.extend(taskData, {
            runbookComponents: runbookVersionData.meta.runbook_components
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            extend(draft.runbook, runbookData)
          })
        )

        updateCurrentVersion(transactionInterface)(runbookVersionData.runbook_version)
      },
    []
  )

  return useRecoilCallback(
    () => async (response: RunbookMergeResponse) => {
      addRunbookActionHandlerBreadcrumb('active-runbook', response)
      const responseRunbook = response.runbook
      const responseRunbookId = responseRunbook.id
      const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id

      const [taskData, runbookVersionData] = await Promise.all([
        getTasks(responseRunbookId, responseRunbookCurrentVersionId),
        getRunbookVersion(responseRunbookId, responseRunbookCurrentVersionId)
      ])

      updatePageData({
        taskData,
        runbookVersionData,
        runbookData: responseRunbook
      })
    },
    [updatePageData]
  )
}
