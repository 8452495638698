import { format } from 'date-fns'

import { Box, Icon, IconButton, LoadingPanel, Text } from '@cutover/react-ui'
import { CustomPromptType, useRunbookGenerateSuggestions } from 'main/services/queries/use-runbook-ai-suggestions'
import { useLanguage } from 'main/services/hooks'
import { AiSuggestionsModel } from 'main/data-access/models/ai-suggestions-model'
import { CurrentUserModel } from 'main/data-access'

type AiSuggestionSubItemProps = {
  promptItem: CustomPromptType
  runbookId: number
}

export const AiSuggestionItem = ({ promptItem, runbookId }: AiSuggestionSubItemProps) => {
  const currentUserId = CurrentUserModel.useId()?.toString() ?? '0'

  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const setLoading = AiSuggestionsModel.useSetLoading()
  const resetSuggestions = AiSuggestionsModel.useReset()
  const isLoading = AiSuggestionsModel.useGetLoading({
    sessionId: currentUserId,
    runbookId,
    promptItemKey: promptItem.key
  })

  const suggestions = AiSuggestionsModel.useGet({
    runbookId,
    sessionId: currentUserId,
    promptItemKey: promptItem.key
  })

  useRunbookGenerateSuggestions(runbookId, promptItem.key, {
    enabled: !isLoading && !suggestions?.result.length,
    onSuccess: () => {
      setLoading({ sessionId: currentUserId, runbookId, promptItemKey: promptItem.key }, true)
    },
    onError: () => {
      setLoading({ sessionId: currentUserId, runbookId, promptItemKey: promptItem.key }, false)
    }
  })

  const handleClickRefresh = () => {
    resetSuggestions({ sessionId: currentUserId, runbookId, promptItemKey: promptItem.key })
  }

  return (
    <>
      {isLoading || !suggestions.result.length ? (
        <Box>
          <LoadingPanel loadingText={t('loadingText')} />
        </Box>
      ) : (
        <Box flex={false}>
          <Box css="word-wrap: break-word; white-space: normal; padding-bottom: 20px;">
            {`${t('subPanelText')}\n'${promptItem.name}'`}
          </Box>

          <Box direction="row" gap="large" align="center">
            <Text color="text-light">
              {`Generated at: ${format(new Date(suggestions.generated_at), 'd MMM HH:mm')}`}
            </Text>
            <IconButton
              tertiary
              label={t('refresh')}
              icon="refresh"
              size="medium"
              tipPlacement="top"
              onClick={handleClickRefresh}
            />
          </Box>

          {suggestions?.result?.map(item => (
            <Box direction="row" pad="xxsmall" key={`${item.headline}-${item.suggestionDetails}`}>
              <Box flex={false} css="padding-top: 4px;">
                <Icon icon="lightbulb" />
              </Box>

              <Box direction="column" pad="xxsmall">
                <Text weight="bold" css="white-space: normal; overflow-wrap: break-word;">
                  {item.headline}
                </Text>
                <Text color="text-light" css="white-space: normal; overflow-wrap: break-word;">
                  {item.suggestionDetails}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
