import { DataSourceValue } from '../../queries/types'
import { DataSourceValueFilterContext } from './filter-context'
import { createDataSourceValueFilterFunction, DataSourceValueFilterType } from './filters'

export const query = createDataSourceValueFilterFunction(
  'q',
  (
    dataSourceValue: DataSourceValue,
    filters: DataSourceValueFilterType,
    context: DataSourceValueFilterContext
  ): boolean => {
    if (filters.q?.trim().length === 0) return true
    if (filters.q === undefined) return false
    const lowerSearch = String(filters.q).toLowerCase()

    const matchValues = context.lowerCaseValues?.[dataSourceValue.id]
    if (!matchValues) return false

    return Object.values(matchValues).some(value =>
      Array.isArray(value) ? value.some(v => v.includes(lowerSearch)) : (value as string).includes(lowerSearch)
    )
  }
)
