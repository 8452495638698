import { DuplicateRunbooksModal as DuplicateRunbooksModalTwoSteps } from 'main/components/shared/runbook-duplicate/two-step-modal/duplicate-runbooks-modal'
import { DuplicateRunbooksModal as DuplicateRunbooksModalOneStep } from 'main/components/shared/runbook-duplicate/one-step-modal/duplicate-runbooks-modal'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers,
  useAccountRunbookTypes
} from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useWorkspacePermittedProjects } from 'main/services/hooks'
import { useDuplicateRunbooksValidation } from './use-duplicate-runbooks-validation'
import { ConfigModel } from 'main/data-access'

type DuplicateRunbooksModalWorkspaceWrapperProps = {
  runbook?: { id: number }
  runbookIds?: number[]
  open: boolean
  closeModal: () => void
  templateType?: string
  reload: () => void
}

export const DuplicateRunbooksModalWorkspaceWrapper = ({
  runbook,
  runbookIds,
  open,
  closeModal,
  templateType,
  reload
}: DuplicateRunbooksModalWorkspaceWrapperProps) => {
  const isReactRunbookAndTemplateCreate = ConfigModel.useIsFeatureEnabled('react_runbook_and_template_create')

  const { account } = useAccount()
  const { customFieldsLookup } = useAccountCustomFields()
  const { customFieldUsers } = useAccountCustomFieldUsers()
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const { runbookLookup } = useWorkspaceData()
  const { runbookTypeLookup } = useAccountRunbookTypes()
  const isSnippet = templateType === 'snippet'
  const singleRunbookFromBulk = runbookIds?.length === 1 ? runbookLookup?.[runbookIds[0]] : undefined
  const singleRunbook = runbook?.id ? runbookLookup?.[runbook.id] : undefined
  const context = singleRunbookFromBulk ? 'bulk-single' : runbook?.id ? 'single' : 'bulk'

  const runbookTypeId = singleRunbook?.runbook_type_id ?? singleRunbookFromBulk?.runbook_type_id
  const runbookType = !!runbookTypeId ? runbookTypeLookup?.[runbookTypeId] : undefined

  const { errorMessage, warningMessage } = useDuplicateRunbooksValidation({
    context,
    singleRunbook,
    runbookIds,
    runbookType,
    templateType
  })

  const { projects, isAllPermitted, isLoading } = useWorkspacePermittedProjects({
    accountId: account?.id,
    enabled: !isSnippet
  })
  const hasPermissionOnExistingProject = isAllPermitted(runbook ? [runbook.id] : runbookIds ?? [])

  const customFieldProps = {
    customFieldsLookup: customFieldsLookup,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  // Use new one-step modal for creating runbook from template
  return isReactRunbookAndTemplateCreate && templateType === 'default' ? (
    <DuplicateRunbooksModalOneStep
      runbook={singleRunbook ?? singleRunbookFromBulk}
      runbookType={runbookType}
      runbookIds={runbookIds}
      open={open}
      closeModal={closeModal}
      templateType={templateType as 'default' | 'snippet' | undefined}
      reloadRunbookList={reload}
      customFieldProps={customFieldProps}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
      context={context}
      hasPermissionOnExistingProject={hasPermissionOnExistingProject}
      projects={projects}
      loading={isLoading}
    />
  ) : (
    <DuplicateRunbooksModalTwoSteps
      runbook={singleRunbook ?? singleRunbookFromBulk}
      runbookType={runbookType}
      runbookIds={runbookIds}
      open={open}
      closeModal={closeModal}
      templateType={templateType as 'default' | 'snippet' | undefined}
      reloadRunbookList={reload}
      customFieldProps={customFieldProps}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
      context={context}
      hasPermissionOnExistingProject={hasPermissionOnExistingProject}
      projects={projects}
      loading={isLoading}
    />
  )
}
