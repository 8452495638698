import { useEffect, useState } from 'react'
import { Heading } from 'grommet'

import { Accordion, Icon, ListItem, Pill, RightPanel, Text } from '@cutover/react-ui'
import { useLanguage, useWebsockets } from 'main/services/hooks'
import { AiSuggestionItem } from './ai-suggestion-item'
import {
  AiSuggestionsWebsocketResponseType,
  CustomPromptType,
  useRunbookImprovementPrompts
} from 'main/services/queries/use-runbook-ai-suggestions'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { AiSuggestionsModel } from 'main/data-access/models/ai-suggestions-model'
import { WebsocketKeys } from 'main/services/api/websocket-providers'
import { ActiveRunbookModel, CurrentUserModel } from 'main/data-access'

const AI_SUGGESTIONS_CHANNEL_NAME = 'AiSuggestionsChannel'

export const AiSuggestionsPanel = () => {
  const [{ runbookId }, { closeRightPanel }] = useRightPanelTypeState('ai-suggestions-list')

  return <>{runbookId && <AiSuggestionsPanelContent runbookId={runbookId} onClose={closeRightPanel} />}</>
}

type AiSuggestionsPanelContentProps = {
  runbookId: number
  onClose?: () => void
}

type AiSuggestionPanelMode = 'prompts' | 'suggestion-item'

export const AiSuggestionsPanelContent = ({ runbookId, onClose }: AiSuggestionsPanelContentProps) => {
  const currentUserId = CurrentUserModel.useId()
  const aiSuggestionsIdentifier = WebsocketKeys.aiSuggestionsSubscriber(currentUserId)
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const [panelMode, setPanelMode] = useState<AiSuggestionPanelMode>('prompts')
  const [prompt, setPrompt] = useState<CustomPromptType>({ key: '', name: '', description: '' })
  const getRunbookId = ActiveRunbookModel.useIdCallback()

  const handleClose = () => {
    onClose?.()
  }

  const handleClickRowItem = (item: CustomPromptType) => {
    setPrompt(item)
    setPanelMode('suggestion-item')
  }

  const websockets = useWebsockets()
  const updateSuggestions = AiSuggestionsModel.useOnAction()
  const setLoading = AiSuggestionsModel.useSetLoading()

  useEffect(() => {
    const subscription = websockets.findExistingSubscription(
      AI_SUGGESTIONS_CHANNEL_NAME,
      currentUserId,
      aiSuggestionsIdentifier
    )

    if (subscription) {
      return
    }

    if (!currentUserId) {
      return
    }

    websockets.subscribe(AI_SUGGESTIONS_CHANNEL_NAME, currentUserId, aiSuggestionsIdentifier, {
      received: async (response: AiSuggestionsWebsocketResponseType) => {
        const activeRunbookId = await getRunbookId()
        setLoading(
          { sessionId: response.session_id, runbookId: activeRunbookId, promptItemKey: response.prompt_key },
          false
        )
        updateSuggestions(response)
      }
    })
  }, [currentUserId])

  return (
    <RightPanel
      onBack={
        panelMode === 'suggestion-item'
          ? () => {
              setPanelMode('prompts')
            }
          : undefined
      }
      title={
        <>
          <Heading truncate css="line-height: normal; font-size: 18px;" level={3} margin="none">
            {panelMode === 'prompts' ? t('title') : t('subItemTitle')}
          </Heading>
          <Pill label="New" size="medium" color="feature-pink" />
        </>
      }
      onClose={handleClose}
    >
      {panelMode === 'prompts' ? (
        <AiPromptList onClickRowItem={handleClickRowItem} />
      ) : (
        <AiSuggestionItem promptItem={prompt} runbookId={runbookId} />
      )}
    </RightPanel>
  )
}

type AiSuggestionsProps = {
  onClickRowItem: (promptItem: CustomPromptType) => void
}

const AiPromptList = ({ onClickRowItem }: AiSuggestionsProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const { data } = useRunbookImprovementPrompts()

  return (
    <>
      {data && Object.keys(data).length > 0 ? (
        <Accordion>
          {data.map(item => (
            <ListItem
              size="large"
              startComponent={<Icon icon="rocket" />}
              endComponents={[<Icon icon="chevron-right" />]}
              title={item.name}
              subTitle={item.description as string}
              key={item.key}
              onClick={() => {
                onClickRowItem(item)
              }}
            />
          ))}
        </Accordion>
      ) : (
        <Text css="white-space: normal; overflowrap: break-word;" color="text-light">
          {t('noPromptsFound')}
        </Text>
      )}
    </>
  )
}
