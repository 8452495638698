import { useEffect, useRef, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'

import { Box, Checkbox, IconButton, Message, RightPanel } from '@cutover/react-ui'
import { CloseRightPanelAction, useRightPanelTypeState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel, ConfigModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { CreateOrLinkChangeRequestModal } from '../../pages/task-list-ng-connector/change-requests/create-or-link-change-request-modal'
import { getChangeRequests, refreshChangeRequests } from 'main/services/queries/use-change-requests'
import { ChangeRequestModel } from 'main/data-access/models/change-request-model'
import { ChangeRequestSections } from './change-request-sections'
import { ChangeRequestsDeleteModal } from '../../modals/delete-change-requests/delete-change-requests-modal'
import { useCanCreateOrLinkChangeRequest, useCanSelectAllChangeRequests } from './use-change-request-permissions'

export const ChangeRequestsPanel = () => {
  const [{ runbookId }, { closeRightPanel }] = useRightPanelTypeState('runbook-change-requests')
  const [isLoading, setIsLoading] = useState(true)
  const loadChangeRequests = ChangeRequestModel.useAction('load')

  useEffect(() => {
    const initializeChangeRequests = async () => {
      const changeRequests = await getChangeRequests(String(runbookId))
      loadChangeRequests(changeRequests)
      setIsLoading(false)
    }

    initializeChangeRequests()
  }, [runbookId])

  return <ChangeRequestsPanelInner isLoading={isLoading} onClose={closeRightPanel} />
}

type ChangeRequestsPanelInnerProps = {
  isLoading: boolean
  onClose?: CloseRightPanelAction
}

export const ChangeRequestsPanelInner = ({ onClose, isLoading = true }: ChangeRequestsPanelInnerProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel' })
  const runbook = ActiveRunbookModel.useGet()
  const changeRequests = ChangeRequestModel.useGetAll() ?? []
  const { changeRequestRefreshInterval } = ConfigModel.useGet()
  const canSelectAllChangeRequests = useCanSelectAllChangeRequests(changeRequests)
  const canCreateOrLinkChangeRequest = useCanCreateOrLinkChangeRequest()

  const [isCreateOrLinkCRQModalOpen, setCreateOrLinkCRQModalOpen] = useState(false)
  const [selectedCRQs, setSelectedCRQs] = useState<number[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [refreshTime, setRefreshTime] = useState<number | null>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const [lastChecked, setLastChecked] = useState<Date | null>(null)

  useEffect(() => {
    if (changeRequests.length === 0) return

    const mostRecent = changeRequests.reduce((latest, item) =>
      new Date(item.last_checked_at) > new Date(latest.last_checked_at) ? item : latest
    )
    setLastChecked(new Date(mostRecent.last_checked_at) ?? null)
  }, [changeRequests])

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const handleLinkCRQ = () => {
    setCreateOrLinkCRQModalOpen(true)
  }

  const handleCloseModal = () => {
    setCreateOrLinkCRQModalOpen(false)
  }

  const toggleChangeRequest = (id: number) => {
    setSelectedCRQs(prev => (prev.includes(id) ? prev.filter(crqId => crqId !== id) : [...prev, id]))
  }

  const handleRefresh = () => {
    if (!refreshTime) refreshChangeRequests(runbook.id)
    setRefreshTime(changeRequestRefreshInterval)

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      setRefreshTime(prev => {
        if (prev === null || prev <= 1) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
          }
          return null
        }
        return prev - 1
      })
    }, 1000)
  }

  return (
    <>
      <RightPanel
        title={t('title')}
        onClose={onClose}
        loading={isLoading}
        headerItems={[
          changeRequests.length > 0 && (
            <IconButton
              key="refresh-button"
              tertiary
              label={
                refreshTime
                  ? t('refreshTime', { count: refreshTime })
                  : t('refreshAll', { distance: formatDistanceToNow(lastChecked ?? new Date()) })
              }
              icon="refresh"
              disabled={intervalRef.current !== null}
              onClick={handleRefresh}
              tipPlacement="top"
            />
          ),
          canCreateOrLinkChangeRequest &&
            (selectedCRQs.length > 0 ? (
              <IconButton
                key="delete-button"
                tertiary
                label={t('deleteButton')}
                icon="delete"
                onClick={() => setDeleteModalOpen(true)}
                tipPlacement="top"
              />
            ) : (
              <IconButton
                key="link-button"
                primary
                label={t('linkButton')}
                icon="add"
                onClick={handleLinkCRQ}
                tipPlacement="top"
              />
            ))
        ]}
      >
        {runbook.change_requests_count === 0 ? (
          <Message type="error" icon="alert" message={t('linkErrorMessage')} />
        ) : (
          <Box flex={false}>
            {canSelectAllChangeRequests && (
              <Box pad="xxsmall">
                <Checkbox
                  label={selectedCRQs.length !== changeRequests.length ? t('selectAll') : t('deselectAll')}
                  checked={selectedCRQs.length === changeRequests.length}
                  onClick={() => {
                    if (selectedCRQs.length === changeRequests.length) {
                      setSelectedCRQs([])
                    } else {
                      setSelectedCRQs(changeRequests.map(crq => crq.id))
                    }
                  }}
                />
              </Box>
            )}
            <ChangeRequestSections
              changeRequests={changeRequests}
              selectedChangeRequests={selectedCRQs}
              toggleChangeRequest={toggleChangeRequest}
            />
          </Box>
        )}
      </RightPanel>
      <CreateOrLinkChangeRequestModal
        open={isCreateOrLinkCRQModalOpen}
        runbookId={String(runbook.id)}
        changeRequestType="change_request"
        handleCloseModal={handleCloseModal}
      />
      <ChangeRequestsDeleteModal
        open={deleteModalOpen}
        runbookId={runbook.id}
        changeRequestIds={selectedCRQs}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => setSelectedCRQs([])}
      />
    </>
  )
}
