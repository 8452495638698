import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { ChangeRequestModelType } from 'main/data-access/models/change-request-model'
import {
  changeRequestListResponseState_INTERNAL,
  changeRequestsLookup,
  changeRequestsState,
  changeRequestState
} from 'main/recoil/runbook/models/change-requests/change-requests'
import { ChangeRequest } from 'main/services/queries/types'
import { runbookResponseState_INTERNAL } from 'main/recoil/runbook'
import { RunbookChangeRequestsResponse } from 'main/services/api/data-providers/runbook-types'

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */

export const useHandleChangeRequestAction = () => {}

export type ChangeRequestActionType = {
  load: ReturnType<typeof useLoadChangeRequests>
  create: ReturnType<typeof useCreateOrLinkChangeRequests>
  delete: ReturnType<typeof useDeleteChangeRequests>
  update: ReturnType<typeof useUpdateChangeRequest>
}

/* eslint-disable react-hooks/rules-of-hooks */
// @ts-ignore
export const useChangeRequestAction: ChangeRequestModelType['useAction'] = action => {
  switch (action) {
    case 'load':
      return useLoadChangeRequests()
    case 'create':
      return useCreateOrLinkChangeRequests()
    case 'delete':
      return useDeleteChangeRequests()
    case 'update':
      return useUpdateChangeRequest()
    default:
      throw new Error(`${action} is not yet implemented in useAction`)
  }
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useOnActionChangeRequest: ChangeRequestModelType['useOnAction'] = () => {
  const processChangeRequestUpdateResponse = useProcessChangeRequestUpdateResponse()

  return useCallback(
    (response: RunbookChangeRequestsResponse) => {
      switch (response.meta?.headers.request_method) {
        case 'update':
          return processChangeRequestUpdateResponse(response as RunbookChangeRequestsResponse)
        default:
          return
      }
    },
    [processChangeRequestUpdateResponse]
  )
}

const useProcessChangeRequestUpdateResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookChangeRequestsResponse) => {
        const changeRequests = response.change_requests
        const updateMap = new Map(changeRequests.map(cr => [cr.id, cr]))

        set(changeRequestListResponseState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.change_requests = draft.change_requests.map(cr => updateMap.get(cr.id) ?? cr)
          })
        )
      },
    []
  )

const useLoadChangeRequests = () => {
  return useRecoilCallback(
    ({ set }) =>
      (changeRequests: ChangeRequest[]) => {
        set(changeRequestListResponseState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.change_requests = changeRequests
          })
        )
      },
    []
  )
}

const useCreateOrLinkChangeRequests = () => {
  return useRecoilCallback(
    ({ set }) =>
      (changeRequests: ChangeRequest[]) => {
        set(changeRequestListResponseState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.change_requests = [...previousState.change_requests, ...changeRequests]
          })
        )

        // The change request API call does not return an updated runbook state,
        // so increment the count manually here to update the change request sidebar and error message if any.
        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            draft.runbook.change_requests_count = prev.runbook.change_requests_count + changeRequests.length
          })
        )
      },
    []
  )
}

const useDeleteChangeRequests = () => {
  return useRecoilCallback(({ set }) => (changeRequests: ChangeRequest[]) => {
    set(changeRequestListResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        const deleteIds = new Set(changeRequests.map(cr => cr.id))
        draft.change_requests = draft.change_requests.filter(cr => !deleteIds.has(cr.id))
      })
    )

    // The change request API call does not return an updated runbook state,
    // so decrement the count manually here to update the change request sidebar and error message if any.
    set(runbookResponseState_INTERNAL, prev =>
      produce(prev, draft => {
        draft.runbook.change_requests_count = prev.runbook.change_requests_count - changeRequests.length
      })
    )
  })
}

const useUpdateChangeRequest = () => {
  return useRecoilCallback(({ set }) => (changeRequest: ChangeRequest) => {
    set(changeRequestListResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        const index = draft.change_requests.findIndex(request => request.id === changeRequest.id)

        if (index !== -1) {
          draft.change_requests[index] = changeRequest
        }
      })
    )
  })
}

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useChangeRequest: ChangeRequestModelType['useGet'] = identifier =>
  useRecoilValue(changeRequestState(identifier))

export const useChangeRequestCallback: ChangeRequestModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) =>
        await snapshot.getPromise(changeRequestState(identifier)),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useChangeRequests: ChangeRequestModelType['useGetAll'] = () => useRecoilValue(changeRequestsState)

export const useChangeRequestsCallback: ChangeRequestModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(changeRequestsState),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                 Get Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useChangeRequestsLookup: ChangeRequestModelType['useGetLookup'] = () =>
  useRecoilValue(changeRequestsLookup)

export const useChangeRequestsLookupCallback: ChangeRequestModelType['useGetLookupCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(changeRequestsLookup),
    []
  )
}
