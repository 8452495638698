import { FocusEventHandler, memo, ReactNode, useCallback, useId, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { MergeExclusive } from 'type-fest'
import { v4 } from 'uuid'
import { CheckBoxGroupProps, CheckBoxType, CheckBoxGroup as GrommetCheckBoxGroup } from 'grommet'

import { useShowMoreButton } from '../../form/internal/use-show-more'
import { IconName } from '../../icon'
import { ItemCreateInput } from '../../item-create-input'
import { Box } from '../../layout'
import { themeColor } from '../../theme'
import { checkboxFocusCss, CheckboxGroupItem, CheckboxProps } from '../checkbox'
import { FieldBox, FormField } from '../internal/form-field'

type Value = (string | number)[] | null

type UncontrolledOnChangeEvent = {
  value: Value
  option: CheckBoxType & Partial<CheckboxProps> & { value: any }
}

// Note: the `onChange` type is incorrectly defined in grommet, and can only accept
// strings, see https://github.com/grommet/grommet/issues/6224
// A workaround is to typecast this via unknown into the expected type
type ControlledOnChangeEventHandler = CheckBoxGroupProps['onChange']
type UncontrolledOnChangeEventHandler = ((event: UncontrolledOnChangeEvent) => void) | undefined

type ControlledOnlyCheckboxGroupProps = {
  value: (string | number)[] | null
  onChange?: ControlledOnChangeEventHandler
}

type UncontrolledOnlyCheckboxGroupProps = {
  onChange?: UncontrolledOnChangeEventHandler
}

type SharedCheckboxGroupProps = {
  hasError?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  custom?: boolean
  label?: string
  helpText?: string
  icon?: IconName
  inlineError?: string
  name?: string
  valueKey?: string
  labelKey?: string
  direction?: 'row' | 'column'
  fill?: boolean
  defaultValue?: (string | number)[]
  options: string[] | (CheckBoxType & Partial<CheckboxProps>)[]
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
  /** include this to show item create input if field creation is allowed */
  onCreateOption?: (value: string) => void
  a11yTitle?: string
  id?: string
  plain?: boolean
}

export type CheckboxGroupProps = MergeExclusive<ControlledOnlyCheckboxGroupProps, UncontrolledOnlyCheckboxGroupProps> &
  SharedCheckboxGroupProps

export const CheckboxGroup = memo(({ options, plain = false, ...props }: CheckboxGroupProps) => {
  let groupId = useId()
  groupId = props.id ?? groupId

  const opts = useMemo(
    () =>
      options.map((option: any) => {
        const checkboxInputId = v4()
        return typeof option === 'object'
          ? {
              a11yTitle: option[props.labelKey ?? 'label'],
              id: checkboxInputId,
              'aria-labelledby': `${groupId} ${checkboxInputId}`,
              ...option // can override a11yTitle in options
            }
          : option
      }),
    [options, groupId]
  )

  if (isControlled(props)) {
    return <ControlledCheckboxGroup {...props} plain={plain} id={groupId} options={opts} onChange={props.onChange} />
  } else {
    // @ts-ignore
    return <UncontrolledCheckboxGroup {...props} plain={plain} id={groupId} options={opts} onChange={props.onChange} />
  }
})

type UncontrolledCheckboxGroupProps = UncontrolledOnlyCheckboxGroupProps & SharedCheckboxGroupProps

const UncontrolledCheckboxGroup = ({
  onChange,
  options,
  valueKey = 'value',
  ...props
}: UncontrolledCheckboxGroupProps) => {
  const [value, setValue] = useState<Value | null>(props.defaultValue ?? null)

  const opts = useMemo(() => {
    return options.map(opt => {
      return typeof opt === 'object' ? { ...opt, _value: opt[valueKey] } : opt
    }) as typeof options
  }, [options, valueKey])

  const handleChange: ControlledOnChangeEventHandler = useCallback(
    (evt?: any) => {
      const event = {
        ...evt,
        // adds a value prop to the option object so that the uncontrolled change event handler can use it
        option: typeof evt.option === 'object' ? { ...evt.option, value: evt.option._value } : { value: evt.option }
      }

      onChange?.(event)
      setValue(evt.value)
    },
    [onChange]
  )

  return <ControlledCheckboxGroup {...props} value={value} valueKey={valueKey} onChange={handleChange} options={opts} />
}

type ControlledCheckboxGroupProps = ControlledOnlyCheckboxGroupProps & SharedCheckboxGroupProps

const ControlledCheckboxGroup = ({
  label,
  readOnly,
  required,
  hasError,
  disabled,
  inlineError,
  helpText,
  custom,
  icon,
  labelKey = 'label',
  valueKey = 'value',
  options,
  fill,
  onCreateOption,
  plain,
  ...props
}: ControlledCheckboxGroupProps) => {
  const defaultDirection = props.direction
  const direction = useMemo(
    () =>
      options.some(opt => typeof opt === 'object' && opt.helpText)
        ? 'column'
        : custom && defaultDirection !== 'column'
        ? 'row'
        : defaultDirection,
    [options, defaultDirection, custom]
  )
  const initialValue = useMemo(() => props.defaultValue ?? props.value, [props.defaultValue, props.value])
  const [maxNumOptionsToShow, itemShowButton] = useShowMoreButton(options.length)

  const handleReadOnlyChange: CheckBoxGroupProps['onChange'] = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
  }

  const checkboxGroupItems = (
    <GrommetCheckBoxGroup
      {...props}
      a11yTitle={props.a11yTitle ?? label}
      data-testid={label ?? props.name}
      id={props.id}
      options={options}
      width="100%"
      flex={false}
      disabled={disabled}
      gap={direction === 'row' || fill ? '4px' : '0'}
      labelKey={labelKey}
      direction={fill ? 'row' : direction}
      valueKey={valueKey}
      // FIXME: needs investigation -- for some rason plain groups work with the empty array but not ones used in forms
      value={(readOnly ? initialValue ?? undefined : props.value ?? undefined) ?? (plain ? [] : undefined)}
      onChange={readOnly ? handleReadOnlyChange : props.onChange}
      css={`
        flex-wrap: ${direction === 'row' ? 'wrap' : undefined};
        > label {
          flex: ${direction === 'row' && !fill ? 0 : 1};
          min-width: ${fill ? '0' : 'auto'}; // allows flex children to shrink lower than original size
        }
      `}
    >
      {
        // @ts-ignore not sure typing is not working  here due to grommet internals
        function (
          option: string | Record<string, any>,
          { checked, indeterminate }: { checked?: boolean; indeterminate?: boolean }
        ) {
          if (!checked) {
            checked = initialValue?.includes((option as Record<string, any>).value)
          }
          const isDisabled = disabled || (typeof option === 'object' && option.disabled)
          const hasError = typeof option === 'object' && option.hasError
          // @ts-ignore can't resolve type for index
          const index = options.indexOf(option)
          const labelText = typeof option === 'string' ? option : option[labelKey ?? 'label'] ?? ''
          return custom ? (
            // TODO: will need a tooltip here
            <CustomToggle
              className="custom-checkbox-group-toggle-option"
              data-testid={'option-' + labelText}
              active={checked}
              disabled={isDisabled}
              aria-hidden
              hasError={hasError}
            >
              {labelText}
            </CustomToggle>
          ) : (
            <CheckboxGroupItem
              {...(typeof option === 'string' ? { option } : option)}
              hasError={hasError}
              label={labelText}
              checked={checked}
              indeterminate={indeterminate}
              disabled={isDisabled}
              helpText={typeof option === 'object' ? option.helpText : undefined}
              css={`
                display: ${index >= maxNumOptionsToShow && 'none'};
              `}
            />
          )
        }
      }
    </GrommetCheckBoxGroup>
  )

  if (plain) {
    return (
      <CheckboxGroupWrapper plain fill={fill} custom={custom}>
        {checkboxGroupItems}
        {itemShowButton}
        {onCreateOption && <ItemCreateInput onCreateItem={onCreateOption} />}
      </CheckboxGroupWrapper>
    )
  } else {
    return (
      <CheckboxesField
        label={label}
        icon={icon}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        hasError={hasError}
        inlineError={inlineError}
        helpText={helpText}
        custom={custom}
      >
        {checkboxGroupItems}
        {itemShowButton}
        {onCreateOption && <ItemCreateInput onCreateItem={onCreateOption} />}
      </CheckboxesField>
    )
  }
}

type CheckboxesFieldProps = {
  label?: string
  readOnly?: boolean
  required?: boolean
  hasError?: boolean
  disabled?: boolean
  inlineError?: string
  helpText?: string
  direction?: 'row' | 'column'
  fill?: boolean
  icon?: IconName
  children?: ReactNode
  custom?: boolean
  'data-testid'?: string
}

export const CheckboxesField = ({
  label,
  icon,
  disabled,
  readOnly,
  required,
  hasError,
  inlineError,
  helpText,
  fill = false,
  children,
  custom,
  direction,
  'data-testid': dataTestId
}: CheckboxesFieldProps) => {
  // This component is used externally also as a wrapper for individual checkboxes to make them appear like a single group. In order
  // to also support this for a row layout, we can wrap the
  const content =
    direction === 'row' ? (
      <Box
        direction="row"
        gap="xxsmall"
        css={`
          width: 100%;
          > * {
            width: unset;
          }
        `}
      >
        {children}
      </Box>
    ) : (
      children
    )
  return (
    <CheckboxGroupWrapper flex={false} fill={fill} custom={custom} data-testid={dataTestId}>
      <FormField
        label={label}
        startIcon={icon}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        hasError={hasError}
        inlineError={inlineError}
        helpText={helpText}
      >
        {content}
      </FormField>
    </CheckboxGroupWrapper>
  )
}

// TODO: could move this to its own comp
export const CustomToggle = styled(Box)<{ active?: boolean; disabled?: boolean; hasError?: boolean }>`
  border-radius: 12px;
  padding: 0 8px;
  margin: 4px 0;
  font-size: 13px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
  display: block;
  text-align: center;
  color: ${props =>
    props.hasError
      ? themeColor('error')
      : props.active && props.disabled
      ? themeColor('text-disabled')
      : props.active && !props.disabled
      ? themeColor('bg')
      : props.disabled
      ? themeColor('text-disabled')
      : themeColor('text-light')};
  background-color: ${props =>
    props.active && props.disabled
      ? themeColor('bg-4')
      : props.active && !props.disabled
      ? themeColor('primary')
      : themeColor('bg-1')};
  &:hover {
    background-color: ${props =>
      props.active && props.disabled
        ? themeColor('bg-4')
        : props.active && !props.disabled
        ? themeColor('primary-1')
        : props.disabled
        ? themeColor('bg-1')
        : themeColor('bg-2')};
  }
`

export const CheckboxGroupWrapper = styled(Box).attrs({
  flex: false
})<{ custom?: boolean; plain?: boolean }>`
  ${checkboxFocusCss}
  ${FieldBox} {
    flex-direction: column;

    // NOTE: this hides a span with the option label auto-created by grommet even with custom render func
    label > span:last-of-type {
      display: none;
    }

    > div[role='group'] {
      flex: ${props => (props.direction === 'row' && !props.fill ? 0 : 1)};
      min-width: ${props => (props.fill ? '0' : 'auto')}; // allows flex children to shrink lower than original size
    }
  }

  label {
    max-height: ${props => props.plain && '32px'};
    width: ${props => !props.custom && !props.fill && '100%'};

    &[disabled] {
      opacity: 1;
    }

    > div {
      width: 100%;
    }

    // NOTE: this hides a span with the option label auto-created by grommet even with custom render func
    ${props =>
      props.plain &&
      css`
        > span:last-of-type {
          display: none;
        }
      `}
  }
`

function isControlled(props: any): props is ControlledCheckboxGroupProps {
  return props.hasOwnProperty('value')
}
