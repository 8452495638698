import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { Card, CardProps, Grid, RunbookTypeIcon, SpinnerIcon } from '@cutover/react-ui'
import { useLanguage, useWorkspacePermittedProjects } from 'main/services/hooks'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers,
  useAccountRunbookTypes
} from 'main/services/api/data-providers/account/account-data'
import { FormModal } from 'main/components/shared/form'
import { useCustomFieldFormCallback } from 'main/components/shared/custom-field-form'
import { RunbookCreatePayload, useRunbookCreateMutation } from 'main/services/queries/use-runbook'
import { buildCreateRunbookSchema, CreateRunbookFormType } from './create-runbook-schema'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helpers'
import { getClientIANATimezone } from 'main/services/timezone-util'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { FieldValue, RunbookTypeType } from 'main/services/queries/types'
import { CreateRunbookDetails, CustomFieldsFormProps } from './create-runbook-details'
import { FormRenderProps } from 'main/components/shared/form/form'
import { ConfigModel } from 'main/data-access'
import { fetchRunbooks } from 'main/services/queries/use-runbooks'

type CreateRunbookModalProps = {
  open: boolean
  onClickClose: () => void
  isTemplate?: boolean
}

export const CreateRunbooksModal = ({ open, onClickClose, isTemplate = false }: CreateRunbookModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal' })
  const { account } = useAccount()
  const accountId = account?.id
  const timezone = getClientIANATimezone()
  const { runbookTypes } = useAccountRunbookTypes()
  const [isCreateFromTemplate, setIsCreateFromTemplate] = useState(false)
  const [runbookTypeId, setRunbookTypeId] = useState<number | undefined>(undefined)
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const { customFieldsLookup } = useAccountCustomFields()
  const { projects: folders } = useWorkspacePermittedProjects({ accountId })
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const { customFieldUsers } = useAccountCustomFieldUsers()
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const isAngularDeveloperRunbookEnabled = ConfigModel.useIsFeatureEnabled('angular_developer_runbook')

  const createRunbook = useRunbookCreateMutation().mutateAsync
  const navigate = useNavigate()

  const { getFieldValues, fieldValueValidation, buildFieldValuesAttributesRequestData, getCustomFieldData } =
    useCustomFieldFormCallback({
      applyToSlugs: ['runbook_add_edit'],
      customFieldsLookup,
      customFieldGroups,
      excludeTransientFieldValues: true
    })

  const { customFields, groupedCustomFields } = getCustomFieldData(undefined, {
    runbook_type_id: runbookTypeId
  })

  const customFieldProps = {
    customFields,
    customFieldUsers,
    groupedCustomFields,
    customFieldGroupsLookup
  }

  const defaultValues = {
    _step: 1,
    runbook: {
      name: '',
      account_id: accountId,
      runbook_type_id: 1,
      is_template: isTemplate,
      timezone: timezone,
      roles: []
    },
    shift_time: true,
    base_template: null,
    field_values: buildDefaultFieldValues({ customFields, groupedCustomFields, fieldValues: {} })
  } as unknown as CreateRunbookFormType

  const resolveTitle = (formContext: FormRenderProps<CreateRunbookFormType>) => {
    const { _step: currentStep, runbook } = formContext.getValues()
    if (currentStep === 1) {
      return isTemplate ? t('chooseRunbookType.templateHeading') : t('chooseRunbookType.runbookHeading')
    } else {
      const runbookTypeId = runbook?.runbook_type_id
      const runbookTypeName = runbookTypes?.find(type => type.id === runbookTypeId)?.name
      return t('enterDetails.heading', {
        runbookTypeName: isTemplate ? `${runbookTypeName} ${t('enterDetails.template')}` : runbookTypeName
      })
    }
  }

  const dataTransformer = ({
    field_values,
    base_template,
    runbook,
    role_types,
    shift_time,
    ai_config
  }: CreateRunbookFormType) => {
    return {
      base_runbook_id: base_template?.id,
      runbook: {
        ...runbook,
        field_values_attributes: buildFieldValuesAttributesRequestData(field_values, true),
        roles:
          role_types
            ?.map(role =>
              (role.users || []).map(user => ({
                role_type_id: role.id,
                subject_id: user.id,
                subject_type: 'User'
              }))
            )
            .flat() ?? []
      },
      shift_time: shift_time,
      ai_config: ai_config
    }
  }

  const navigateToRunbook = (data: any) => {
    const suffix = isReactRunbookEnabled || !isAngularDeveloperRunbookEnabled ? 'list' : 'angular-list'
    navigate(`/app/${account?.slug}/runbooks/${data?.runbook?.id}/current_version/tasks/${suffix}`)
  }

  return (
    <>
      <FormModal<CreateRunbookFormType, RunbookCreatePayload>
        width={700}
        schema={buildCreateRunbookSchema(fieldValueValidation, isCreateFromTemplate)}
        title={resolveTitle}
        confirmText={
          isTemplate ? t('formSubmission.submitButtonTextTemplate') : t('formSubmission.submitButtonTextRunbook')
        }
        steps={2}
        confirmDisabled={confirmDisabled}
        open={open}
        onClose={onClickClose}
        onSubmit={createRunbook}
        defaultValues={defaultValues}
        successMessage={isTemplate ? t('formSubmission.templateSuccess') : t('formSubmission.runbookSuccess')}
        onSuccess={navigateToRunbook}
        errorMessage={isTemplate ? t('formSubmission.templateError') : t('formSubmission.runbookError')}
        transformer={dataTransformer}
        hideFooter={formContext => {
          return formContext.getValues('_step') === 1
        }}
      >
        <CreateRunbookModalContent
          setConfirmDisabled={setConfirmDisabled}
          runbookTypes={!!runbookTypes ? [...runbookTypes].sort((a, b) => a.id - b.id) : []}
          isTemplate={isTemplate}
          folders={folders}
          customFieldsProps={customFieldProps}
          setIsCreateFromTemplate={setIsCreateFromTemplate}
          runbookTypeId={runbookTypeId}
          setRunbookTypeId={setRunbookTypeId}
          getFieldValues={getFieldValues}
        />
      </FormModal>
    </>
  )
}

type CreateRunbookModalContentProps = {
  customFieldsProps: CustomFieldsFormProps
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
  runbookTypes: RunbookTypeType[] | undefined
  runbookTypeId: number | undefined
  getFieldValues: (fieldValues: FieldValue[]) => Record<number, any>
  setConfirmDisabled: Dispatch<SetStateAction<boolean>>
  setIsCreateFromTemplate: Dispatch<SetStateAction<boolean>>
  setRunbookTypeId: Dispatch<SetStateAction<number | undefined>>
}

const CreateRunbookModalContent = ({
  customFieldsProps,
  folders,
  isTemplate = false,
  runbookTypes,
  runbookTypeId,
  getFieldValues,
  setConfirmDisabled,
  setIsCreateFromTemplate,
  setRunbookTypeId
}: CreateRunbookModalContentProps) => {
  const { getValues, setValue, resetField } = useFormContext<CreateRunbookFormType>()
  const currentStep = getValues('_step')
  const { accountId: accountSlug } = useParams()
  const [templatesExist, setTemplatesExist] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const sanitisedRunbookTypes = runbookTypes
    ?.filter(runbookType => !runbookType.archived)
    .filter(runbookType => !runbookType.disabled)
    .filter(runbookType => runbookType.key !== 'snippet')

  const handleSelectRunbookType = async (option: CardProps) => {
    // if already selected, reset the base template
    if (runbookTypeId) resetField('base_template')
    setValue('runbook.runbook_type_id', option.key as number)
    setIsLoading(true)
    setRunbookTypeId(option.key as number)
    try {
      const resp = await fetchRunbooks({
        limit: 1,
        template_type: 'default',
        type: [option.key],
        fields: ['id', 'name'],
        accountId: accountSlug
      })
      setTemplatesExist(resp.runbooks.length > 0)
    } finally {
      setIsLoading(false)
      setValue('_step', 2)
    }
  }

  const runbookTypeOptions: CardProps[] = (sanitisedRunbookTypes ?? []).map(type => ({
    key: type.id,
    title: type.name,
    subtitle: type.description,
    startComponent: <RunbookTypeIcon icon={type.icon_name} color={type.icon_color} />,
    endComponents: isLoading && type.id === getValues('runbook.runbook_type_id') && <SpinnerIcon color="text-light" />,
    disabled: isLoading
  }))

  switch (currentStep) {
    case 1:
      return (
        <Grid minWidth={300} data-testid="select-runbook-types-grid">
          {runbookTypeOptions
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(option => (
              <Card onClick={() => handleSelectRunbookType(option)} {...option} />
            ))}
        </Grid>
      )
    case 2:
      return (
        <CreateRunbookDetails
          setConfirmDisabled={setConfirmDisabled}
          isTemplate={isTemplate}
          folders={folders}
          setIsCreateFromTemplate={setIsCreateFromTemplate}
          templatesExist={templatesExist}
          getFieldValues={getFieldValues}
          {...customFieldsProps}
        />
      )
    default:
      return null
  }
}
