import { useEffect, useMemo } from 'react'
import * as yup from 'yup'
import { keyBy } from 'lodash'
import { format } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { TextInput, useNotify } from '@cutover/react-ui'
import { FormModal, SelectField, TextInputField } from 'main/components/shared/form'
import { ChangeRequest } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, TaskModel } from 'main/data-access'
import { ChangeRequestEditPayload, useChangeRequestEdit } from 'main/services/queries/use-change-requests'
import { ChangeRequestModel } from 'main/data-access/models/change-request-model'

type ChangeRequestEditModalProps = {
  open: boolean
  changeRequest: ChangeRequest
  onClose?: () => void
}

const validationSchema = yup.object({
  selectedStatus: yup.string().required(),
  reason: yup.string().required(),
  notes: yup.string(),
  commsDone: yup.string().required(),
  summarizedOutcomes: yup.string().required(),
  approvedCIModification: yup.string().required(),
  modifications: yup.string(),
  deployCriteriaMet: yup.string().required(),
  postDeploymentOutcomes: yup.string(),
  stabilityResults: yup.string().required(),
  stability: yup.string()
})

export type ChangeRequestEditFormType = yup.InferType<typeof validationSchema>

export const ChangeRequestEditModal = ({ open, changeRequest, onClose }: ChangeRequestEditModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.editModal' })
  const notify = useNotify()
  const tasks = TaskModel.useGetAll()
  const tasksInternalIdLookup = keyBy(tasks, 'internal_id')
  const updateChangeRequestState = ChangeRequestModel.useAction('update')
  const runbook = ActiveRunbookModel.useGet()
  const editChangeRequest = useChangeRequestEdit(runbook.id)

  /* ------------------------------- Date fields ------------------------------ */

  const startTask = changeRequest.change_start ? tasksInternalIdLookup[changeRequest.change_start] : null
  const startTaskValid =
    startTask && ['in-progress', 'complete'].includes(tasksInternalIdLookup[changeRequest.change_start].stage)
  const startTimeMessage = !startTask
    ? t('startTimeInvalid')
    : startTaskValid
    ? format(new Date(startTask.start_display * 1000), 'do MMM yyyy HH:mm')
    : t('startTaskNotStarted', { taskInternalId: startTask.internal_id })

  const finishTask = changeRequest.change_finish ? tasksInternalIdLookup[changeRequest.change_finish] : null
  const finishTaskValid = finishTask && tasksInternalIdLookup[changeRequest.change_finish].stage === 'complete'
  const finishTimeMessage = !finishTask
    ? t('finishTimeInvalid')
    : finishTaskValid && startTask?.end_display
    ? format(new Date(startTask.end_display * 1000), 'do MMM yyyy HH:mm')
    : t('finishTaskNotComplete', { taskInternalId: finishTask.internal_id })

  const defaultValues = {
    selectedStatus: '',
    reason: '',
    notes: '',
    commsDone: '',
    summarizedOutcomes: '',
    approvedCIModification: '',
    modifications: '',
    deployCriteriaMet: '',
    postDeploymentOutcomes: '',
    stabilityResults: '',
    stability: ''
  }

  const dataTransformer = (data: ChangeRequestEditFormType): ChangeRequestEditPayload => {
    return {
      change_request_ids: [changeRequest.id],
      transition: data.selectedStatus,
      transition_properties: {
        reason: data.reason,
        notes: data.notes,
        closure: {
          summarizedOutcomes: data.summarizedOutcomes,
          approvedCIModification: data.approvedCIModification,
          reviewOfModifications: data.modifications,
          deployCriteriaMet: data.deployCriteriaMet,
          postDeploymentOutcomes: data.postDeploymentOutcomes,
          stabilityResults: data.stabilityResults,
          stability: data.stability,
          communicationDone: data.commsDone
        }
      }
    }
  }

  /**
   * This handler does not use mutateAsync as we close the modal immidiatly after the mutation is called.
   * The success and error notifications are used to show the result to the user.
   */
  const handleSubmit = (data: ChangeRequestEditPayload) => {
    editChangeRequest.mutate(data, {
      onSuccess: changeRequest => {
        updateChangeRequestState(changeRequest)
        notify.success(t('successMessage'), { title: t('successTitle') })
      },
      onError: () => {
        notify.error(t('errorMessage'), { title: t('errorTitle') })
      }
    })
  }

  return (
    <FormModal<ChangeRequestEditFormType, ChangeRequestEditPayload>
      open={open}
      title={t('title', { externalId: changeRequest.external_id })}
      confirmText={t('confirmText')}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmDisabled={!startTaskValid || !finishTaskValid}
      schema={validationSchema}
      defaultValues={defaultValues}
      transformer={dataTransformer}
    >
      <TextInput label={t('startTime')} disabled value={startTimeMessage} />
      <TextInput label={t('finishTime')} disabled value={finishTimeMessage} />
      {startTaskValid && finishTaskValid && <ChangeRequestEditFormFields changeRequest={changeRequest} />}
    </FormModal>
  )
}

const ChangeRequestEditFormFields = ({ changeRequest }: { changeRequest: ChangeRequest }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.editModal.fields' })
  const runbookType = ActiveRunbookModel.useRunbookType()
  const { watch, resetField } = useFormContext()
  const selectedStatus = watch('selectedStatus')

  /* ------------------------------- Transitions ------------------------------ */

  const transitions = runbookType.change_request_types[0].transitions
  const possibleTransitions = transitions.filter(transition => transition.from_state === changeRequest.current_state)
  const transitionOptions = possibleTransitions.map(transition => ({
    label: transition.label,
    value: transition.identifier
  }))

  /* --------------------------- Transition reasons --------------------------- */

  const transitionReasons = useMemo(() => {
    const reasons = possibleTransitions.find(transition => transition.identifier === selectedStatus)?.properties.reasons
    return reasons?.map(reason => ({ label: reason, value: reason })) ?? []
  }, [selectedStatus, possibleTransitions])

  useEffect(() => {
    resetField('reason')
  }, [selectedStatus])

  const yesOrNoOptions = useMemo(
    () => [
      { label: t('yes'), value: 'Yes' },
      { label: t('no'), value: 'No' }
    ],
    []
  )

  return (
    <>
      <SelectField<ChangeRequestEditFormType> name="selectedStatus" label={t('status')} options={transitionOptions} />
      <SelectField<ChangeRequestEditFormType>
        name="reason"
        label={t('reason')}
        options={transitionReasons}
        disabled={transitionReasons.length === 0}
      />
      <TextInputField<ChangeRequestEditFormType> name="notes" label={t('notes')} />
      <SelectField<ChangeRequestEditFormType> name="commsDone" label={t('commsDone')} options={yesOrNoOptions} />
      <TextInputField<ChangeRequestEditFormType> name="summarizedOutcomes" label={t('summarizedOutcomes')} />
      <SelectField<ChangeRequestEditFormType>
        name="approvedCIModification"
        label={t('approvedCIModification')}
        options={yesOrNoOptions}
        placeholder={t('yesOrNoPlaceholder')}
      />
      <TextInputField<ChangeRequestEditFormType> name="modifications" label={t('modifications')} />
      <SelectField<ChangeRequestEditFormType>
        name="deployCriteriaMet"
        label={t('deployCriteriaMet')}
        options={yesOrNoOptions}
        placeholder={t('yesOrNoPlaceholder')}
      />
      <TextInputField<ChangeRequestEditFormType> name="postDeploymentOutcomes" label={t('postDeploymentOutcomes')} />
      <SelectField<ChangeRequestEditFormType>
        name="stabilityResults"
        label={t('stabilityResults')}
        options={yesOrNoOptions}
        placeholder={t('yesOrNoPlaceholder')}
      />
      <TextInputField<ChangeRequestEditFormType> name="stability" label={t('stability')} />
    </>
  )
}
