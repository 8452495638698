import { useMemo } from 'react'
import { format } from 'date-fns'
import { flatMap } from 'lodash'

import { useLanguage } from 'main/services/hooks'
import { getDateValue } from '../filter-groups'
import { FILTER_ANY_VALUE, FILTER_NO_VALUE } from '../filter-groups/filter-group-extra-options'
import { FilterGroup, FilterGroupBase, FilterOption, FilterOptionCheckbox, SelectedFilter } from '../filter-types'

const isSpecialCase = (value: any) => value === FILTER_ANY_VALUE || value === FILTER_NO_VALUE

export const useFilterHeaderPillTip = (selected: SelectedFilter, filter: FilterGroup) => {
  return useMemo(() => {
    if (!Array.isArray(selected)) return

    return ((selected ?? []) as string[])
      .map(
        (id: string) =>
          (filter as FilterGroupBase<{ value: string; label: string }>)?.options?.find(option => option.value === id)
            ?.label
      )
      .join(', ')
  }, [selected])
}

export const useFilterHeaderPillText = (selected: SelectedFilter, filter: FilterGroup, option?: FilterOption) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return useMemo(() => {
    switch (filter.type) {
      case 'hierarchy':
        if (Array.isArray(selected)) {
          if (selected.length > 1) return `${filter.title}: (${selected.length})`

          const flatOptions = flatMap(filter.options, o => [o, ...(o.children || [])])
          const selectedOption = flatOptions.find(o => o.value === selected[0])
          return selectedOption ? `${filter.title}: ${selectedOption.label}` : undefined
        }
        return
      case 'checkbox':
      case 'select':
        if (Array.isArray(selected)) {
          if (selected.length > 1) return `${filter.title}: (${selected.length})`
          if (isSpecialCase(selected[0]))
            return `${filter.title}: ${selected[0] === '*' ? t('anyValueSet') : t('noValueSet')}`

          const selectedOption = [...filter.options].find(o => o.value === selected[0])
          return selectedOption ? `${filter.title}: ${selectedOption.label}` : undefined
        } else {
          if (!option) return

          const { labelReversed, label, value, options } = option as FilterOptionCheckbox
          if (labelReversed) return selected === value ? label : labelReversed

          if (options && Array.isArray(options)) {
            const optionLabel = options.find(o => o.value === option.value)?.label
            return `${label} ${optionLabel}`
          }

          return label
        }
      case 'text':
        if (isSpecialCase(selected)) return `${filter.title}: ${selected === '*' ? t('anyValueSet') : t('noValueSet')}`
        return `${option ? option.label : filter.title}: ${selected}`
      case 'date':
        if (Array.isArray(selected)) {
          const dates = selected.filter(Boolean)
          if (dates.length > 1) return `${filter.title}: (${dates.length})`
          return `${filter.title}: ${format(getDateValue(dates[0]) as Date, 'd MMM HH:mm')}`
        }

        if (isSpecialCase(selected)) return `${filter.title}: ${selected === '*' ? t('anyValueSet') : t('noValueSet')}`
        return `${option ? option.label : filter.title}: ${format(getDateValue(selected) as Date, 'd MMM HH:mm')}`
      case 'search':
        return `${filter.title}: ${selected}`
      case 'runbook-id':
        const s = selected as number[]
        return `${filter.title}: ${s.length > 1 ? `(${s.length})` : s[0]}`
    }
  }, [selected])
}
