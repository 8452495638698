import { useLanguage } from 'main/services/hooks'
import { DuplicateRunbooksModal as DuplicateRunbooksModalTwoSteps } from 'main/components/shared/runbook-duplicate/two-step-modal/duplicate-runbooks-modal'
import { DuplicateRunbooksModal as DuplicateRunbooksModalOneStep } from 'main/components/shared/runbook-duplicate/one-step-modal/duplicate-runbooks-modal'
import { usePermittedProjects } from 'main/services/hooks/use-permitted-projects'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ConfigModel,
  CustomFieldGroupModel,
  CustomFieldModel,
  CustomFieldUserModel
} from 'main/data-access'

export const RunbookDuplicateModalWrapper = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const isReactRunbookAndTemplateCreate = ConfigModel.useIsFeatureEnabled('react_runbook_and_template_create')

  const runbook = ActiveRunbookModel.useGet()
  const templateType = runbook.template_type
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const runbookType = ActiveRunbookModel.useRunbookType()
  const customFieldUsers = CustomFieldUserModel.useGetAll()
  const customFieldGroupsLookup = CustomFieldGroupModel.useGetLookup()
  const customFieldGroups = CustomFieldGroupModel.useGetAll()
  const accountId = ActiveAccountModel.useId()
  const { data, isLoading } = usePermittedProjects({ accountId, enabled: templateType !== 'snippet' })
  const hasPermissionOnExistingProject = data?.projects.some(p => p.id === runbook.project_id)

  const customFieldProps = {
    customFieldsLookup,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  const isRunbookTemplateNotApproved = runbook?.is_template && runbook.template_status !== 'template_approved'
  const isRunbookTypeDisabled = !!runbookType?.disabled

  // Use new one-step modal for creating runbook from template
  return isReactRunbookAndTemplateCreate && templateType === 'default' ? (
    <DuplicateRunbooksModalOneStep
      runbook={runbook}
      runbookType={runbookType}
      open
      loading={isLoading}
      closeModal={closeModal}
      runbookIds={null}
      templateType={templateType}
      customFieldProps={customFieldProps}
      errorMessage={isRunbookTypeDisabled ? [t('disabledRunbookTypeError')] : undefined}
      warningMessage={isRunbookTemplateNotApproved ? [t('unapprovedTemplateWarning')] : undefined}
      context="single"
      hasPermissionOnExistingProject={hasPermissionOnExistingProject}
      projects={data?.projects}
    />
  ) : (
    <DuplicateRunbooksModalTwoSteps
      runbook={runbook}
      runbookType={runbookType}
      open
      loading={isLoading}
      closeModal={closeModal}
      runbookIds={null}
      templateType={templateType === 'off' ? undefined : templateType}
      customFieldProps={customFieldProps}
      errorMessage={isRunbookTypeDisabled ? [t('disabledRunbookTypeError')] : undefined}
      warningMessage={isRunbookTemplateNotApproved ? [t('unapprovedTemplateWarning')] : undefined}
      context="single"
      hasPermissionOnExistingProject={hasPermissionOnExistingProject}
      projects={data?.projects}
    />
  )
}
