import { useMemo } from 'react'

import { ColumnConfig, Table, Text, Toggle } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'
import { EventWebhook, IntegrationActionItem } from 'main/services/queries/types'
import { IntegrationActionItemServerParams, useEventWebhooksQuery } from 'main/services/queries/use-event-webhooks'

const DEFAULT_WEBHOOKS_REQUEST_PARAMS = {
  key: 'webhooks'
} as IntegrationActionItemServerParams

export const EventWebhooksListPage = () => {
  const { t } = useLanguage('eventWebhooks')
  const { integrationHooks } = ConfigModel.useGet()
  const { data } = useEventWebhooksQuery(DEFAULT_WEBHOOKS_REQUEST_PARAMS)
  // Only one Event Webhook integrationSettings
  const iaiWebhooks = data?.integration_action_items

  const eventWebhooksColumns: ColumnConfig<IntegrationActionItem>[] = useMemo(() => {
    const columns = [
      {
        header: t('eventWebhooks.table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('eventWebhooks.table.columnHeaders.endpointUrl'),
        property: 'endpoint_url',
        sortable: true,
        render: (datum: EventWebhook) => {
          return <Text>{datum.settings.webhook_url as string}</Text>
        }
      },
      {
        header: t('eventWebhooks.table.columnHeaders.eventType'),
        property: 'event_type',
        sortable: true,
        render: (datum: EventWebhook) => {
          return <Text>{getEventLabels(datum.on || [])}</Text>
        }
      },
      {
        header: t('eventWebhooks.table.columnHeaders.enabled'),
        property: 'enabled',
        sortable: false,
        render: (datum: EventWebhook) => {
          return <Toggle checked={!datum.enabled} />
        }
      }
    ]

    return columns
  }, [])

  function getEventLabels(events: string[]) {
    const labels: string[] = []

    events.forEach(event => {
      integrationHooks.forEach(hook => {
        if (event === hook.on) {
          labels.push(hook.name)
        }
      })
    })

    return labels.join(', ')
  }

  return <Table columns={eventWebhooksColumns} data={iaiWebhooks} />
}
