import { useMemo, useState } from 'react'

import { Accordion, AccordionPanel, Box, Text, themeColor } from '@cutover/react-ui'
import { ChangeRequest, ChangeTask, StandaloneChangeTask } from 'main/services/queries/types'
import { ActiveRunbookModel } from 'main/data-access'
import {
  ChangeRequestItemHeader,
  ChangeRequestStatusIndicator,
  stateIndicatorStyles
} from './change-request-item-header'
import { ChangeRequestItemContent } from './change-request-item-content'
import { ChangeRequestLinkTasksModal } from '../../modals/change-request-link-tasks/change-request-link-tasks-modal'
import { ChangeRequestEditModal } from '../../modals/change-request-edit/change-request-edit-modal'
import { ChangeTaskEditModal } from '../../modals/change-task-edit/change-task-edit-modal'
import { useLanguage } from 'main/services/hooks'
import { CreateChangeTaskModal } from '../../pages/task-list-ng-connector/change-requests/create-change-task-modal'

type ChangeRequestSectionsProps = {
  changeRequests: ChangeRequest[]
  selectedChangeRequests: number[]
  toggleChangeRequest: (id: number) => void
}

type ChangeModalType = 'linkTasks' | 'editChangeRequest' | 'editChangeTask' | 'createChangeTask' | undefined

export const ChangeRequestSections = ({
  changeRequests,
  selectedChangeRequests,
  toggleChangeRequest
}: ChangeRequestSectionsProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const runbook = ActiveRunbookModel.useGet()
  const [changeRequest, setChangeRequest] = useState<ChangeRequest | undefined>(undefined)
  const [changeTask, setChangeTask] = useState<ChangeTask | undefined>(undefined)
  const [modalType, setModalType] = useState<ChangeModalType | undefined>(undefined)

  const runbookType = ActiveRunbookModel.useRunbookType()
  const { states } = runbookType.change_request_types[0]

  const stateColorMap = useMemo(() => {
    return new Map(states.map(s => [s.identifier, s.properties.color]))
  }, [states])

  const getStateColor = (changeRequest: ChangeRequest) => stateColorMap.get(changeRequest.current_state)

  const changeRequestsByState = changeRequests.reduce(
    (acc: { [x: string]: ChangeRequest[] }, changeRequest: ChangeRequest) => {
      if (changeRequest.change_request_type === 'change_task') return acc

      if (!acc[changeRequest.current_state]) {
        acc[changeRequest.current_state] = []
      }

      acc[changeRequest.current_state].push(changeRequest)

      return acc
    },
    {}
  )

  const standaloneChangeTasks = useMemo(() => {
    const map = new Map<number, StandaloneChangeTask[]>()

    changeRequests.filter(cr => cr.change_request_type === 'change_request').forEach(cr => map.set(cr.id, []))
    changeRequests.filter(cr => cr.change_request_type === 'change_task').forEach(cr => map.get(cr.parent_id)?.push(cr))

    return map
  }, [changeRequests])

  const handleLinkChangeRequestTask = (changeRequest: ChangeRequest, changeTask?: ChangeTask) => {
    setChangeRequest(changeRequest)
    changeTask && setChangeTask(changeTask)
    setModalType('linkTasks')
  }

  return (
    <Box flex={false}>
      {Object.entries(changeRequestsByState).map(([state, requests]) => (
        <Box key={state} margin={{ bottom: 'medium' }}>
          <Text
            size="large"
            color="text-light"
            margin={{ bottom: 'xsmall' }}
            css={`
              background-color: ${themeColor('bg-1')};
              padding: 0 8px;
            `}
          >
            {states.find(s => s.identifier === state)?.label}
          </Text>
          <Accordion>
            {requests.map(changeRequest => (
              <AccordionPanel
                label={changeRequest.external_id}
                a11yTitle={t('headerAriaLabel', { externalId: changeRequest.external_id })}
                labelNode={
                  <ChangeRequestItemHeader
                    changeRequest={changeRequest}
                    selectedChangeRequests={selectedChangeRequests}
                    toggleChangeRequest={toggleChangeRequest}
                    linkChangeRequestCutoverTask={handleLinkChangeRequestTask}
                    editChangeRequest={changeRequest => {
                      setChangeRequest(changeRequest)
                      setModalType('editChangeRequest')
                    }}
                  />
                }
                suffix={
                  <ChangeRequestStatusIndicator
                    color={getStateColor(changeRequest) as keyof typeof stateIndicatorStyles}
                  />
                }
                key={changeRequest.id}
              >
                <ChangeRequestItemContent
                  changeRequest={changeRequest}
                  standaloneChangeTasks={standaloneChangeTasks}
                  linkChangeRequestCutoverTask={handleLinkChangeRequestTask}
                  editChangeTask={(changeRequest, changeTask) => {
                    setChangeTask(changeTask)
                    setChangeRequest(changeRequest)
                    setModalType('editChangeTask')
                  }}
                  createChangeTask={changeRequest => {
                    setChangeRequest(changeRequest)
                    setModalType('createChangeTask')
                  }}
                />
              </AccordionPanel>
            ))}
          </Accordion>
        </Box>
      ))}
      {changeRequest && (
        <>
          <ChangeRequestLinkTasksModal
            open={modalType === 'linkTasks'}
            changeRequest={changeRequest}
            changeTask={changeTask}
            onClose={() => setModalType(undefined)}
          />
          <ChangeRequestEditModal
            open={modalType === 'editChangeRequest'}
            changeRequest={changeRequest}
            onClose={() => setModalType(undefined)}
          />
          <CreateChangeTaskModal
            open={modalType === 'createChangeTask'}
            runbookId={String(runbook.id)}
            changeRequestType="change_task"
            parentChangeRequestId={changeRequest.id}
            parentChangeRequestExternalId={changeRequest.external_id}
            handleCloseModal={() => setModalType(undefined)}
          />
        </>
      )}
      {changeRequest && changeTask && (
        <ChangeTaskEditModal
          open={modalType === 'editChangeTask'}
          changeRequest={changeRequest}
          changeTask={changeTask}
          onClose={() => setModalType(undefined)}
        />
      )}
    </Box>
  )
}
