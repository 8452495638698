import * as yup from 'yup'
import { format } from 'date-fns'

import { TextInput, useNotify } from '@cutover/react-ui'
import { FormModal, SelectField } from 'main/components/shared/form'
import { TaskModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { ChangeRequest, ChangeTask } from 'main/services/queries/types'
import {
  FinishTaskForChangeRequestPayload,
  useFinishTaskForChangeRequest
} from 'main/services/queries/use-change-requests'
import { ChangeRequestModel } from 'main/data-access/models/change-request-model'

type ChangeTaskEditModalProps = {
  open: boolean
  changeRequest: ChangeRequest
  changeTask: ChangeTask
  onClose?: () => void
}

const validationSchema = yup.object({
  selectedStatus: yup.string().required()
})

export type ChangeTaskEditFormType = yup.InferType<typeof validationSchema>

export const ChangeTaskEditModal = ({ open, changeRequest, changeTask, onClose }: ChangeTaskEditModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.editModal' })
  const updateChangeRequestState = ChangeRequestModel.useAction('update')
  const notify = useNotify()
  const tasksInternalIdLookup = TaskModel.useGetLookup({ keyBy: 'internal_id' })
  const finishTaskForChangeRequest = useFinishTaskForChangeRequest(changeRequest.id)

  const startTask = changeRequest.change_task_starts
    ? tasksInternalIdLookup[changeRequest.change_task_starts[changeTask.id]]
    : null
  const startTaskValid = startTask && ['in-progress', 'complete'].includes(startTask.stage)
  const startTimeMessage = !startTask
    ? t('startTimeInvalid')
    : startTaskValid
    ? format(new Date(startTask.start_display * 1000), 'do MMM yyyy HH:mm')
    : t('startTaskNotStarted', { taskInternalId: startTask.internal_id })

  const finishTask = changeRequest.change_task_finishes
    ? tasksInternalIdLookup[changeRequest.change_task_finishes[changeTask.id]]
    : null
  const finishTaskValid = finishTask && finishTask.stage === 'complete'
  const finishTimeMessage = !finishTask
    ? t('finishTimeInvalid')
    : finishTaskValid && finishTask?.end_display
    ? format(new Date(finishTask.end_display * 1000), 'do MMM yyyy HH:mm')
    : t('finishTaskNotComplete', { taskInternalId: finishTask.internal_id })

  const statusReasons = [
    { value: 'success', label: 'Success' },
    { value: 'failed', label: 'Failed' },
    // backend expects 'canceled' instead of 'cancelled'
    { value: 'canceled', label: 'Cancelled' }
  ]

  const dataTransformer = (data: ChangeTaskEditFormType): any => {
    // TODO: Start and finish task IDs follows pattern in change_request_finish_task_controller.js
    // This might need to be revised. Does it make sense that both correspond to the ID of the change task?
    return {
      change_task: changeTask,
      start_task_id: changeTask.id,
      finish_task_id: changeTask.id,
      finish_task_status: data.selectedStatus
    }
  }

  const handleSubmit = async (data: FinishTaskForChangeRequestPayload) => {
    finishTaskForChangeRequest.mutate(data, {
      onSuccess: changeRequest => {
        updateChangeRequestState(changeRequest)
        notify.success(t('taskSuccssMessage'), { title: t('taskSuccssTitle') })
      },
      onError: () => {
        notify.error(t('taskErrorMessage'), { title: t('taskErrorTitle') })
      }
    })
  }

  return (
    <FormModal<ChangeTaskEditFormType, FinishTaskForChangeRequestPayload>
      open={open}
      onClose={onClose}
      schema={validationSchema}
      transformer={dataTransformer}
      onSubmit={handleSubmit}
      defaultValues={{ selectedStatus: '' }}
      confirmText="update"
      title={t('titleTask', { externalId: changeTask.external_id })}
    >
      <TextInput label={t('startTime')} disabled value={startTimeMessage} />
      <TextInput label={t('finishTime')} disabled value={finishTimeMessage} />
      {startTaskValid && finishTaskValid && (
        <SelectField<ChangeTaskEditFormType> name="selectedStatus" label={t('fields.status')} options={statusReasons} />
      )}
    </FormModal>
  )
}
