import { isPlainObject } from 'lodash'

import { DataSourceValuesResponse } from '../../queries/types'
import { DataSourceValueFilterType } from './filters'

export type BuildDataSourceValueFilterContextData = {
  data_source_values_map: DataSourceValuesResponse['data_source_values_map']
  data_source_values?: DataSourceValuesResponse['data_source_values']
}

export type DataSourceValueFilterContext = {
  templates?: { [key: number]: number }
  dataSourceValues?: DataSourceValuesResponse['data_source_values']
  lowerCaseValues?: { [key: number]: { [key: string]: string | string[] } }
}

export function buildDataSourceValueFilterContext(
  filters: DataSourceValueFilterType,
  { data_source_values_map, data_source_values = [] }: BuildDataSourceValueFilterContextData
): DataSourceValueFilterContext {
  let context: DataSourceValueFilterContext = {}

  if (filters.t !== undefined) {
    context.templates = data_source_values_map.templates
  }

  if (filters.dsv) {
    context.dataSourceValues = data_source_values
  }

  if (filters.q) {
    context.lowerCaseValues = data_source_values.reduce((acc, val) => {
      acc[val.id] = Object.keys(val.values).reduce((acc, key) => {
        const value = val.values[key]

        acc[key] = Array.isArray(value)
          ? value.map(v => String(v).toLowerCase())
          : isPlainObject(value) // Value of type object is not supported
          ? ''
          : String(value).toLowerCase()

        return acc
      }, {} as { [key: string]: string | string[] })

      return acc
    }, {} as { [key: number]: { [key: string]: string | string[] } })
  }

  return context
}
