import { Box } from '../layout/box/box'
import { Text } from '../typography/text/text'

type NoResourceFoundProps = {
  clearAllFilters?: () => void
  context?: string
  anyResourceExist?: boolean
}
/**
 * @param clearAllFilters Function to clear all filters.
 * @param context Resource context, for example task or runbook.
 * @param anyResourceExist Does any resource (tasks, runbooks, templates) exist at all? If no then it wasn't probably created.
 */

// This has some one off styling choices that are specific to match the angular spacing.
// TODO: Determine what this should look like and implement properly.
export const NoResourceFound = ({ clearAllFilters, context, anyResourceExist = true }: NoResourceFoundProps) => {
  const noResourceFoundMessage = anyResourceExist
    ? `No matching ${context}s found.`
    : `No ${context}s have been created yet.` // TODO: implement language service
  const cantFindMessage = `Can't find the ${context} you're looking for? Try  `

  return (
    <Box data-testid="no-resource-found" margin={{ top: '48px' }} css="white-space: normal;">
      <Text textAlign="center" color="text-light" css="margin-bottom: 20px; font-size: 18px;">
        {noResourceFoundMessage}
      </Text>
      {clearAllFilters && (
        <Text textAlign="center" color="text-light" css="font-size: 18px;">
          {cantFindMessage}
          <Text
            role="button"
            color="primary"
            onClick={clearAllFilters}
            css={`
              cursor: pointer;
              font-size: 18px;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            clearing your filters
          </Text>
        </Text>
      )}
    </Box>
  )
}
