import { atomFamily } from 'recoil'

import { AiSuggestionsWebsocketResponseType } from 'main/services/queries/use-runbook-ai-suggestions'

export const aiSuggestions_INTERNAL = atomFamily<
  AiSuggestionsWebsocketResponseType,
  { sessionId: string; runbookId: number; promptItemKey: string }
>({
  key: 'ai-suggestions',
  default: {
    result: [],
    runbook: undefined,
    session_id: '',
    prompt_key: '',
    generated_at: ''
  }
})

export const aiSuggestionsLoading_INTERNAL = atomFamily<
  boolean,
  { sessionId: string; runbookId: number; promptItemKey: string }
>({
  key: 'ai-suggestions-loading',
  default: false
})
