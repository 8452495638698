import { keyBy, unescape } from 'lodash'
import { selector } from 'recoil'

import {
  CustomField,
  CustomFieldApplyToSlug,
  CustomFieldGroup,
  CustomFieldUser,
  FieldOption
} from 'main/services/queries/types'
import { accountComputedState, accountMetaState } from './account'

export type ExtendedCustomField = CustomField & {
  field_type_name: string
  apply_to_name?: string
  apply_to_slug?: CustomFieldApplyToSlug
  options: CustomField['options'] & {
    // TODO: we should really have these things come back from the backend; the way that we find the conditional
    // logic field is hacky (see all hardcoded values and attempt to find ids based on name) and not performant
    // but saves us iterating every time the custom field form is opened
    search_parent_id?: number | null
    search_parent_value_key?: string | null
    search_child_id?: number | null
    search_child_value_key?: string | null
  }
}

const CONDITIONAL_PROGRESSION_PARENT_FORM_VALUE_KEY = 'Task Id'

export const accountCustomFieldsState = selector<ExtendedCustomField[]>({
  key: 'account-custom-fields',
  get: ({ get }) => {
    const customFields = get(accountMetaState).custom_fields
    const parentChildLookup = get(accountComputedState).customFields?.parentChildLookup
    const childParentLookup = get(accountComputedState).customFields?.childParentLookup

    return customFields.map(cf => {
      let constraint = cf.constraint

      if (constraint) {
        try {
          constraint = JSON.parse(constraint)
        } catch (e) {
          console.warn('Failed to parse constraint', e)
        }
      }

      const child = parentChildLookup?.[cf.id]
      const parent = childParentLookup?.[cf.id]

      return {
        ...cf,
        constraint,
        name: unescape(cf.name),
        field_type_name: cf.field_type.name,
        apply_to_name: cf.apply_to?.name,
        apply_to_slug: cf.apply_to?.slug,
        options: {
          ...cf.options,
          search_parent_id: parent ? parent.id : undefined,
          search_parent_value_key: parent ? CONDITIONAL_PROGRESSION_PARENT_FORM_VALUE_KEY : undefined,
          search_child_id: child ? child.id : undefined,
          search_child_value_key: child ? child.value_key : undefined
        }
      }
    })
  }
})

export const customFieldLookups = selector({
  key: 'custom-field-lookups',
  get: ({ get }) => {
    const customFields = get(accountCustomFieldsState)
    const filteredValueKeyFields = customFields.filter(field => field.value_key !== null)

    const optionsLookup = customFields.reduce((acc, cf) => {
      cf.field_options?.forEach(fo => (acc[fo.id] = fo))
      return acc
    }, {} as Record<number, FieldOption>)

    return {
      fields: keyBy(customFields, 'id'),
      fieldsByValueKey: keyBy(filteredValueKeyFields, 'value_key'),
      options: optionsLookup
    }
  }
})

export const customFieldOptionsState = selector({
  key: 'custom-field-options',
  get: ({ get }) => {
    const customFields = get(accountCustomFieldsState)

    const options = customFields.flatMap(cf => cf.field_options || [])
    return options
  }
})

export const customFieldOptionsLookup = selector({
  key: 'custom-field-options-lookup',
  get: ({ get }) => {
    const { options } = get(customFieldLookups)
    return options
  }
})

export const customFieldLookup = selector({
  key: 'custom-field-lookup',
  get: ({ get }) => {
    const { fields } = get(customFieldLookups)
    return fields
  }
})

export const customFieldLookupByValueKey = selector({
  key: 'custom-field-lookup-by-value-key',
  get: ({ get }) => {
    const { fieldsByValueKey } = get(customFieldLookups)
    return fieldsByValueKey
  }
})

export const accountCustomFieldUsers = selector<CustomFieldUser[]>({
  key: 'account-custom-field-users',
  get: ({ get }) => {
    return get(accountMetaState).custom_field_users
  }
})

export const customFieldUserLookup = selector({
  key: 'custom-field-user-lookup',
  get: ({ get }) => {
    const customFieldUsers = get(accountCustomFieldUsers)
    return keyBy(customFieldUsers ?? [], 'id')
  }
})

export const accountCustomFieldGroupsState = selector<CustomFieldGroup[]>({
  key: 'account-custom-field-groups',
  get: ({ get }) => {
    return get(accountMetaState).custom_field_groups
  }
})

export const customFieldGroupsLookup = selector({
  key: 'custom-field-groups-lookup',
  get: ({ get }) => {
    const customFieldGroups = get(accountCustomFieldGroupsState)

    return keyBy(customFieldGroups ?? [], 'id')
  }
})
