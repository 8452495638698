import { Attributes, ForwardedRef, forwardRef, memo, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import {
  DataTable,
  DataTableExtendedProps,
  ColumnConfig as GrommetColumnConfig,
  SortType,
  Spinner,
  ThemeContext
} from 'grommet'
import { useLocation, useMeasure } from 'react-use'
import styled, { css } from 'styled-components/macro'

import { Box } from '../../layout'
import { themeColor } from '../../theme'
import { Text } from '../../typography'

const PINNED_MIN_WIDTH_DEFAULT = 500
const BREAKPOINT_LARGE = 800
const BREAKPOINT_MEDIUM = 600

export type ColumnConfig<T> = GrommetColumnConfig<T> & {
  key?: string
  hidden?: boolean
}

export type TableProps<T> = Omit<DataTableExtendedProps<T>, 'sortable'> & {
  css?: Attributes['css']
  columns: ColumnConfig<T>[]
  isLoading?: boolean
  isLoadingMore?: boolean
  sortable?: boolean | 'server' | 'client'
  pinnedMinWidth?: number
  alternateRowColors?: boolean
  selectedItem?: {
    key: string
    value: string | number
  }
}

export const Table = memo(forwardRef(TableComponent)) as <T>(
  props: TableProps<T> & { ref?: ForwardedRef<HTMLTableElement> }
) => ReturnType<typeof TableComponent>

function TableComponent<T>(
  {
    columns: columnData,
    pin = true,
    isLoading,
    onSort,
    sortable,
    /** threshold for showing pinned columns */
    pinnedMinWidth = PINNED_MIN_WIDTH_DEFAULT,
    alternateRowColors = true,
    selectedItem,
    ...restProps
  }: TableProps<T>,
  ref: ForwardedRef<HTMLTableElement>
) {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [sort, setSort] = useState<SortType | undefined>(undefined)
  const [useMeasureRef, { width }] = useMeasure<HTMLDivElement>()
  const scrollLocation = useRef<{ source: 'more' | 'sort'; value: number } | null>(null)
  // we don't have react-router in react-ui, so we're using this hook from react-use. It doesn't support
  // hash based routing so we have to manually extract the search query from the url hash.
  const location = useLocation()
  const search = location.hash?.split('?')[1]

  const columns: ColumnConfig<T>[] | undefined = useMemo(() => {
    return (columnData as ColumnConfig<T>[])
      ?.filter(({ hidden }) => !hidden)
      .map(({ key, hidden: _hidden, ...column }) => {
        let size = width < BREAKPOINT_LARGE && ['xlarge', 'large'].includes(column.size ?? '') ? 'medium' : column.size
        size = width < BREAKPOINT_LARGE && ['medium'].includes(column.size ?? '') ? 'small' : size
        size = width < BREAKPOINT_MEDIUM && ['medium'].includes(size ?? '') ? 'small' : size
        const pin = column.pin && width > pinnedMinWidth

        return {
          ...column,
          pin,
          property: key ?? column.property,
          size,
          header: (
            <Box width={{ width: size, min: pin ? 'calc(100% - 26px)' : undefined }} responsive={!!pin}>
              <Text
                weight="bold"
                truncate="tip"
                tipPlacement="top"
                color={sort?.property === column.property ? 'primary' : 'text'}
                css={`
                  padding-left: ${column.sortable === false ? '12px' : '0'};
                `}
              >
                {column.header as ReactNode}
              </Text>
            </Box>
          ),
          render: (datum: T) => {
            const content = column.render?.(datum) ?? datum[column.property as keyof T]
            return (
              <Text
                css="display: block"
                truncate="tip"
                tipPlacement="top"
                data-selected={
                  selectedItem && (datum as { [key: string]: string })[selectedItem.key] === selectedItem.value
                }
              >
                {content as ReactNode}
              </Text>
            )
          }
        }
      })
  }, [columnData, width, sort, selectedItem])

  const handleSort = useCallback(
    (sortBy?: { property: string; direction: 'desc' | 'asc' }) => {
      scrollLocation.current = { source: 'more', value: scrollRef.current?.scrollLeft ?? 0 }
      if (sortBy) {
        setSort(sortBy)
        onSort?.(sortBy)
      }
    },
    [onSort]
  )

  const alternateRowColorsSelector = restProps.onClickRow ? 'tr:not(:hover):nth-child(odd)' : 'tr:nth-child(odd)'

  // Basic themeing is being weird for hoving rows when with pinned columns. Have to extend the theme
  // context here where we have knoweldge of whether the rows are clickable or not to apply the hover color
  return (
    <ThemeContext.Extend
      value={{
        dataTable: {
          body: {
            extend: css`
              background: ${themeColor('bg')};
              td {
                padding: 8px;
                border-right: 1px solid ${themeColor('bg-1')};
              }
              th {
                padding: 4px 0;
              }
              ${restProps.onClickRow &&
              css`
                tr:hover {
                  &,
                  td {
                    background: ${themeColor('bg-1')};
                  }
                }
              `}
              ${alternateRowColors &&
              css`
                ${alternateRowColorsSelector} td {
                  background-color: ${themeColor('bg-0')};
                }
              `}

              /* 
                Highlight selected row when data-selected='true' on any child element of a table cell
                Use selectedItem key and value to determine which row to highlight
              */

              tr:has([data-selected='true']) {
                background-color: ${themeColor('primary-bg')} !important;
                td {
                  background: unset !important;
                }
              }
            `,
            pinned: {
              body: {
                extend: css`
                  background: ${themeColor('bg')};
                  ${restProps.onClickRow &&
                  css`
                    &:hover {
                      background: ${themeColor('bg-2')};
                    }
                  `}
                `
              }
            }
          }
        }
      }}
    >
      <Box width="100%" height="100%" ref={useMeasureRef}>
        <Box css="position: relative" height="100%" overflow="auto" ref={scrollRef} margin={{ top: 'xsmall' }}>
          <StyledDataTable
            {...restProps}
            primaryKey={false}
            key={search}
            background="bg"
            ref={ref as any}
            responsize={false}
            // @ts-ignore issue with typed properly as T vs expected as unknown. Related to what is needed to type memo + forwardRef
            columns={columns}
            sortable={!!sortable}
            onSort={handleSort}
            pin={pin}
          />
        </Box>
      </Box>
      {isLoading && <Loader />}
    </ThemeContext.Extend>
  )
}

// TODO: revise component so it does not require margin-bottom
const StyledDataTable = styled(DataTable)<{ isLoading?: boolean }>`
  position: initial;
  height: auto;
  margin-bottom: 24px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Loader = () => {
  return (
    <Box
      css={`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        inset: 0;
      `}
    >
      <Spinner
        css={`
          position: absolute;
          inset: 0;
          margin: auto;
          padding: 0;
          svg {
            fill: ${themeColor('text-disabled')};
            height: 100%;
            width: 100%;
          }
        `}
        size="medium"
        color="text-disabled"
      />
    </Box>
  )
}
