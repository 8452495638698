import styled from 'styled-components/macro'

import { Text } from '@cutover/react-ui'
import { SpinnerIcon } from '../../icon'
import { Box } from '../box'

type LoadingPanelProps = {
  overlay?: boolean
  size?: number
  contained?: boolean
  loadingText?: string
}

export const LoadingPanel = (props: LoadingPanelProps) => {
  const { overlay = false, size = 80, contained } = props

  const IconRender = () => (
    <IconWrapper data-testid="loading" size={size}>
      <Box gap="small">
        <SpinnerIcon color="bg-4" css="width: auto;" size="xlarge" />
        {props.loadingText && <Text color="text-light">{props.loadingText}</Text>}
      </Box>
    </IconWrapper>
  )

  return contained ? (
    <Box align="center" justify="center" fill css="text-align: center" height="100%" width="100%" background="bg">
      <IconRender />
    </Box>
  ) : overlay ? (
    <OverlayWrapper background="bg">
      <IconRender />
    </OverlayWrapper>
  ) : (
    <FixedWrapper background="bg">
      <IconRender />
    </FixedWrapper>
  )
}

const OverlayWrapper = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  text-align: center;
  z-index: 999;
`

const FixedWrapper = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
`

const IconWrapper = styled(Box)<{ size: number }>`
  position: absolute;
  top: ${({ size }) => `calc(50% - ${size / 2}px)`};
  left: 0;
  right: 0;
  z-index: 999;
  i {
    width: 100%;
  }
`
