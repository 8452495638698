import { DataSourceValue } from '../../queries/types'
import { custom } from './custom'
import { DataSourceValueFilterContext } from './filter-context'
import { DataSourceValueFilterFunction, DataSourceValueFilterType } from './filters'
import { hasTemplate } from './has-template'
import { query } from './query'

const filterMap: { [key: string]: DataSourceValueFilterFunction } = {
  q: query,
  t: hasTemplate,
  dsv: custom
}

/**
 * Builds an array with only the functions that match
 * the provided filters
 * @param filters The filter payload
 * @returns an array of filter functions
 */
export const buildFilterPipeline = (filters: DataSourceValueFilterType): DataSourceValueFilterFunction[] => {
  let functions = []
  for (let key in filters) {
    if (typeof filterMap[key] === 'function') {
      functions.push(filterMap[key])
    }
  }
  return functions
}

/**
 * Apply the filter pipeline to the provided tasks
 * and returns only the ones that match the given filters
 * @param dataSourceValue The data source values to filter
 * @param filters The filters to apply
 * @param context Optional context data required by some filters
 * @returns
 */
export const applyFilterPipeline = (
  dataSourceValues: DataSourceValue[],
  filters: DataSourceValueFilterType,
  context: DataSourceValueFilterContext = {}
): DataSourceValue[] => {
  const { dsv: dsvFilters = {}, ...restFilters } = filters
  const filterFunctions = buildFilterPipeline(restFilters)

  if (dataSourceValues.length > 0 && filterFunctions.length > 0) {
    for (let i = 0; i < filterFunctions.length; i++) {
      dataSourceValues = filterFunctions[i].call(null, dataSourceValues, filters, context)
      if (dataSourceValues.length === 0) break
    }
  }

  /**
   * Data source value filters are bundled as an object in the 'dsv' filter, keyed by custom field value_key.
   * e.g. dsv: { location: ['UK'] }
   *
   * In order to handle each of those filters as a separate group, such that they are
   * applied as a logical AND, the 'dsv' object entries are applied independently.
   * e.g. { location: ['UK'] } AND { category: ['Finance'] }
   */
  Object.entries(dsvFilters).forEach(([key, value]) => {
    if (dataSourceValues.length > 0) {
      dataSourceValues = custom(dataSourceValues, { dsv: { [key]: value } }, context)
    }
  })

  return dataSourceValues
}
