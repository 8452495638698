import { Fragment, RefObject, useCallback, useMemo, useState } from 'react'
import { formatISO, isDate } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'
import { RequireAllOrNone } from 'type-fest'
import { get } from 'lodash'

import {
  Box,
  CodeEditor,
  DateTimePicker,
  IconButton,
  Modal,
  MultiSelectControl,
  RadioboxGroup,
  Select,
  Text,
  TextInput,
  useNotify
} from '@cutover/react-ui'
import { CustomFieldUserSelect } from './custom-fiield-user-select'
import { SearchableCustomField } from './searchable-custom-field'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import {
  CustomField,
  CustomFieldDisplayType,
  CustomFieldUser,
  FieldOption,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { FieldOptionPayload, useFieldOptionsMutation } from 'main/services/queries/use-custom-fields'
import { TaskSelectField } from '../form/task-select'
import { FormType } from '../form/form'
import { TextEditorField } from '../form'
import { StreamModel, TaskTypeModel } from 'main/data-access'
import { TaskModel } from 'main/data-access/models/task-model'
import { DynamicTextField } from '../form/dynamic-text-field/dynamic-text-field'
import { ExtendedCustomField } from 'main/recoil/runbook'

export type CustomFieldFormProps = {
  errors?: Record<string, any>
  customFields: CustomField[] | ExtendedCustomField[]
  customFieldUsers?: CustomFieldUser[]
  namePrefix?: string
  disabled?: boolean
  readOnly?: boolean
  validateRequired?: boolean // for bulk edit custom field form, we don't need any of the custom field fields to be required
  timezone?: string | null // for date-time-picker custom fields to have timezone offset info on the label
  taskId?: string | number
  formRef?: RefObject<FormType<any>>
}
type CustomFieldFormDataProps = {
  taskLookup: Record<number, TaskListTask>
  taskTypeLookup: Record<number, TaskType>
  streamLookup: Record<number, StreamListStream>
}

export const CustomFieldForm = (props: CustomFieldFormProps & RequireAllOrNone<CustomFieldFormDataProps>) => {
  return !props.taskLookup || !props.taskTypeLookup || !props.streamLookup ? (
    <CustomFieldFormWithGlobalStateData {...props} />
  ) : (
    <CustomFieldFormInner {...props} />
  )
}

const CustomFieldFormWithGlobalStateData = (props: CustomFieldFormProps) => {
  const { contents: taskLookup } = TaskModel.useGetLookupLoadable()
  const { contents: taskTypeLookup } = TaskTypeModel.useGetLookupLoadable()
  const { contents: streamLookup } = StreamModel.useGetLookupLoadable()

  return (
    <CustomFieldFormInner
      {...props}
      taskLookup={taskLookup}
      taskTypeLookup={taskTypeLookup}
      streamLookup={streamLookup}
    />
  )
}

const CustomFieldFormInner = ({
  customFields,
  customFieldUsers,
  namePrefix = '',
  disabled,
  readOnly,
  validateRequired = true,
  errors,
  timezone,
  taskId,
  formRef,
  taskLookup,
  taskTypeLookup,
  streamLookup
}: CustomFieldFormProps & CustomFieldFormDataProps) => {
  const { t } = useLanguage(['customFields', 'common'])
  const { account } = useAccount()
  const {
    register,
    control,
    getValues,
    formState: { defaultValues }
  } = useFormContext()
  const initialFieldValues = useMemo(() => {
    return get(defaultValues, `${namePrefix || ''}field_values`)
  }, [defaultValues])

  const errorIds = Object.keys(errors || {})
  const notify = useNotify()
  const fieldOptionsMutation = useFieldOptionsMutation()
  const [isOptionConfirmModalOpen, setOptionConfirmModalOpen] = useState(false)
  const [optionCustomField, setOptionCustomField] = useState<CustomField | null>(null)
  const [optionValue, setOptionValue] = useState<string | null>(null)

  const closeOptionConfirmModal = () => {
    setOptionConfirmModalOpen(false)
    setOptionCustomField(null)
    setOptionValue(null)
  }

  const showOptionConfirmModal = (customField: CustomField, value: string) => {
    setOptionConfirmModalOpen(true)
    setOptionCustomField(customField)
    setOptionValue(value.trim())
  }

  const handleOptionCreate = () => {
    if (optionCustomField && optionValue) {
      mutateCustomFieldOption(optionCustomField.id, optionValue)
    }
  }

  const mutateCustomFieldOption = (customFieldId: number, value: string) => {
    const data: FieldOptionPayload = {
      account_slug: account?.slug,
      field_option: {
        custom_field_id: customFieldId,
        name: value.trim()
      }
    }

    fieldOptionsMutation.mutate(data, {
      onSuccess: () => {
        notify.success(t('fieldOptions.saved'))
        closeOptionConfirmModal()
      },
      onError: () => {
        notify.warning(t('fieldOptions.uniqueWarningDescription'), {
          title: t('fieldOptions.uniqueWarningHeader')
        })
        closeOptionConfirmModal()
      }
    })
  }

  const optionConfirmModal = (
    <Modal
      title={t('fieldOptions.confirmDialogHeader')}
      focusConfirmButton
      open={isOptionConfirmModalOpen}
      onClickConfirm={handleOptionCreate}
      onClose={closeOptionConfirmModal}
    >
      {optionValue && (
        <Box direction="row">
          <Text>{t('fieldOptions.confirmDialogDescriptionFirst')}</Text>
          <Text css="font-weight: 700">&nbsp;{optionValue}&nbsp;</Text>
          <Text>{t('fieldOptions.confirmDialogDescriptionSecond')}</Text>
          <Text css="font-weight: 700">&nbsp;{optionCustomField?.display_name || optionCustomField?.name}</Text>
          <Text>{t('fieldOptions.confirmDialogDescriptionThird')}</Text>
        </Box>
      )}
    </Modal>
  )

  const getOptionLabel = useCallback(
    ({ name, archived }: FieldOption) => `${name}${archived ? ` (${t('archived')})` : ''}`,
    []
  )

  const filterArchivedAndNotSelected = useCallback(
    (customField: CustomField): FieldOption[] => {
      return customField.field_options.filter(o => {
        if (!o.archived) return true

        switch (customField.field_type.slug) {
          case 'checkboxes': {
            const initiallySelectedFieldValues = initialFieldValues[customField.id]?.value

            if (!initiallySelectedFieldValues) return !o.archived
            const parsedFieldValue = JSON.parse(initiallySelectedFieldValues)
            return Array.isArray(parsedFieldValue) ? parsedFieldValue.includes(o.id) : false
          }
          case 'select_menu':
          case 'radiobox':
            return initialFieldValues[customField.id]?.field_option_id === o.id
          default:
            return true
        }
      })
    },
    [initialFieldValues]
  )

  const [codeEditorModalOpen, setCodeEditorModalOpen] = useState(false)

  return (
    <Box direction="column" width="100%">
      {optionConfirmModal}
      {customFields.sort(sortByDisplayOrder).map(cf => {
        const customFieldName = cf.display_name || cf.name
        const hasError = errorIds.includes(String(cf.id))
        const cfRequired = !validateRequired ? false : cf.required
        // TODO: remove cf.type check once legacy SCF are migrated, ticket #WIN-2667
        const cfSlug =
          cf.type === 'SearchableCustomField' || cf.type === 'MultiSearchableCustomField'
            ? 'searchable'
            : cf.field_type.slug

        // Microfosft Teams integration channel searchable custom field should not show dependent fields
        // TODO: this should be substituted by a more generic solution to hide dependent fields
        const hideDependentFields = cf.name.includes('MS Channel')

        switch (cfSlug) {
          case 'text':
            if (cf.type !== 'DependentCustomField') {
              const fieldName = `${namePrefix}field_values.${cf.id}.value`
              const fieldValue = getValues(`${namePrefix}field_values.${cf.id}`)
              const hasComputedValue = fieldValue?.computed_value !== undefined

              if (hasComputedValue) {
                return (
                  <DynamicTextField
                    key={cf.id}
                    as="input"
                    disabled={disabled}
                    hasError={hasError}
                    label={cf.display_name || cf.name}
                    readOnly={readOnly || !!cf.options?.readonly}
                    required={cfRequired}
                    templateKey={`${namePrefix}field_values.${cf.id}.value`}
                    valueKey={`${namePrefix}field_values.${cf.id}.computed_value`}
                  />
                )
              } else {
                return (
                  <TextInput
                    key={cf.id}
                    label={customFieldName}
                    required={cfRequired}
                    hasError={hasError}
                    readOnly={readOnly || !!cf.options?.readonly}
                    disabled={disabled}
                    defaultValue={cf.default_value as string}
                    {...register(fieldName)}
                  />
                )
              }
            }
            break
          case 'searchable':
            const dependentCustomFields = customFields.filter(customField => {
              return customField.source_custom_field_id == cf.id && customField.type == 'DependentCustomField'
            })
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchableCustomField
                      namePrefix={namePrefix}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      required={cfRequired}
                      customField={cf as ExtendedCustomField}
                      value={value}
                      multiSelect={cf.type === 'MultiSearchableCustomField'}
                      onChange={onChange}
                      hasError={hasError}
                      taskId={taskId}
                      hideDependentFields={hideDependentFields}
                      parentId={(cf as ExtendedCustomField).options?.search_parent_id}
                      parentValueKey={(cf as ExtendedCustomField).options?.search_parent_value_key}
                      dependentCustomFields={dependentCustomFields as ExtendedCustomField[]}
                    />
                  )
                }}
              />
            )
          case 'textarea':
            return (
              <TextEditorField
                formRef={formRef}
                key={cf.id}
                name={`${namePrefix}field_values.${cf.id}.value`}
                label={customFieldName}
                required={cfRequired}
                readOnly={readOnly || !!cf.options?.readonly}
                disabled={!!disabled}
                hasError={hasError}
                restrictedMode
              />
            )

          case 'select_menu':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.field_option_id`}
                render={({ field: { value, onChange, ref, onBlur } }) => {
                  const filteredOptions = filterArchivedAndNotSelected(cf)

                  let parsedError: undefined | { archived: number }
                  try {
                    parsedError = JSON.parse(errors?.[String(cf.id)]?.field_option_id?.message || '{}')
                  } catch (e) {}

                  return (
                    <Select
                      inputRef={ref}
                      onBlur={onBlur}
                      value={value ? filteredOptions.find(o => o.id === value)?.id : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={onChange}
                      options={filteredOptions.map(fo => ({
                        label: getOptionLabel(fo),
                        value: fo.id,
                        hasError: parsedError?.archived === fo.id
                      }))}
                      hasError={hasError}
                      readOnly={readOnly || !!cf.options?.readonly}
                      disabled={disabled}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  )
                }}
              />
            )
          case 'radiobox':
            // need to use a controller here because CF value is a number and needs conversion to string
            // (html inputs do not accept numbers as values)
            return (
              <Controller
                name={`${namePrefix}field_values.${cf.id}.field_option_id`}
                key={cf.id}
                control={control}
                render={({ field: { name, onChange, value, onBlur, ref } }) => {
                  const filteredOptions = filterArchivedAndNotSelected(cf)

                  let parsedError: undefined | { archived: number }
                  try {
                    parsedError = JSON.parse(errors?.[String(cf.id)]?.field_option_id?.message || '{}')
                  } catch (e) {}

                  return filteredOptions.length > 10 ? (
                    <Select
                      inputRef={ref}
                      onBlur={onBlur}
                      value={value ? filteredOptions.find(o => o.id === value)?.id : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={onChange}
                      options={filteredOptions.map(fo => ({
                        label: getOptionLabel(fo),
                        value: fo.id,
                        hasError: fo.id === parsedError?.archived
                      }))}
                      hasError={hasError}
                      readOnly={readOnly || !!cf.options?.readonly}
                      disabled={disabled}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  ) : (
                    <RadioboxGroup
                      name={name}
                      label={customFieldName}
                      required={cfRequired}
                      direction="column"
                      onChange={e => onChange(Number(e.target.value))} // coercing here so checked===true in Grommet RadioButtonGroup
                      value={value}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      onBlur={onBlur}
                      ref={ref}
                      options={filteredOptions.map(option => ({
                        value: option.id,
                        label: getOptionLabel(option),
                        hasError: option.id === parsedError?.archived
                      }))}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  )
                }}
              />
            )
          case 'checkboxes':
            // need to use a controller here because CF value is a number and needs conversion to string
            // (html inputs do not accept numbers as values)
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange } }) => {
                  const parsedValue = JSON.parse(value || '[]') as number[]
                  let parsedError
                  try {
                    parsedError = JSON.parse(errors?.[String(cf.id)]?.value?.message || '{}')
                  } catch (e) {}
                  const archivedErrorIds = parsedError?.archived || []

                  return (
                    <MultiSelectControl
                      key={cf.id}
                      label={customFieldName}
                      onCreateOption={
                        cf.allow_field_creation && !readOnly
                          ? (value: string) => mutateCustomFieldOption(cf.id, value)
                          : undefined
                      }
                      options={filterArchivedAndNotSelected(cf).map(option => ({
                        value: option.id,
                        option: option.name,
                        label: getOptionLabel(option),
                        hasError: archivedErrorIds.includes(option.id)
                      }))}
                      value={parsedValue}
                      onChange={val => {
                        const values = val?.map(v => v.value)
                        values && onChange(JSON.stringify(values))
                      }}
                      required={cfRequired}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                    />
                  )
                }}
              />
            )
          case 'datetime':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange, ref, onBlur } }) => {
                  const timeValue = value ? new Date(value) : null
                  return (
                    <DateTimePicker
                      value={isDate(timeValue) ? timeValue : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={date => onChange(date ? formatISO(date) : null)}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      inputRef={ref}
                      onBlur={onBlur}
                      timezone={timezone}
                    />
                  )
                }}
              />
            )
          case 'temporary':
            // TODO: check with integrations if this type ever made its way to production and if
            // any clients are using it
            return (
              <Box key={cf.id} margin={{ top: 'medium' }}>
                <TextInput
                  {...register(`${namePrefix}field_values.${cf.id}.value`)}
                  label={customFieldName}
                  required={cfRequired}
                  disabled={disabled || readOnly || !!cf.options?.readonly} // TODO: distinguish between disabled and readonly
                />
              </Box>
            )
          case 'user_select':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                // the custom field is stored in the database as json stringified array of ids
                render={({ field: { value: stringifiedValue, onChange, onBlur, ref } }) => {
                  const userIds = JSON.parse(stringifiedValue || '[]') as number[]

                  return (
                    <CustomFieldUserSelect
                      required={cfRequired}
                      label={customFieldName}
                      accountId={account?.id}
                      value={userIds}
                      customFieldUsers={customFieldUsers}
                      onChange={value => (value ? onChange(JSON.stringify(value)) : null)}
                      disabled={disabled}
                      inputRef={ref}
                      onBlur={onBlur}
                      readOnly={readOnly || !!cf.options?.readonly}
                    />
                  )
                }}
              />
            )
          case 'endpoint':
            // TODO: Remove. Will be deprecated soon
            return (
              <TextInput
                {...register(`${namePrefix}field_values.${cf.id}.value`)}
                key={cf.id}
                label={customFieldName}
                required={cfRequired}
                readOnly={readOnly || !!cf.options?.readonly}
                disabled={disabled}
              />
            )
          case 'task_picker':
            const fieldName = `${namePrefix}field_values.${cf.id}.value`
            let taskInternalId = getValues(`${namePrefix}field_values.${cf.id}.value`)
            taskInternalId = typeof taskInternalId !== 'object' ? [taskInternalId] : taskInternalId
            const possibleTasks = Object.keys(taskLookup).map(id => taskLookup[id as unknown as number])

            return (
              <Fragment key={cf.id}>
                {taskTypeLookup && streamLookup && (
                  <TaskSelectField
                    label={customFieldName}
                    name={fieldName}
                    tasks={possibleTasks}
                    taskTypeLookup={taskTypeLookup}
                    streamLookup={streamLookup}
                    valueKey={'internal_id'}
                    value={taskInternalId}
                    single
                    placeholderValue={t('fields.taskPicker.placeholderValue')}
                    placeholder={t('fields.taskPicker.placeholder')}
                    required={cfRequired}
                    disabled={disabled}
                    readOnly={readOnly || !!cf.options?.readonly}
                    onChange={value => (value ? JSON.stringify(value) : null)}
                  />
                )}
              </Fragment>
            )
          case 'code_editor':
            return (
              <Box key={cf.id}>
                <Box direction="row">
                  <Text size="small" color="light" alignSelf="center">
                    {' '}
                    {customFieldName}{' '}
                  </Text>
                  <IconButton
                    label={t('fields.codeEditor.placeholder')}
                    icon="open-new"
                    size="small"
                    onClick={() => setCodeEditorModalOpen(true)}
                  />
                </Box>
                <Controller
                  key={cf.id}
                  name={`${namePrefix}field_values.${cf.id}.value`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CodeEditor
                      onChange={value => onChange(value)}
                      value={value as string | undefined}
                      defaultLanguage="json"
                      resize="vertical"
                      showCutoverCodeCompletion
                      minHeight={100}
                    />
                  )}
                />
                <Modal
                  title={customFieldName}
                  open={codeEditorModalOpen}
                  onClose={() => setCodeEditorModalOpen(false)}
                  hideFooter
                >
                  <Controller
                    name={`${namePrefix}field_values.${cf.id}.value`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CodeEditor
                        onChange={value => onChange(value)}
                        value={value as string | undefined}
                        defaultLanguage="json"
                        resize="vertical"
                        showCutoverCodeCompletion
                        minHeight={600}
                      />
                    )}
                  />
                </Modal>
              </Box>
            )
          default:
            return unhandledCFTypeError(cf.field_type.slug) // this ensures we handle all field_type slugs in switch statement
        }
      })}
    </Box>
  )
}

const unhandledCFTypeError = (type: CustomFieldDisplayType): never => {
  throw new Error(`Custom field type ${type} not handled. If this is expected please handle it by returning null`)
}

const sortByDisplayOrder = (a: CustomField, b: CustomField) => (a.order || 0) - (b.order || 0)
