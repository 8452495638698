import { format, isSameDay } from 'date-fns'

import { useLanguage } from 'main/services/hooks'
import { ChangeRequest } from 'main/services/queries/types'

export const useChangeRequestChangeWindow = (changeRequest: ChangeRequest) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })

  const getMetadataByKey = (key: string) => {
    if (!changeRequest.metadata?.fields) return

    const fields = changeRequest.metadata.fields
    return key in fields ? fields[key].value : null
  }

  const withinChangeWindow = () => {
    const windowStart = getMetadataByKey('window_starts_at')
    const windowEnd = getMetadataByKey('window_ends_at')
    if (!windowStart || !windowEnd) return false

    const windowStartDate = new Date(windowStart)
    const windowEndData = new Date(windowEnd)
    const now = new Date()

    if (now >= windowStartDate && now < windowEndData) return true
    return false
  }

  // TODO: need to take runbook timezone into account
  const formatChangeWindow = () => {
    const windowStart = getMetadataByKey('window_starts_at')
    const windowEnd = getMetadataByKey('window_ends_at')
    if (!windowStart || !windowEnd) return t('windowNone')

    const windowStartDate = new Date(windowStart)
    const windowEndData = new Date(windowEnd)

    if (isSameDay(windowStartDate, windowEndData)) {
      return `${format(windowStartDate, 'do MMM yyyy, HH:mm')} - ${format(windowEndData, 'HH:mm')}`
    } else {
      return `${format(windowStartDate, 'do MMM yyyy, HH:mm')} - ${format(windowEndData, 'do MMM, HH:mm')}`
    }
  }

  return { withinChangeWindow, formatChangeWindow }
}
