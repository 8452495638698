import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

/**
 * A hook that provides a debounced version of a given value.
 *
 * @param value - The value to debounce. This can be any type.
 * @param delay - The debounce delay in milliseconds. Defaults to 500ms.
 * @returns The debounced value, which updates only after the specified delay.
 *
 * @description
 * This hook is useful for scenarios where you want to limit the frequency of updates
 * to a value. You can reduce the number of updates and improve performance, which is
 * important when frequent updates could lead to unnecessary re-renders or API calls.
 *
 * Example use cases:
 * - Debouncing search input to avoid making API calls on every keystroke.
 * - Delaying updates to a state variable until the user stops interacting.
 * - Reducing the frequency of expensive computations triggered by rapidly changing values.
 * - Updating the UI only when a prop has stopped changing for a certain period.
 */
export function useDebouncedValue<TValue>(value: TValue, delay = 500): TValue {
  const [state, setState] = useState<TValue>(value)

  const debouncedUpdate = useMemo(
    () =>
      debounce((value: TValue) => {
        setState(value)
      }, delay),
    [delay]
  )

  useEffect(() => {
    debouncedUpdate(value)

    return () => {
      debouncedUpdate.cancel()
    }
  }, [value, debouncedUpdate])

  return state
}
