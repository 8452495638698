import {
  createContext,
  DependencyList,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import cx from 'classnames'
import { useMeasure, usePrevious, useUpdateEffect } from 'react-use'

import { Icon, IconName } from '../../icon'
import { ColorProp } from '../../theme'
import { useScrollOpenPanel } from './use-scroll-open-panel'
import { Box } from '../../layout'
import {
  AccordionHeading,
  AccordionItemButton,
  AccordionItemContentWrapper,
  AccordionLabelLeft,
  AccordionLabelRight,
  AccordionList,
  AccordionPanelItem,
  CollapseExpandIcon,
  EXPAND_COLLAPSE_TRANSITION_MS,
  SuffixContainer
} from './accordion-components'

type AccordionContextType = {
  renderMode: AccordionContentRenderMode
  iconColor?: string
  padPanelContentBottom?: boolean
  disableAnimation?: boolean
}

const AccordionContext = createContext<AccordionContextType>({ renderMode: 'active' })
const useAccordionContext = () => useContext(AccordionContext)

export type AccordionContentRenderMode =
  /** Content is renderd the the first time a panel is opened. Afterwards, content remains in the DOM and is hidden when the panel collapses. */
  | 'first-active'
  /** Content is immediately and always rendered. Collapsed panel content is hidden.  */
  | 'always'
  /** Content is only in the DOM when a panel is opened. On collapse, content is removed from the DOM.  */
  | 'active'

export type AccordionProps = {
  children?: ReactNode
  scrollContainer?: Ref<HTMLElement> | string | HTMLElement | null
  renderMode?: AccordionContentRenderMode
  iconColor?: string
  className?: string
  // could customize to also take a number but for now it is just 16px or nothing
  padPanelContentBottom?: boolean
  'data-testid'?: string
  a11yTitle?: string
  disableAnimation?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Accordion List                               */
/* -------------------------------------------------------------------------- */

export const Accordion = ({
  children,
  scrollContainer,
  renderMode = 'active',
  iconColor,
  padPanelContentBottom = true,
  disableAnimation,
  ...restProps
}: AccordionProps) => {
  const [listElement, listElementCallbackRef] = useState<HTMLUListElement | null>(null)

  useScrollOpenPanel({
    scrollEl: scrollContainer,
    accordionEl: listElement,
    transitionMs: EXPAND_COLLAPSE_TRANSITION_MS
  })

  return (
    <AccordionContext.Provider
      value={{
        renderMode,
        iconColor,
        padPanelContentBottom,
        disableAnimation
      }}
    >
      <AccordionList
        aria-label={restProps.a11yTitle}
        {...restProps}
        ref={listElementCallbackRef as Ref<HTMLUListElement> | undefined}
      >
        {children}
      </AccordionList>
    </AccordionContext.Provider>
  )
}

/* -------------------------------------------------------------------------- */
/*                          Accordion Panel List Item                         */
/* -------------------------------------------------------------------------- */

export type AccordionPanelProps = {
  children?: ReactNode
  label: string
  /** @deprecated WARNING: This prop should only be used in special cases vetted by CFE */
  labelNode?: ReactNode
  /** the icon to be rendered to the left of the label text */
  icon?: IconName | ReactElement
  /** defaults to iconColor passed into the parent Accordion */
  iconColor?: string // may come from the backend
  initialActive?: boolean
  hasError?: boolean
  disabled?: boolean
  initialDisabled?: boolean
  /** further components to be rendered next to the label */
  labelSuffix?: ReactNode
  /** further components to be rendered on the right hand side of the header */
  suffix?: ReactNode
  headingLevel?: number
  a11yTitle?: string
  'data-testid'?: string
  id?: string
}

export type AccordionPanelType = {
  open: () => void
  close: () => void
}

export const AccordionPanel = forwardRef<AccordionPanelType, AccordionPanelProps>(
  (
    {
      children,
      label,
      labelNode,
      icon,
      iconColor,
      labelSuffix,
      suffix,
      a11yTitle,
      initialActive: isInitialActive = false,
      hasError,
      disabled,
      headingLevel = 4,
      'data-testid': dataTestId
    },
    ref
  ) => {
    const {
      iconColor: defaultIconColor,
      renderMode,
      padPanelContentBottom,
      disableAnimation: disableAnimationPanel
    } = useAccordionContext()

    // accessibility ids
    const headingId = useId()
    const contentId = useId()
    const buttonId = useId()

    const shouldPadBottom = !!padPanelContentBottom
    const [contentRef, { height: contentHeight }] = useMeasure()
    const hasContentHeight = contentHeight > 0

    const [isActive, setIsActive] = useState(isInitialActive)
    const [isHidden, setIsHidden] = useState(!isInitialActive)
    const [isTransitioning, setIsTransitioning] = useState(false)
    const [disableAnimation, setDisableAnimation] = useState(
      () => disableAnimationPanel || (isInitialActive && !contentHeight)
    )

    const hasBeenActiveRef = useRef(isActive)
    const showOpen = !disabled && isActive
    const shouldRenderHiddenContent =
      renderMode === 'always' || (renderMode === 'first-active' && hasBeenActiveRef.current)
    const showContent = isTransitioning || showOpen || shouldRenderHiddenContent
    const hadError = usePrevious(hasError)

    useAfterAnimationIf(() => setDisableAnimation(false), disableAnimation && hasContentHeight, [hasContentHeight])
    useAfterAnimationIf(() => setIsHidden(!showOpen), true, [showOpen])

    useEffect(() => {
      if (isActive) {
        hasBeenActiveRef.current = true
        setIsHidden(false)
      }
    }, [isActive])

    const handleToggle = useCallback(() => {
      setIsActive(prev => !prev)
      setIsTransitioning(true)
    }, [setIsActive, setIsTransitioning])

    useImperativeHandle(ref, () => ({
      open: () => setIsActive(true),
      close: () => setIsActive(false)
    }))

    useEffect(() => {
      if (!hadError && hasError) setIsActive(true)
    }, [hasError])

    useUpdateEffect(() => {
      if (disableAnimationPanel !== undefined) {
        setDisableAnimation(disableAnimationPanel && isActive)
      }
    }, [disableAnimationPanel])

    return (
      <AccordionPanelItem
        data-testid={dataTestId}
        onTransitionEnd={() => setIsTransitioning(false)}
        aria-label={a11yTitle}
        className={cx({
          'accordion-item': true,
          'accordion-item--disable-animation': disableAnimation,
          'accordion-item--active': showOpen,
          'accordion-item--disabled': disabled,
          'accordion-item--error': hasError,
          'accordion-item--animating': isTransitioning,
          'accordion-item--with-icon': !!icon
        })}
      >
        <AccordionItemButton
          className="accordion-item-heading-button"
          id={buttonId}
          aria-expanded={showOpen}
          onClick={handleToggle}
          disabled={disabled}
          type="button"
          aria-controls={contentId}
          {...(labelNode ? { 'aria-label': `${a11yTitle ?? label} heading` } : { 'aria-labelledby': headingId })}
        >
          <AccordionLabelLeft>
            {typeof icon === 'string' ? (
              <Icon aria-hidden icon={icon} color={(iconColor ?? defaultIconColor) as ColorProp} />
            ) : (
              icon
            )}
            <AccordionHeading
              role="presentation"
              id={headingId}
              headingLevel={headingLevel}
              data-testid={label + ' accordion'}
            >
              {labelNode ?? label}
            </AccordionHeading>
            {labelSuffix}
          </AccordionLabelLeft>

          <AccordionLabelRight>
            {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
            <SuffixContainer>
              <CollapseExpandIcon className="accordion-item-expand-collapse-icon" />
            </SuffixContainer>
          </AccordionLabelRight>
        </AccordionItemButton>

        <AccordionItemContentWrapper
          className="accordion-item-content"
          role="region"
          {...(labelNode ? { 'aria-label': `${a11yTitle ?? label} content` } : { 'aria-labelledby': headingId })}
          id={contentId}
          css={`
            min-height: ${showOpen && contentHeight + 'px'};
          `}
          aria-hidden={!showOpen}
        >
          <Box
            flex={false}
            ref={contentRef as any}
            css={`
              visibility: ${isHidden ? 'hidden' : 'visible'};
              > *:last-child {
                padding-bottom: ${shouldPadBottom && '16px'};
              }
            `}
          >
            {showContent && children}
          </Box>
        </AccordionItemContentWrapper>
      </AccordionPanelItem>
    )
  }
)

const useAfterAnimationIf = (fn: () => any, condition: boolean, deps?: DependencyList | undefined) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined
    clearTimeout?.(timeout)

    if (condition) {
      timeout = setTimeout(fn, EXPAND_COLLAPSE_TRANSITION_MS)
    }

    return () => clearTimeout?.(timeout)
  }, deps)
}
