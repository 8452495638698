import { createEventManager } from '@cutover/events'

import { Action } from './main/components/integrations/types'
import { AuthHeaders } from 'main/components/authentication/session'
import { OpenShareRunbookDashboardData } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-types'
import { LinkTemplateData, RunModalData } from 'main/components/runbook/modals/runbook-modals'
import {
  Permissions,
  SelectedTeam,
  SelectedUser,
  Stream,
  ValidationRunbookTeam
} from 'main/components/runbook/right-panels/people-panel/types'
import { RunbookTeam, RunbookTeamUser } from 'main/components/runbook/right-panels/people-panel/use-runbook-team'
import { RunbookType } from 'main/components/settings/runbook-types/use-runbook-type'
import { AxisDefName } from 'main/components/workspace/pages/runbooks-timeline/types'
import { DialogData, Runbook } from 'main/connectors/choose-start-time-runbook-connector'
import {
  Favorite,
  LinkedSnippet,
  RunbookListRunbook,
  StreamListStream,
  RunbookShowRunbook,
  TaskLinkedResourceDetails,
  TaskListTask,
  TaskShowTask,
  TaskType,
  UnlinkResource
} from 'main/services/queries/types'
import { CustomField } from 'main/services/queries/types/custom-fields'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import { RunbooksResponseMeta } from 'main/services/queries/use-runbooks'

/*
  Register events here by adding a key value to the `CutoverEvents` type. The key is the
  event string and the value is the event payload. Events emitted without a payload
  should be typed with a value of undefined

  example:

  type CutoverEvents = {
    'something-happened': {
      id: number
    }
  }

 */

export type MountEvent<D extends object = {}> = D & { elementForMount: HTMLElement }

export type ConnectorMountEvents = {
  'show-runbook-activities': MountEvent<{
    currentUserId: number
    timezone: string
    disableCommentField: boolean
  }>
  'show-runbook-users': MountEvent
  'open-apps-project': MountEvent
  'show-app-header-component': MountEvent
  'show-mobile-app-header-component': MountEvent
  'open-runbook-people-panel': MountEvent<{
    accountId: number
    runbook: { id: number; name: string; runbookTypeIconColor: string; isIncident: boolean; archived: boolean }
    runbookVersionId: number
    isCurrentVersion: boolean
    permissions: Permissions
    selectedTab: string
    runbookTeams: ValidationRunbookTeam[]
    streams: Stream[]
    selectedUser: SelectedUser
    selectedTeam: SelectedTeam
  }>
  'show-runbooks-timeline': MountEvent
  'open-runbook-dashboard-schedule': MountEvent<{
    accountId: string
    runbookId: number
    runbookVersionId: number
    currentUserId: number
    dashboardId: number
    timezone: string
    taskIds: number[]
    isFiltered: boolean
  }>
  // TODO: remove this event once React Workspace Route feature flag is removed
  'show-runbooks-dashboard': MountEvent
  'show-task-list': MountEvent
  'show-create-snippet-modals': MountEvent<{ accountId: string }>
  'show-add-snippet': MountEvent<{ accountSlug: string; runbookId: number; taskId: number; streamName: string }>
  'open-duplicate-snippets-modal': MountEvent<{
    snippet: { id: number; name: string } | null
    runbookIds: number[] | null
  }>
  'open-choose-runbook-type-modal': MountEvent
  'show-create-snippet-from-runbook-modals': MountEvent<{
    runbookId: number
    taskInternalIds: number[]
  }>
  'show-start-snippet-task-type': MountEvent<{ linkedSnippets: LinkedSnippet[] }>
  'open-runbook-integrations-panel': MountEvent<{
    runbook: RunbookShowRunbook
    runbookVersionId: number
    tasks: TaskListTask[]
    taskTypeLookup: Record<number, TaskType>
    streams: StreamListStream[]
  }>
  'nothing-happening': MountEvent // WIP - to be removed
  'open-start-time-runbook-modal': MountEvent<{
    data: { runbook: Runbook; customFields: CustomField[]; dialogData: DialogData; runbookType: RunbookType }
  }>
}

export type ConnectorEvents = {
  'hide-task-list': undefined
  // runbook-activities
  'hide-runbook-activities': undefined

  // app events
  'close-apps-project': undefined
  'close-apps-header': undefined
  'close-apps-mobile-header': undefined
  'runbook-data': { permissions: { [x: string]: number[] }; runbook?: { is_template: boolean; has_apps: boolean } }
  'update-timezone': { timezone: string }

  // runbook-users
  'hide-runbook-users': undefined

  // runbook-integrations
  'close-runbook-integrations-panel': undefined
  'on-submit-integrations-panel': undefined

  // people-panel
  'runbook-people-panel-close': undefined
  'close-runbook-people-panel': undefined
  'runbook-streams-updated': { streams: Stream[] }
  'right-panel-content-id-change': {
    contentId: string
  }
  'runbook-teams-users-added': undefined
  'open-import-users-modal': undefined
  'open-delete-team-modal': RunbookTeam | undefined
  'open-delete-user-reassign-modal':
    | {
        removedUser: RunbookTeamUser
        continueUpdate:
          | {
              runbookTeamId: number
              selected: string
              userId: number
              usersToReassign: number[]
            }
          | undefined
          | {}
      }
    | undefined
  'open-central-team-settings': { teamId: number | undefined }
  'open-delete-team-reassign-modal':
    | {
        removedUsers: RunbookTeamUser[] | undefined
        continueUpdate:
          | {
              runbookTeamId: number
              selected: string
              userId: number
              usersToReassign: number[]
            }
          | undefined
          | {}
      }
    | undefined

  // runbooks timeline
  'hide-runbooks-timeline': undefined
  'toggle-timeline-group': { group?: AxisDefName }
  'toggle-timeline-spotlight': { spotlight?: AxisDefName }
  'clear-runbook-filters': undefined
  'custom-fields-filter-data-loaded': { customFields: CustomField[] }

  // runbook-dashboard-schedule
  'runbook-dashboard-schedule-close': undefined
  'close-runbook-dashboard-schedule': undefined

  // runbooks-dashboard
  // TODO: remove this event once React Workspace Route feature flag is removed
  'hide-runbooks-dashboard': undefined

  // snippets
  'hide-add-snippet': undefined
  'hide-create-snippet-modals': undefined
  'update-runbook-page-on-snippet-added': undefined
  'close-duplicate-snippets-modal': undefined
  'close-choose-runbook-type-modal': undefined
  'hide-start-snippet-task-type': undefined
  'hide-create-snippet-from-runbook-modals': undefined

  // runbook duplicate
  'close-start-time-runbook-modal': undefined

  // user update
  'angular-user-updated': { user: CurrentUser }
}

export type PanelEvents = {
  'angular-header-opened': { height: number }
  'angular-header-closed': undefined
  'angular-main-nav-opened': undefined
  'angular-main-nav-closed': undefined
  'angular-right-sub-panel-closed': undefined
  'angular-right-sub-panel-opened': { width: number }
  'angular-right-panel-closed': undefined
  'angular-right-panel-opened': {
    elementForMount?: HTMLElement
    width: number
  }
  'angular-right-panel-resize': { width: number }
  'close-angular-right-panel': undefined
  'close-right-panel': undefined
  'open-right-panel': undefined
  'right-panel-closed': undefined
  'right-panel-opened': undefined
  'open-angular-right-panel': {
    content?: string
    contentId?: number | { [key: string]: any }
  }
  'angular-toggle-panel': undefined
  'angular-toggle-loading': { loading: boolean }
  'angular-toggle-user-profile': undefined
  'angular-logout': undefined
  'open-task-edit-panel': { taskId: number }
  'open-team-edit-panel': { teamId: number }
}

type AuthenticationEvents = {
  login: CurrentUser
  logout: undefined
  'token-update': AuthHeaders
  'token-validate': undefined
  'session-expiry': undefined
}

export type ChangeRequestEvents = {
  'open-create-or-link-change-request-modal': { runbookId: number; changeRequestType: string }
  'open-create-change-task-modal': {
    runbookId: number
    changeRequestType: string
    parentChangeRequestId: number
    parentChangeRequestExternalId: string
  }
  'change-request-created': { changeRequests: any }
}

export type OtherEvents = {
  'refresh-runbook-page-data': MountEvent
  // The below event is not used properly: emitted and listened for in the same (old) react component code.
  // Unclear if this code is used in production.
  'open-create-runbook-modal': undefined // emitted from react to trigger opening the angular create runbook modal
  'open-integration-debug-modal': { action: Action }
  'modal-saved': undefined
  'modal-cancelled': undefined
  'select-runbook-team': { runbookTeamId: number }
  'runbook-create-complete': undefined
  'update-runbooks-view-meta': { meta: RunbooksResponseMeta }
  'share-runbook-dashboard-modal-open': OpenShareRunbookDashboardData
  'linked-template-modal-open': LinkTemplateData
  'runbook-team-deleted': { deletedRunbookTeamName: string }
  'react-portal-component-mount': ReactPortalPayload
  'react-portal-component-unmount': ReactPortalPayload
  'react-portal-component-update': ReactPortalPayload
  'task-panel-angular-update': {
    task?: TaskShowTask
    linked_resource?: Pick<TaskLinkedResourceDetails, 'id' | 'type' | 'meta' | 'archived'>
  }
  'new-task-angular-update': {
    task?: TaskListTask
    linked_resource?: Pick<TaskLinkedResourceDetails, 'id' | 'type' | 'meta' | 'archived'>
  }
  // TODO: remove this event once React Workspace Route feature flag is removed
  'dashboard-reload': undefined
  'unlink-resource-confirmation-modal': { unlinkResource: UnlinkResource[]; actionMessage: string }
  'unlink-resource-angular-update': undefined
  'bulk-unlink-resource-angular-update': undefined
  'trigger-react-refetch-favorites': undefined
  'angular-toggle-favorite-runbook': { favorite: Favorite }
  'trigger-angular-refetch-favorites': undefined
  'reload-runbook-list-on-bulk-duplicate': undefined
  'saved-view-nav': {
    view: any
    previousState: any
  }
  'angular-clear-saved-view': undefined
  'trigger-react-refetch-saved-views': { accountSlug: string; accountId: number; savedView?: any }
  'trigger-angular-fetch-saved-views': { accountId: number; activeSavedView?: any; reset?: boolean }
  'react-update-active-saved-view': { view?: any }
  'angular-toggle-nav': undefined
  'angular-close-nav': undefined
  'angular-open-nav': undefined
  'react-toggle-main-nav': { open: boolean; screenSize?: string }
  'angular-toggle-main-nav': { open: boolean }
  'legacy-react-toggle-main-nav': { open: boolean }
  'angular-ready': undefined
  'trigger-react-refetch-workspaces': undefined
  'select-snippet-for-snippet-task-type': { selectedSnippetId: number | null }
  'open-runbook-run-modal': RunModalData
  'runbooks-template-type-changed': { templateType?: string | null }
  'open-export-runbooks-modal': undefined
  'open-import-portable-template-modal': undefined
  'open-angular-duplicate-runbooks-modal': {
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent
    runbook: RunbookListRunbook | undefined
    runbookIds: number[] | undefined
    firstRunbookIsTemplate?: boolean
  }
  'open-angular-merge-runbooks-modal': { runbookIds: number[] }
  'reload-runbook-list': undefined
  'filter-task-list-by-internal-ids': { serializedIdsForSearch: string | undefined }
}

export type CutoverEvents = ConnectorMountEvents &
  PanelEvents &
  ConnectorEvents &
  AuthenticationEvents &
  ChangeRequestEvents &
  OtherEvents

type ReactPortalPayload = {
  id: string
  componentId: string
  element: HTMLElement
  props: Record<string, unknown>
  onEvent: (data: unknown) => void
}

export const eventManager = createEventManager<CutoverEvents>()
