import {
  useGetAiSuggestions,
  useGetAiSuggestionsLoading,
  UseGetAiSuggestionsType,
  useOnActionAiSuggestions,
  useResetAiSuggestions,
  useSetAiSuggestionsLoading
} from '../hooks/recoil/ai-suggestions'
import { AiSuggestionsWebsocketResponseType } from 'main/services/queries/use-runbook-ai-suggestions'

export type AiSuggestionsModelType = {
  useGet: ({ runbookId, sessionId, promptItemKey }: UseGetAiSuggestionsType) => AiSuggestionsWebsocketResponseType
  useOnAction: () => (response: AiSuggestionsWebsocketResponseType) => void
  useSetLoading: () => ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType, loading: boolean) => void
  useGetLoading: ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType) => boolean
  useReset: () => ({ sessionId, runbookId, promptItemKey }: UseGetAiSuggestionsType) => void
}

export const AiSuggestionsModel: AiSuggestionsModelType = {
  useGet: useGetAiSuggestions,
  useOnAction: useOnActionAiSuggestions,
  useSetLoading: useSetAiSuggestionsLoading,
  useGetLoading: useGetAiSuggestionsLoading,
  useReset: useResetAiSuggestions
}
