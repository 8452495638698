import { useDefaultLayout } from '@cutover/react-ui'
import { Layout } from 'main/components/layout'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks'
import { EventWebhooksHeader } from './event-webhooks-header/event-webhooks-header'

export const EventWebhooksLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('event_webhooks')

  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout header={<EventWebhooksHeader />} subHeader={null} filter={null} rightPanels={null} />
  ) : null
}
