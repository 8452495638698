import { scopedTranslations } from 'config/i18n'
import { format as formatDate } from 'date-fns'
import { GetRecoilValue, ReadOnlySelectorOptions, selector } from 'recoil'
import { isEmpty } from 'lodash'

import { toCamelCase } from '@cutover/api'
import { streamsFlattenedState, teamsState, userState } from '../../runbook'
import { convertConfigObjectKeysToSnakeCase, globalConfigProperty } from '../global-config'
import { filterSelector } from '../filters'
import { GlobalConfigType } from 'main/services/hooks'
import { getAccountTaskType } from '../../runbook/models/account/task-types'
import { isDateControl, isMultiSelectControl, isSearchableCustomField } from 'main/services/tasks/filtering'
import { runbookFilterCustomFieldsState } from 'main/recoil/runbook/models/runbook-version/custom-fields/custom-fields-filters'
import { customFieldLookupByValueKey, ExtendedCustomField } from 'main/recoil/runbook/models/account/custom-fields'
import { runbookComponentsState } from 'main/recoil/runbook/models/runbook-version/runbook-components'
import { CombinedFilterKeys } from 'main/components/shared/filter/filter-groups/groups-header'

type Label = {
  value: string
  tip?: string
}

type CustomFieldLabels = {
  [key: number]: Label
}

type DataSourceValuesLabels = {
  [key: number | string]: Label
}

/*
 * Selector helpers
 */
const selectorFilterValue = ({ key }: { key: CombinedFilterKeys }) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))

      const filterValue = get(filterSelector({ attribute: key }))

      return {
        value: `${t(toCamelCase(key))}: ${filterValue}`
      }
    }
  })
}

const selectorFilterBoolean = ({
  key,
  ...options
}: { key: CombinedFilterKeys } & Omit<ReadOnlySelectorOptions<string | undefined>, 'get' | 'key'>) => {
  return selector({
    ...options,
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))

      const filterValue = get(filterSelector({ attribute: key }))

      if (typeof filterValue === typeof undefined) return undefined

      return {
        value: filterValue ? t(toCamelCase(key)) : t(key, { context: 'reversed' })
      }
    }
  })
}

const selectorFilterDate = ({
  key,
  ...options
}: { key: CombinedFilterKeys } & Omit<ReadOnlySelectorOptions<string | undefined>, 'get' | 'key'>) => {
  return selector({
    ...options,
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))
      if (key === 'dd') {
        const dateWithinConfig = get(globalConfigProperty({ attribute: 'dateWithinOptions' })) as {
          id: string
          name: string
          order: number
        }[]
        // make sure option is in configs
        const filterValue = get(filterSelector({ attribute: key }))
        if (typeof filterValue === typeof undefined) return undefined
        const configOption = dateWithinConfig.find(value => value.id === filterValue)
        return !!configOption
          ? { value: `${t(toCamelCase(key))} ${configOption.name}` }
          : { value: t(toCamelCase(key)) }
      }
      if (key === 'df' || key == 'dt' || key == 'created_after' || key == 'created_before') {
        const filterValue = get(filterSelector({ attribute: key })) as number
        if (typeof filterValue === typeof undefined) return undefined
        const formattedDateTime = formatDate(new Date(filterValue * 1000), 'dd LLL HH:mm')
        return { value: `${t(toCamelCase(key))}: ${formattedDateTime}` }
      }
    }
  })
}

const selectorFilterArray = ({
  key,
  getSingleValueName
}: {
  key: CombinedFilterKeys
  getSingleValueName: (recoil: { get: GetRecoilValue }, id: string | number) => string
}) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))
      const filter = get(filterSelector({ attribute: key })) as string[]
      if (!filter || filter.length === 0) return undefined

      const expandedValue = filter.reduce((acc, cur) => {
        const value = getSingleValueName({ get }, cur)
        return acc === '' ? value : `${acc}, ${value}`
      }, '')

      return filter.length > 1
        ? { value: `${t(toCamelCase(key))}: (${filter.length})`, tip: `${t(toCamelCase(key))}: ${expandedValue}` }
        : { value: `${t(toCamelCase(key))}: ${expandedValue}` }
    }
  })
}

// Used when the filter value itself is the desired value & doesn't need to be looked up
const selectorFilterArraySimple = ({ key }: { key: CombinedFilterKeys }) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))
      const filter = get(filterSelector({ attribute: key })) as string[]
      if (!filter || filter.length === 0) return undefined

      const expandedValue = filter.reduce((acc, cur) => {
        return acc === '' ? cur : `${acc}, ${cur}`
      }, '')

      return filter.length > 1
        ? { value: `${t(toCamelCase(key))}: (${filter.length})`, tip: `${t(toCamelCase(key))}: ${expandedValue}` }
        : { value: `${t(toCamelCase(key))}: ${expandedValue}` }
    }
  })
}

const selectorFilterCustomFieldsObject = ({
  key,
  getSingleValueName
}: {
  key: CombinedFilterKeys
  getSingleValueName: (
    recoil: { get: GetRecoilValue },
    value: number[] | number | string,
    customField: ExtendedCustomField
  ) => Label
}) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): CustomFieldLabels | undefined => {
      const filter = get(filterSelector({ attribute: key })) as string[]
      if (!filter || isEmpty(filter)) return undefined

      const customFields = get(runbookFilterCustomFieldsState)
      const labels: CustomFieldLabels = {}

      Object.entries(filter).forEach(([key, val]) => {
        const cf = customFields.find(cf => cf.id === Number(key))
        if (!cf) return

        labels[Number(key)] = getSingleValueName({ get }, val, cf)
      })

      return labels
    }
  })
}

const selectorFilterDataSourceValuesObject = ({
  key,
  getSingleValueName
}: {
  key: CombinedFilterKeys
  getSingleValueName: (recoil: { get: GetRecoilValue }, value: number[] | number | string) => Label
}) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): DataSourceValuesLabels | undefined => {
      const customFieldsLookup = get(customFieldLookupByValueKey)
      const filter = get(filterSelector({ attribute: key })) as string[]

      if (!filter || isEmpty(filter)) return undefined

      const labels: DataSourceValuesLabels = {}

      Object.entries(filter).forEach(([key, val]) => {
        const customField = customFieldsLookup[key]
        const label = getSingleValueName({ get }, val)
        const prefix = customField.display_name || customField.name
        labels[key] = { value: `${prefix}: ${label.value}`, tip: `${prefix}: ${label.tip}` }
      })

      return labels
    }
  })
}

const selectorFilterInt = ({ key }: { key: CombinedFilterKeys }) => {
  return selector({
    key: `filter:${key}:label`,
    get: ({ get }): Label | undefined => {
      const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter.labels' }))
      const filter = get(filterSelector({ attribute: key })) as number
      if (!filter) return undefined
      return { value: `${t(toCamelCase(key))} #${filter} : ${filter}` }
    }
  })
}

/*
 * Individual label states - BOOLEANS
 */
const milestoneLabelState = selectorFilterBoolean({
  key: 'm'
})
const criticalPathLabelState = selectorFilterBoolean({
  key: 'critical'
})
const assignedLabelState = selectorFilterBoolean({
  key: 'a'
})
const fixedStartLabelState = selectorFilterBoolean({
  key: 'fs'
})
const fixedEndLabelState = selectorFilterBoolean({
  key: 'fe'
})
const startNotificationsDisabledLabelState = selectorFilterBoolean({
  key: 'sn'
})
const commentsLabelState = selectorFilterBoolean({
  key: 'c'
})
const lateLabelState = selectorFilterBoolean({
  key: 'l'
})
const overrunLabelState = selectorFilterBoolean({
  key: 'or'
})
const activeTasksLabelState = selectorFilterBoolean({
  key: 'at'
})
const hasSuccessorsLabelState = selectorFilterBoolean({
  key: 'hs'
})
const includeUsersLabelState = selectorFilterBoolean({
  key: 'includeUsers'
})
const hasPredecessorsLabelState = selectorFilterBoolean({
  key: 'hp'
})
const hasErrorsLabelState = selectorFilterBoolean({
  key: 'he'
})
const myTasksLabelState = selectorFilterBoolean({
  key: 'mt'
})

/*
 * Individual label states - Values
 */

const searchLabel = selectorFilterValue({
  key: 'q'
})

/*
 * Individual label states - Objects
 */
const customLabelState = selectorFilterCustomFieldsObject({
  key: 'f',
  getSingleValueName: ({ get }, val, cf): Label => {
    const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter' }))
    const label: Label = {
      value: ''
    }

    const resolveText = (val: string | number) => {
      if (val === '*') {
        return t('anyValueSet')
      } else if (val === 0) {
        return t('noValueSet')
      } else if (isMultiSelectControl(cf.field_type.slug) || isSearchableCustomField(cf.type)) {
        const option = cf.field_options.find(opt => opt.id === val)
        return option?.name ?? ''
      } else {
        return val as string
      }
    }

    let tip = undefined
    let text = ''

    if (Array.isArray(val)) {
      if (isDateControl(cf.field_type.slug)) {
        const textParts: string[] = []

        for (let i = 0; i < 2; i++) {
          const label = i === 0 ? 'from' : i === 1 ? 'to' : ''
          const value = val[i]
          const resolvedText = value !== null ? formatDate(new Date(value), 'dd LLL HH:mm') : null
          if (resolvedText) {
            textParts.push(`${label} ${resolvedText}`)
          }
        }

        if (val[2] === 0) textParts.push(t('noValueSet'))

        if (textParts.length === 1) {
          text = textParts[0]
        } else {
          text = `(${textParts.length})`
          tip = textParts.join(', ')
        }
      } else {
        if (val.length === 1) {
          text = resolveText(val[0])
        } else {
          text = `(${val.length})`
          tip = val.reduce((acc, curr) => {
            const resolved = resolveText(curr)
            return acc === '' ? resolved : `${acc}, ${resolved}`
          }, '')
        }
      }
    } else {
      text = resolveText(val)
    }

    label.value = `${cf.display_name}: ${text}`
    label.tip = tip && `${cf.display_name}: ${tip}`

    return label
  }
})

/*
 * Individual label states - ARRAYS
 */
const streamLabelState = selectorFilterArray({
  key: 'stream',
  getSingleValueName: ({ get }, value) => {
    const streams = get(streamsFlattenedState)
    const stream = streams.find(s => s.internal_id === value)
    return stream?.name ?? ''
  }
})
const taskLevelLabelState = selectorFilterArray({
  key: 'lv',
  getSingleValueName: ({ get }, value) => {
    const taskLevelsConfig = get(globalConfigProperty({ attribute: 'taskLevels' })) as GlobalConfigType['taskLevels']
    return taskLevelsConfig.find(tl => tl.id === value)?.name ?? ''
  }
})
const startRequirementsLabelState = selectorFilterArray({
  key: 'sr',
  getSingleValueName: ({ get }, value) => {
    const startRequirementsConfig = convertConfigObjectKeysToSnakeCase(
      get(globalConfigProperty({ attribute: 'startRequirements' }))
    ) as GlobalConfigType['startRequirements']
    return startRequirementsConfig[value].name
  }
})
const endRequirementsLabelState = selectorFilterArray({
  key: 'er',
  getSingleValueName: ({ get }, value) => {
    const endRequirementsConfig = convertConfigObjectKeysToSnakeCase(
      get(globalConfigProperty({ attribute: 'endRequirements' }))
    ) as GlobalConfigType['endRequirements']
    return endRequirementsConfig[value].name
  }
})
const completionTypeLabelState = selectorFilterArray({
  key: 'ct',
  getSingleValueName: ({ get }, value) => {
    const completionTypesConfig = convertConfigObjectKeysToSnakeCase(
      get(globalConfigProperty({ attribute: 'completionTypes' }))
    ) as GlobalConfigType['completionTypes']
    return completionTypesConfig[value].name
  }
})
const stageLabelState = selectorFilterArray({
  key: 'stage',
  getSingleValueName: ({ get }, value) => {
    const stagesConfig = get(
      globalConfigProperty({ attribute: 'taskStageTypes' })
    ) as GlobalConfigType['taskStageTypes']

    return stagesConfig.find(stage => stage.id === value)?.name ?? ''
  }
})
const userLabelState = selectorFilterArray({
  key: 'user',
  getSingleValueName: ({ get }, value) => {
    const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter' }))

    if (value === '*') {
      return t('anyValueSet')
    } else if (value === 0) {
      return t('noValueSet')
    }
    const user = get(userState({ id: value as number }))
    return user?.name ?? ''
  }
})
const taskTypeLabelState = selectorFilterArray({
  key: 'type',
  getSingleValueName: ({ get }, value) => {
    const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter' }))
    const taskType = get(getAccountTaskType(value as number))
    const label = taskType?.key.includes('integration-action-item')
      ? taskType?.integration_action_items[0]?.name
      : taskType?.name
    return label ? (taskType.archived ? `${label} ${t('disabledIndicator')}` : label) : ''
  }
})
const runbookComponentLabelState = selectorFilterArray({
  key: 'rbc',
  getSingleValueName: ({ get }, value) => {
    const runbookComponents = get(runbookComponentsState)
    return runbookComponents.find(rbc => rbc.internal_id === value)?.name ?? ''
  }
})
const teamLabelState = selectorFilterArray({
  key: 'team',
  getSingleValueName: ({ get }, value) => {
    const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter' }))

    if (value === '*') {
      return t('anyValueSet')
    } else if (value === 0) {
      return t('noValueSet')
    }
    const teams = get(teamsState)
    const team = teams.find(t => t.team_id === value)
    return team?.name ?? ''
  }
})

/*
 * Individual label states - Int
 */
const criticalToHereLabelState = selectorFilterInt({
  key: 'critical_to_here'
})
const predecessorsToHereLabelState = selectorFilterInt({
  key: 'predecessors_to_here'
})
/*
 * Individual label states - DATES
 */
const dateWithinLabelState = selectorFilterDate({
  key: 'dd'
})
const dateFromLabelState = selectorFilterDate({
  key: 'df'
})
const dateToLabelState = selectorFilterDate({
  key: 'dt'
})

// Audit log filters
const authorLabelState = selectorFilterArray({
  key: 'author_id',
  getSingleValueName: ({ get }, value) => {
    const user = get(userState({ id: value as number }))
    return user?.name ?? ''
  }
})
const createdAfterLabelState = selectorFilterDate({
  key: 'created_after'
})
const createdBeforeLabelState = selectorFilterDate({
  key: 'created_before'
})
const objectTypeLabelState = selectorFilterArraySimple({
  key: 'object_type'
})
const taskInternalIdLabelState = selectorFilterValue({
  key: 'task_id'
})

// Data source filters
const hasTemplateLabelState = selectorFilterBoolean({
  key: 't'
})

const dsvLabelState = selectorFilterDataSourceValuesObject({
  key: 'dsv',
  getSingleValueName: ({ get }, val) => {
    const t = get(scopedTranslations({ ns: 'common', keyPrefix: 'filter' }))
    const label: Label = {
      value: ''
    }

    const resolveText = (val: string | number) => {
      if (val === '*') {
        return t('anyValueSet')
      } else if (val === 0) {
        return t('noValueSet')
      } else {
        return val as string
      }
    }

    let tip = undefined
    let text = ''

    if (Array.isArray(val)) {
      if (val.length === 1) {
        text = resolveText(val[0])
      } else {
        text = `(${val.length})`
        tip = val.reduce((acc, curr) => {
          const resolved = resolveText(curr)
          return acc === '' ? resolved : `${acc}, ${resolved}`
        }, '')
      }
    } else {
      text = resolveText(val)
    }

    label.value = text
    label.tip = tip

    return label
  }
})

/*
 * All labels
 */
export const labelsState = selector<any>({
  key: 'filter:labels',
  get: ({ get }) => {
    return {
      a: get(assignedLabelState),
      at: get(activeTasksLabelState),
      c: get(commentsLabelState),
      critical: get(criticalPathLabelState),
      ct: get(completionTypeLabelState),
      dd: get(dateWithinLabelState),
      df: get(dateFromLabelState),
      dt: get(dateToLabelState),
      er: get(endRequirementsLabelState),
      f: get(customLabelState),
      q: get(searchLabel),
      fe: get(fixedEndLabelState),
      fs: get(fixedStartLabelState),
      he: get(hasErrorsLabelState),
      hp: get(hasPredecessorsLabelState),
      hs: get(hasSuccessorsLabelState),
      includeUsers: get(includeUsersLabelState),
      l: get(lateLabelState),
      lv: get(taskLevelLabelState),
      m: get(milestoneLabelState),
      or: get(overrunLabelState),
      rbc: get(runbookComponentLabelState),
      sn: get(startNotificationsDisabledLabelState),
      sr: get(startRequirementsLabelState),
      stage: get(stageLabelState),
      stream: get(streamLabelState),
      team: get(teamLabelState),
      type: get(taskTypeLabelState),
      user: get(userLabelState),
      critical_to_here: get(criticalToHereLabelState),
      predecessors_to_here: get(predecessorsToHereLabelState),
      mt: get(myTasksLabelState),
      // Audit log filters
      author_id: get(authorLabelState),
      object_type: get(objectTypeLabelState),
      created_after: get(createdAfterLabelState),
      created_before: get(createdBeforeLabelState),
      task_id: get(taskInternalIdLabelState),
      // Data source filters
      t: get(hasTemplateLabelState),
      dsv: get(dsvLabelState)
    }
  }
})
