import { Box, Checkbox, Text } from '@cutover/react-ui'
import { ChangeRequest, ChangeTask } from 'main/services/queries/types'
import { ChangeStatusButton } from './change-status-button'
import { useLanguage } from 'main/services/hooks'
import { useChangeRequestPermissions } from './use-change-request-permissions'

type ChangeRequestItemHeaderProps = {
  changeRequest: ChangeRequest
  selectedChangeRequests: number[]
  toggleChangeRequest: (id: number) => void
  linkChangeRequestCutoverTask: (changeRequest: ChangeRequest, changeTask?: ChangeTask) => void
  editChangeRequest: (changeRequest: ChangeRequest, changeTask?: ChangeTask) => void
}

export const ChangeRequestItemHeader = ({
  changeRequest,
  selectedChangeRequests,
  toggleChangeRequest,
  linkChangeRequestCutoverTask,
  editChangeRequest
}: ChangeRequestItemHeaderProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.changeRequestItem' })
  const { canSelectChangeRequest, canLinkChangeRequest, canStartChangeRequest, canFinishChangeRequest } =
    useChangeRequestPermissions(changeRequest)

  return (
    <Box
      direction="row"
      width="100%"
      margin={{ vertical: 'small' }}
      align="center"
      data-testid="change-request-item-header"
    >
      <Box direction="row" flex="shrink">
        {canSelectChangeRequest && (
          <Checkbox
            checked={selectedChangeRequests.includes(changeRequest.id)}
            onClick={e => {
              e.stopPropagation()
              toggleChangeRequest(changeRequest.id)
            }}
          />
        )}
        {canLinkChangeRequest && (
          <ChangeStatusButton
            label={t('linkButtonLabel')}
            changeRequest={changeRequest}
            onClick={linkChangeRequestCutoverTask}
            canSelectChangeRequest={canSelectChangeRequest}
          />
        )}
        {canStartChangeRequest && (
          // This button is presentational only and is disabled by design since the Cutover task will automatically progress the change request.
          <ChangeStatusButton label={t('startButtonLabel')} changeRequest={changeRequest} onClick={() => {}} disabled />
        )}
        {canFinishChangeRequest && (
          <ChangeStatusButton
            label={t('finishButtonLabel')}
            changeRequest={changeRequest}
            onClick={editChangeRequest}
          />
        )}
      </Box>
      <Box flex="grow">
        <Text>{changeRequest.external_id}</Text>
      </Box>
    </Box>
  )
}

export const ChangeRequestStatusIndicator = ({ color }: { color: keyof typeof stateIndicatorStyles }) => {
  return (
    <Box
      css={`
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-right: 8px;
        ${stateIndicatorStyles[color]}

        @keyframes dot-pulse-amber {
          0% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(255, 153, 0, 0.4);
          }
          50% {
            transform: scale(1);
            opacity: 1;
            box-shadow: 0 0 0 10px rgba(255, 153, 0, 0);
          }
          100% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(255, 153, 0, 0);
          }
        }

        @keyframes dot-pulse-red {
          0% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(255, 51, 0, 0.4);
          }
          50% {
            transform: scale(1);
            opacity: 1;
            box-shadow: 0 0 0 10px rgba(255, 51, 0, 0);
          }
          100% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(255, 51, 0, 0);
          }
        }

        @keyframes dot-pulse-green {
          0% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(151, 164, 170, 0.4);
          }
          50% {
            transform: scale(1);
            opacity: 1;
            box-shadow: 0 0 0 10px rgba(151, 164, 170, 0);
          }
          100% {
            transform: scale(0.8);
            opacity: 0.7;
            box-shadow: 0 0 0 0 rgba(151, 164, 170, 0);
          }
        }
      `}
    />
  )
}

/**
 * Custom colors for the change request module.
 * TODO: consider changing to use theme colors.
 */
export const stateIndicatorStyles = {
  red: `
    background-color: rgba(255,64,64,1);
    box-shadow: 0 0 0 rgba(255, 153, 0, 0.4);
    animation: dot-pulse-red 1s infinite;
  `,
  amber: `
    background-color: rgba(255,170,21,1);
    box-shadow: rgba(255, 153, 0, 0.4);
    animation: dot-pulse-amber 1.5s infinite;
  `,
  green: `
    background-color: rgba(0,199,129,1);
    box-shadow: rgba(151, 164, 170, 0.4);
  `,
  refreshing: `
    background-color: rgba(0, 0, 255, 1);
    box-shadow: rgba(151, 164, 170, 0.4);
    animation: dot-pulse-green 2s infinite;
  `,
  completed: `
    background-color: rgba(21,127,37,1);
    box-shadow: rgba(151, 164, 170, 0.4);
    animation: dot-pulse-green 2s infinite;
  `,
  unknown: `
    background-color: rgba(151, 164, 170, 1);
    box-shadow: rgba(151, 164, 170, 0.4);
    animation: dot-pulse-green 2s infinite;
  `,
  off: 'display: none;'
}
