import { useMutation } from 'react-query'

import { ChangeRequest, ChangeRequestLinkPayload, ChangeTask } from './types'
import { apiClient_UNSTABLE } from '../api'
import { ApiError } from '../api/http-gateway-adapter'
import { RunbookChangeRequestsResponse } from '../api/data-providers/runbook-types'

export const getChangeRequests = async (runbookId: string) => {
  const { data } = await apiClient_UNSTABLE.get<RunbookChangeRequestsResponse>(`runbooks/${runbookId}/change_requests`)
  return data.change_requests
}

export const refreshChangeRequests = async (runbookId: number) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookChangeRequestsResponse>(
    `runbooks/${runbookId}/change_requests/refresh`
  )
  return data.change_requests
}

export type UpdateChangeRequestCutoverTasksPayload = {
  start_task_id?: number | null
  finish_task_id?: number | null
}

export const useUpdateChangeRequestCutoverTask = (changeRequestId: number) => {
  return useMutation(async (payload: UpdateChangeRequestCutoverTasksPayload) => {
    const { data } = await apiClient_UNSTABLE.post(`/change_requests/${changeRequestId}/update_tasks`, payload)

    return data?.change_request[0] as ChangeRequest
  })
}

export type ChangeRequestEditPayload = {
  change_request_ids: number[]
  transition: string
  transition_properties: {
    reason?: string
    notes?: string
    closure: {
      summarizedOutcomes: string
      approvedCIModification: string
      reviewOfModifications?: string
      deployCriteriaMet: string
      postDeploymentOutcomes?: string
      stabilityResults: string
      stability?: string
      communicationDone: string
    }
  }
}

export const useChangeRequestEdit = (runbookId: number) => {
  return useMutation(async (payload: ChangeRequestEditPayload) => {
    const { data } = await apiClient_UNSTABLE.put(`runbooks/${runbookId}/change_requests/bulk`, payload)
    return data?.change_requests[0] as ChangeRequest
  })
}

export type UpdateChangeTaskCutoverTasksPayload = {
  change_task_id: string
  start_task_id?: number | null
  finish_task_id?: number | null
}

export function useUpdateChangeTaskCutoverTask(changeRequestId: number) {
  return useMutation(async (payload: UpdateChangeTaskCutoverTasksPayload) => {
    const { data } = await apiClient_UNSTABLE.post(`/change_requests/${changeRequestId}/update_task_tasks`, payload)

    return data?.change_request[0] as ChangeRequest
  })
}

export type FinishTaskForChangeRequestPayload = {
  change_task: ChangeTask
  start_task_id: number
  finish_task_id: number
  finish_task_status: string
}

export function useFinishTaskForChangeRequest(changeRequestId: number) {
  return useMutation(async (payload: FinishTaskForChangeRequestPayload) => {
    const { data } = await apiClient_UNSTABLE.post(`/change_requests/${changeRequestId}/finish_change_task`, payload)

    return data?.change_request[0] as ChangeRequest
  })
}

type ChangeRequestDeletePayload = {
  change_request_ids: number[]
}

export function useChangeRequestsDelete(runbookId: number) {
  return useMutation(async (payload: ChangeRequestDeletePayload) => {
    const { data } = await apiClient_UNSTABLE.delete(`runbooks/${runbookId}/change_requests/bulk`, {
      data: payload
    })

    return data?.change_requests as ChangeRequest[]
  })
}

type ChangeRequestCreatePayload = {
  runbook_id: string | number
  change_request: {
    input: object
    metadata: object | undefined
  }
}

export function useChangeRequestCreate() {
  return useMutation(async (payload: ChangeRequestCreatePayload) => {
    const { data } = await apiClient_UNSTABLE.post(`runbooks/${payload.runbook_id}/change_requests`, payload)

    return data?.change_requests as ChangeRequest[]
  })
}

type NestedApiError = Omit<ApiError, 'errors'> & {
  response: { data: { errors: { failures?: string } } }
}

export const useChangeRequestLink = () => {
  return useMutation<ChangeRequest[], NestedApiError, ChangeRequestLinkPayload>(async payload => {
    const { data } = await apiClient_UNSTABLE.post(`runbooks/${payload.runbook_id}/change_requests/bulk`, {
      change_requests: payload.change_requests,
      runbook_id: payload.runbook_id
    })

    return data?.change_requests as ChangeRequest[]
  })
}
