import { useMemo } from 'react'
import * as yup from 'yup'

import { useNotify } from '@cutover/react-ui'
import { FormModal } from 'main/components/shared/form'
import { TaskSelectField } from 'main/components/shared/form/task-select'
import { StreamModel, TaskModel, TaskTypeModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { ChangeRequest, ChangeTask } from 'main/services/queries/types'
import {
  useUpdateChangeRequestCutoverTask,
  useUpdateChangeTaskCutoverTask
} from 'main/services/queries/use-change-requests'
import { ChangeRequestModel } from 'main/data-access/models/change-request-model'

type ChangeRequestLinkTasksModalProps = {
  open: boolean
  changeRequest: ChangeRequest
  changeTask?: ChangeTask
  onClose?: () => void
}

const validationSchema = yup.object({
  startTask: yup.number().nullable(),
  finishTask: yup.number().nullable()
})

export type TaskSelectFormType = yup.InferType<typeof validationSchema>

export const ChangeRequestLinkTasksModal = ({
  open,
  changeRequest,
  changeTask,
  onClose
}: ChangeRequestLinkTasksModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.linkTaskModal' })
  const notify = useNotify()
  const updateChangeRequestCutoverTask = useUpdateChangeRequestCutoverTask(changeRequest.id)
  const updateChangeTaskCutoverTask = useUpdateChangeTaskCutoverTask(changeRequest.id)
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const streamLookup = StreamModel.useGetLookup()
  const updateChangeRequestState = ChangeRequestModel.useAction('update')
  const tasksInternalIdLookup = TaskModel.useGetLookup({ keyBy: 'internal_id' })
  const tasks = TaskModel.useGetAll()

  const changeRequestStart = tasksInternalIdLookup[changeRequest.change_start]
  const changeRequestFinish = tasksInternalIdLookup[changeRequest.change_finish]
  const changeTaskStart =
    changeTask &&
    changeRequest.change_task_starts &&
    tasksInternalIdLookup[changeRequest.change_task_starts[changeTask.id]]
  const changeTaskFinish =
    changeTask &&
    changeRequest.change_task_finishes &&
    tasksInternalIdLookup[changeRequest.change_task_finishes[changeTask.id]]

  const defaultValues = useMemo(
    () => ({
      startTask: changeTask ? changeTaskStart?.id : changeRequestStart?.id,
      finishTask: changeTask ? changeTaskFinish?.id : changeRequestFinish?.id
    }),
    [changeRequest, changeTask]
  )

  /**
   * This handler does not use mutateAsync as we close the modal immidiatly after the mutation is called.
   * The success and error notifications are used to show the result to the user.
   */
  const handleSubmit = (data: TaskSelectFormType) => {
    if (changeTask) {
      updateChangeTaskCutoverTask.mutate(
        {
          change_task_id: changeTask.id,
          start_task_id: data.startTask,
          finish_task_id: data.finishTask
        },
        {
          onSuccess: changeRequest => {
            updateChangeRequestState(changeRequest)
            notify.success(t('taskSuccessMessage'), { title: t('successTitle') })
          },
          onError: () => {
            notify.error(t('taskErrorMessage'), { title: t('errorTitle') })
            onClose?.()
          }
        }
      )
    } else {
      updateChangeRequestCutoverTask.mutate(
        {
          start_task_id: data.startTask,
          finish_task_id: data.finishTask
        },
        {
          onSuccess: changeRequest => {
            updateChangeRequestState(changeRequest)
            notify.success(t('successMessage'), { title: t('successTitle') })
          },
          onError: () => {
            notify.error(t('errorMessage'), { title: t('errorTitle') })
            onClose?.()
          }
        }
      )
    }
  }

  return (
    <FormModal<TaskSelectFormType>
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText={t('confirmText')}
      title={changeTask ? t('titleChangeTask') : t('titleChange')}
      schema={validationSchema}
      defaultValues={defaultValues}
    >
      <TaskSelectField<TaskSelectFormType>
        name="startTask"
        label={t('startTask')}
        tasks={tasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        single
        disableTaskClick
      />
      <TaskSelectField<TaskSelectFormType>
        name="finishTask"
        label={t('finishTask')}
        tasks={tasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        single
        disableTaskClick
      />
    </FormModal>
  )
}
