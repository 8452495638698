import { ChangeEvent, forwardRef, useCallback, useId, useMemo } from 'react'
import { RadioButtonGroup, RadioButtonGroupExtendedProps } from 'grommet'
import styled from 'styled-components/macro'

import { radioBoxFocusCss, RadioboxItem } from '../../atoms/radiobox'
import { useShowMoreButton } from '../../form/internal/use-show-more'
import { IconName } from '../../icon'
import { ItemCreateInput } from '../../item-create-input/item-create-input'
import { Box } from '../../layout'
import { FormField } from '../internal/form-field'

//  NOTE: if you just need an inline radio group without formfield styling, ie: for the filer panel,
//  use radiobox-list.tsx

export type RadioboxGroupProps = {
  hasError?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  helpText?: string // Hidden in column view for now
  icon?: IconName
  inlineError?: string
  name: string
  valueKey?: string
  labelKey?: string
  direction?: 'row' | 'column'
  value?: RadioButtonGroupExtendedProps['value']
  defaultValue?: RadioButtonGroupExtendedProps['defaultValue']
  onChange?: RadioButtonGroupExtendedProps['onChange']
  options: (RadioButtonGroupExtendedProps['options'][0] & { autoFocus?: boolean; hasError?: boolean })[]
  onFocus?: RadioButtonGroupExtendedProps['onFocus']
  onBlur?: RadioButtonGroupExtendedProps['onBlur']
  onCreateOption?: (value: string) => void
  a11yTitle?: string
  plain?: boolean
}

export const RadioboxGroup = forwardRef<HTMLDivElement, RadioboxGroupProps>(
  (
    {
      label,
      readOnly,
      required,
      hasError,
      disabled,
      name,
      inlineError,
      helpText,
      icon,
      direction = 'column',
      labelKey = 'label',
      onCreateOption,
      options,
      value,
      a11yTitle,
      plain = false,
      ...props
    },
    ref
  ) => {
    const radioGroupId = useId()
    const initialValue = useMemo(() => props.defaultValue ?? value, [])
    const [maxNumOptionsToShow, itemShowButton] = useShowMoreButton(options.length)

    const handleReadOnlyChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      event?.preventDefault()
    }, [])

    if (direction === 'row' && options.length > 4) {
      console.warn('RadioboxGroup: When using a row direction, the number of options should known and 4 or less.')
    }

    // remap options with an accessible radio option name
    const opts = useMemo(
      () =>
        options.map((option: any) => ({
          ...option,
          a11yTitle: option[labelKey]
        })),
      [options]
    )

    return (
      <RadioboxGroupWrapper>
        <FormField
          label={label}
          startIcon={icon}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          labelProps={{
            'aria-labelledby': radioGroupId
          }}
          // Currently passing error state because the app has some instances of unselected
          // radio groups. ie: bulk runbook edit Rag Status radio group If any of these are
          // required we will need to show an error
          hasError={hasError}
          inlineError={inlineError}
          // this is a hack to avoid dealing with the UI when in column mode, for now
          helpText={onCreateOption || direction === 'column' ? undefined : helpText}
          direction={onCreateOption ? 'column' : direction}
          plain={plain}
        >
          <RadioButtonGroup
            id={radioGroupId}
            {...props}
            options={opts}
            name={name}
            flex={false}
            responsive={false}
            direction={onCreateOption ? 'column' : direction}
            gap="0"
            aria-readonly={readOnly}
            onChange={readOnly ? handleReadOnlyChange : props.onChange}
            value={readOnly ? initialValue : value}
            disabled={disabled}
            ref={ref}
            fill
            css="flex: 1"
            a11yTitle={a11yTitle ?? label}
          >
            {(option: any, { checked, hover: _hover }: { checked: boolean; hover?: boolean }) => {
              const index = options.findIndex(
                opt =>
                  (typeof opt === 'object' ? opt.value : opt) === (typeof option === 'object' ? option.value : option)
              )

              return (
                <RadioboxItem
                  hasError={typeof option === 'object' && option.hasError}
                  label={typeof option !== 'object' ? option : option[labelKey]}
                  a11yTitle={typeof option !== 'object' ? option : option[labelKey]}
                  disabled={disabled || (typeof option === 'object' && option.disabled)}
                  checked={checked}
                  css={`
                    display: ${index >= maxNumOptionsToShow && 'none'};
                  `}
                />
              )
            }}
          </RadioButtonGroup>
          {itemShowButton}
          {!readOnly && onCreateOption && <ItemCreateInput onCreateItem={onCreateOption} />}
        </FormField>
      </RadioboxGroupWrapper>
    )
  }
)

const RadioboxGroupWrapper = styled(Box).attrs({
  flex: false
})`
  ${radioBoxFocusCss}

  label label {
    // needed to get truncate working
    max-width: 100%;
  }
`
