export const WebsocketKeys = {
  runbookSubscriber: (id: number) => `runbook:subscriber:${id}`,
  runbookIntegrationButton: (id: number) => `runbook:integration-button:${id}`,
  runbookEditPanel: (id: number) => `runbook:edit-panel:${id}`,
  runbookActivityFeed: (id: number) => `runbook:activity-feed:${id}`,
  runbookSubscriberDeprecated: (id: number) => `runbook:subscriber-deprecated:${id}`,

  accountSubscriber: (id: string) => `account:subscriber:${id}`,

  appsResourceSubscriber: (id: string) => `apps-resource:subscriber:${id}`,

  appsUserSubscriber: (id: number) => `apps-user-channel:subscriber:${id}`,

  cutoverConnectSubscriber: (id: number) => `cutover-connect:subscriber:${id}`,

  userChannelSubscriber: (id: number) => `user-channel:subscriber:${id}`,

  aiSuggestionsSubscriber: (id: string | number | undefined) => `ai-suggestions:subscriber:${id}`
}
