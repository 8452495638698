import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, CheckboxesField, Message, TextLink } from '@cutover/react-ui'
import { CustomFieldsGroupsForm } from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { DuplicateRunbookFormSchema } from './duplicate-runbooks-modal'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  RadioboxGroupField,
  TextInputField
} from 'main/components/shared/form'
import { FolderSelectField } from 'main/components/shared/form/folder-select'
import { useLanguage } from 'main/services/hooks'
import {
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  RunbookListRunbook,
  RunbookShowRunbook
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'

type DuplicateRunbookFormProps = {
  context: 'runbook' | 'template' | 'snippet'
  isBulkDuplicate: boolean
  isSnippet: boolean
  isSingleRunbook: boolean
  displayCustomFields: boolean
  customFields?: CustomField[]
  groupedCustomFields?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
  hasPermissionOnExistingProject?: boolean
  projects?: PermittedProject[]
  runbook?: RunbookListRunbook | RunbookShowRunbook
  setLoading: Dispatch<SetStateAction<boolean>>
  showTemplateFolderLockNote?: boolean
}

export const DuplicateRunbookForm = ({
  context,
  isBulkDuplicate,
  isSnippet,
  isSingleRunbook,
  displayCustomFields,
  customFields = [],
  groupedCustomFields = {},
  customFieldGroupsLookup = {},
  customFieldUsers = [],
  hasPermissionOnExistingProject,
  projects,
  runbook,
  setLoading,
  showTemplateFolderLockNote = false
}: DuplicateRunbookFormProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })

  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useFormContext<DuplicateRunbookFormSchema>()
  const lockDestinationProject = (runbook?.is_template && runbook?.settings_lock_template_copies_to_folder) ?? false

  const [isStartScheduled, setIsStartScheduled] = useState<boolean>(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const copyTeamsOption = watch('copy_teams')
  const copyUsersOption = watch('copy_users')
  const projectModifyTypeOption = watch('project_modify_type')
  const startScheduled = watch('start_scheduled')

  useEffect(() => {
    if (projects && !isSnippet) {
      hasPermissionOnExistingProject
        ? setValue('project_modify_type', 'existing')
        : setValue('project_modify_type', 'choose')

      setValue('project_id', runbook?.project_id || projects?.[0].id)
      setLoading(false)
    }
  }, [projects])

  useEffect(() => {
    if (!copyTeamsOption) {
      setValue('copy_users', false)
    }
  }, [copyTeamsOption])

  useEffect(() => {
    if (copyUsersOption) {
      setValue('copy_teams', true)
    }
  }, [copyUsersOption])

  useEffect(() => {
    if (projectModifyTypeOption === 'existing') {
      clearErrors('project_id')
    }
  }, [projectModifyTypeOption])

  useEffect(() => {
    setIsStartScheduled(!!startScheduled)
  }, [startScheduled])

  const chkboxesGroupProps = {
    direction: 'row' as const,
    label: t('fields.duplicateOptions.label')
  }

  return (
    <Box direction="column">
      {!isBulkDuplicate && (
        <TextInputField<DuplicateRunbookFormSchema> name="name" label={t('fields.name.label')} autoFocus required />
      )}

      {!isSnippet && !isSingleRunbook && hasPermissionOnExistingProject && (
        <RadioboxGroupField<DuplicateRunbookFormSchema>
          name="project_modify_type"
          label={t('fields.folder.label')}
          direction="row"
          options={[
            { label: t('fields.folder.existing'), value: 'existing' },
            { label: t('fields.folder.choose'), value: 'choose' }
          ]}
        />
      )}

      {(isSingleRunbook || projectModifyTypeOption === 'choose') && !isSnippet && (
        <FolderSelectField<DuplicateRunbookFormSchema>
          label={t('fields.destinationFolder.label')}
          folders={projects ?? []}
          name="project_id"
          clearable={false}
          required
          loading={!projects}
          disabled={lockDestinationProject}
        />
      )}

      {isBulkDuplicate && !isSnippet && showTemplateFolderLockNote && projectModifyTypeOption === 'choose' && (
        <Box style={{ marginBottom: '20px' }}>
          <Message message={t('fields.destinationFolder.lockNote')} type="info" data-testid="destinationfolder-note" />
        </Box>
      )}

      {isBulkDuplicate && (
        <TextInputField<DuplicateRunbookFormSchema>
          name="suffix"
          label={t('fields.suffix.label')}
          tooltipText={t('fields.suffix.helpText', { context: context })}
        />
      )}

      <DateTimePickerField<DuplicateRunbookFormSchema>
        name="start_scheduled"
        label={t('fields.startScheduled.labelOptional')}
        fixed
        fixedWidth
      />

      {isStartScheduled && (
        <>
          <DateTimePickerField<DuplicateRunbookFormSchema>
            name="end_scheduled"
            label={t('fields.endScheduled.label')}
            fixed
            fixedWidth
          />

          <CheckboxesField label={t('fields.shiftTime.label')}>
            <CheckboxFieldControlled<DuplicateRunbookFormSchema>
              name="shift_time"
              label={t('fields.shiftTime.helpText')}
            />
          </CheckboxesField>
        </>
      )}

      {displayCustomFields && (
        <CustomFieldsGroupsForm
          customFields={customFields}
          groupedCustomFields={groupedCustomFields}
          customFieldGroupsLookup={customFieldGroupsLookup}
          customFieldUsers={customFieldUsers}
          errors={errors}
        />
      )}

      {showAdvancedOptions && (
        <Box margin={{ top: '20px' }}>
          <CheckboxesField {...chkboxesGroupProps}>
            <CheckboxFieldControlled<DuplicateRunbookFormSchema>
              name="copy_tasks"
              label={t('fields.duplicateOptions.tasksStreams')}
            />
            <CheckboxFieldControlled<DuplicateRunbookFormSchema>
              name="copy_teams"
              label={t('fields.duplicateOptions.teams')}
            />
            <CheckboxFieldControlled<DuplicateRunbookFormSchema>
              name="copy_users"
              label={t('fields.duplicateOptions.runbookUsers')}
            />
          </CheckboxesField>
        </Box>
      )}

      <Box css="margin: 8px 0 32px;">
        <TextLink
          label={showAdvancedOptions ? t('hideAdvancedOptions') : t('showAdvancedOptions')}
          onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          inline
          highlight={false}
        />
      </Box>
    </Box>
  )
}
