import { v4 as uuidV4 } from 'uuid'
import { AutoFocusInside } from 'react-focus-lock'

import { Box, ListItem, Modal, Text, useNotify } from '@cutover/react-ui'
import { ChangeRequestModel } from 'main/data-access/models/change-request-model'
import { useLanguage } from 'main/services/hooks'
import { useChangeRequestsDelete } from 'main/services/queries/use-change-requests'

type ChangeRequestsDeleteModalProps = {
  open: boolean
  runbookId: number
  changeRequestIds: number[]
  onDelete: () => void
  onClose: () => void
}

export const ChangeRequestsDeleteModal = ({
  open,
  runbookId,
  changeRequestIds,
  onDelete,
  onClose
}: ChangeRequestsDeleteModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel.deleteModal' })
  const notify = useNotify()
  const changeRequests = ChangeRequestModel.useGetAll()
  const deleteChangeRequests = ChangeRequestModel.useAction('delete')
  const deleteMutation = useChangeRequestsDelete(runbookId)

  const changeRequestsToDelete = changeRequestIds.map(id => {
    return changeRequests.find(cr => cr.id === id)
  })

  const handleSubmit = () => {
    deleteMutation.mutate(
      { change_request_ids: changeRequestIds },
      {
        onSuccess: data => {
          deleteChangeRequests(data)
          onDelete()
          onClose()
          notify.success(t('successMessage', { count: changeRequestIds.length }), { title: t('successTitle') })
        },
        onError: () => {
          onClose()
          notify.error(t('errorMessage', { count: changeRequestIds.length }), { title: t('errorTitle') })
        }
      }
    )
  }

  return (
    <AutoFocusInside>
      <Modal
        title={t('title', { count: changeRequestIds.length })}
        confirmText="Delete"
        open={open}
        onClose={onClose}
        onClickConfirm={handleSubmit}
        loading={deleteMutation.isLoading}
        focusConfirmButton
      >
        <Text margin={{ bottom: 'small' }}>{t('message', { count: changeRequestIds.length })}</Text>
        {changeRequestsToDelete.map(cr => (
          <ListItem
            key={`cr?.id-${uuidV4()}`}
            title={cr?.external_id}
            size="small"
            startComponent={
              <Box
                css={`
                  padding-left: 16px;
                  &:before {
                    color: #ff9900;
                    content: '!';
                    font-weight: 900;
                    font-size: 20px;
                    margin-right: 5px;
                  }
                `}
              />
            }
          />
        ))}
      </Modal>
    </AutoFocusInside>
  )
}
