import { useQuery } from 'react-query'

import { apiClient } from '../api'
import { QueryKeys } from './query-keys'
import { IntegrationActionItem } from './types'
import { serverQueryObjectToServerQueryString } from 'main/components/shared/filter/filter-params'

type EventWebhooksResponse = {
  integration_action_items: IntegrationActionItem[]
}

export type IntegrationActionItemServerParams = {
  key: string
}

export function useEventWebhooksQuery(params: IntegrationActionItemServerParams) {
  return useQuery<EventWebhooksResponse, Error, EventWebhooksResponse>([QueryKeys.EventWebhooks], async () => {
    const serverParams = serverQueryObjectToServerQueryString({
      queryObject: params
    })

    const { data } = await apiClient.get<EventWebhooksResponse>({
      url: `integration_action_items${serverParams}`
    })
    return data
  })
}
